import { Box, Skeleton, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { FormComponentBuilder } from 'components/common/form/layout';
import { FormControlProps, FormElementControlPropsType } from 'components/common/form/layout/control';
import { useQuery } from '@tanstack/react-query';
import { elementAPI, FolderData } from 'api/editor/element';
import ElementFolderItem from './folder-selection/ElementFolderItem';
import AddFolderButton from './folder-selection/AddFolderButton';
import { Popup, PopupSettings } from 'components/common/popup/Popup';
import useBundleTranslation from 'i18n';
import { now } from 'lodash';

export interface FolderSelectionProps extends FormControlProps {
    label: string;
    baseUrl: string;
    readOnly?: boolean;
}

export default function FolderSelection({ controlProps }: FormElementControlPropsType<FolderSelectionProps>) {
    const { t } = useBundleTranslation(['app/components/common/form/form']);

    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [folderToDelete, setFolderToDelete] = useState<FolderData | null>(null);

    const readOnly = controlProps.readOnly ?? false;

    const {
        data: elementFolders = [],
        refetch,
        isLoading,
    } = useQuery<FolderData[], Error>({
        queryKey: ['fetchFoldersData'],
        queryFn: () => elementAPI.getFoldersData(controlProps.baseUrl),
    });

    if (isLoading) {
        return <Skeleton height={'38px'} animation={'pulse'} />;
    }

    const onFolderDelete = (folderData: FolderData) => {
        setFolderToDelete(folderData);
        setShowConfirmationPopup(true);
    };

    const popupSettings: PopupSettings = {
        title: t('delete_folder_title'),
        textOK: t('delete_button'),
    };

    const afterFolderChanged = () => {
        controlProps.form.hookFormSetValue(controlProps.name, now(), { shouldDirty: true });
    };

    const onDeleteConfirm = () => {
        if (folderToDelete) {
            elementAPI.deleteFolder(controlProps.baseUrl, folderToDelete.folderId).then((response) => {
                refetch();
                afterFolderChanged();
            });
        }

        setShowConfirmationPopup(false);
    };

    const getFolderPath = () => {
        return (
            folderToDelete && (
                <Box sx={{ color: 'text.secondary', wordWrap: 'break-word' }}>
                    {folderToDelete.folderPathParts.length > 0 && (
                        <>
                            {folderToDelete.folderPathParts.join(' / ')}
                            &nbsp;
                        </>
                    )}
                    <Box component={'span'} sx={{ fontWeight: 600 }}>
                        {folderToDelete.folderPathParts.length > 0 && <>{'/'}&nbsp;</>}
                        {folderToDelete.folderName}
                    </Box>
                </Box>
            )
        );
    };

    return (
        <>
            <Box>
                {elementFolders.length > 0 && (
                    <Box sx={{ paddingBottom: '16px' }}>
                        {elementFolders.map((elementFolder) => (
                            <ElementFolderItem
                                key={elementFolder.folderId}
                                elementFolder={elementFolder}
                                onFolderDelete={onFolderDelete}
                                readOnly={readOnly}
                            />
                        ))}
                    </Box>
                )}
                {!readOnly && (
                    <AddFolderButton
                        refetch={refetch}
                        baseUrl={controlProps.baseUrl}
                        selectedFolders={elementFolders}
                        afterAdd={afterFolderChanged}
                    />
                )}
            </Box>
            {showConfirmationPopup && folderToDelete && (
                <Popup
                    settings={popupSettings}
                    open={showConfirmationPopup}
                    onHide={() => {
                        setShowConfirmationPopup(false);
                        setFolderToDelete(null);
                    }}
                    maxWidth={'popupSm'}
                    onConfirm={onDeleteConfirm}
                >
                    <Box>
                        <Box sx={{ mb: 2 }}>{t('delete_confirm')}</Box>
                        {getFolderPath()}
                    </Box>
                </Popup>
            )}
        </>
    );
}

export class FolderSelectionBuilder extends FormComponentBuilder {
    prepareProps(): FolderSelectionProps {
        return {
            ...this.controlProps,
            label: this.elementProps.componentProps?.label,
            baseUrl: this.elementProps.componentProps?.baseUrl,
            readOnly: this.elementProps.componentProps?.readOnly,
        };
    }
}
