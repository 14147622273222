import { ElementAlertStatus, ElementTarget, ElementType } from './index';
import React, { Fragment, lazy, useEffect, useRef, useState } from 'react';
import { alpha, Button, Theme } from '@mui/material';
import { Box, Stack } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import InfoIcon from 'components/element-viewer/controls-panel-icon/InfoIcon';
import DocumentsButton from 'components/element-viewer/controls-panel-icon/DocumentsButton';
import EditButton from 'components/element-viewer/controls-panel-icon/EditButton';
import DownloadIcon from 'components/element-viewer/controls-panel-icon/DownloadIcon';
import ShareButton from 'components/element-viewer/controls-panel-icon/ShareButton';
import { FullScreenHandle } from 'react-full-screen';
import FullScreenButton from 'components/element-viewer/controls-panel-icon/FullScreenButton';
import ExternalLinkButton from 'components/element-viewer/controls-panel-icon/ExternalLinkButton';
import FavoriteButton from 'components/element-viewer/controls-panel-icon/FavoriteButton';
import AlertSubscriptionIcon from 'components/element-viewer/controls-panel-icon/AlertSubscriptionIcon';
import { IBookmarksContext } from 'components/external-reference/hooks/useBookmarks';
import RatingIconButton from '../rating/RatingIconButton';
import { Popup, PopupSettings } from 'components/common/popup/Popup';
import BookmarkManager from 'components/external-reference/bookmark/BookmarManager';
import { BookmarkType } from 'components/external-reference/bookmark/BookmarkDropdown';
import useBundleTranslation from 'i18n';
import ActionMenu from 'components/common/action-menu/ActionMenu';
import EditManualData from 'components/element-viewer/controls-panel-icon/EditManualDataButton';
import ExpertAnalysis from 'components/element-viewer/controls-panel-icon/ExpertAnalysis';
import { ExternalReportViewerDataType } from 'app/extreport/ExternalReportViewer';
import TargetIcon from './controls-panel-icon/TargetIcon';
import { useEmbeddingContextVisible } from 'hooks/useEmbeddingContext';
import { useConfigConstantValue } from 'hooks/useConfigConstant';
import { useIsAdminOrHasPrivilege } from 'hooks/useUserPrivilege';
const LineageIconButton = lazy(() => import('components/lineage/LineageIconButton'));

export function useExternalReportNativeFilters(filters?: string, useNativeFilters?: boolean) {
    const [filtersToGet, setFiltersToGet] = useState(filters);
    useEffect(() => {
        if (useNativeFilters) {
            return;
        }
        setFiltersToGet(filters);
    }, [useNativeFilters, filters]);

    const listerIFrameFilters = (message: any) => {
        if (message?.data?.command != 'filters' || typeof message?.data?.filters == 'undefined') {
            if (filters != filtersToGet) {
                // Reset to default Filters
                setFiltersToGet(filters);
            }
            return false;
        }
        if (message.data.filters != filtersToGet) {
            // Update filters from IFrame response
            setFiltersToGet(JSON.stringify(message.data.filters));
        }
    };

    const interval = useRef(0);
    useEffect(() => {
        if (!useNativeFilters) {
            return;
        }
        if (!interval.current) {
            interval.current = window.setInterval(() => {
                const element = document.getElementById('iframe-component') as HTMLIFrameElement;
                if (element?.contentWindow) {
                    element.contentWindow.postMessage({ command: 'getFilters' });
                }
            }, 1000);
        }
        window.addEventListener('message', listerIFrameFilters);
        return () => {
            window.removeEventListener('message', listerIFrameFilters);
            window.clearInterval(interval.current);
        };
    }, []);
    return filtersToGet;
}

export default function ControlsPanel({
    viewerRequestExtraData,
    element,
    segmentValueId,
    fullScreenHandle,
    externalLink,
    externalLinkTitle,
    onFavoritesChange,
    bookmarksContext,
    context,
    targets,
    alertStatus,
    userChartOverlay,
    filters,
}: {
    viewerRequestExtraData?: ExternalReportViewerDataType;
    element: ElementType;
    segmentValueId: number;
    fullScreenHandle?: FullScreenHandle;
    externalLink?: string;
    externalLinkTitle?: string;
    onFavoritesChange: () => void;
    bookmarksContext?: IBookmarksContext;
    context?: 'viewer' | 'preview';
    targets?: ElementTarget[];
    alertStatus?: ElementAlertStatus;
    userChartOverlay?: number;
    filters?: string;
}) {
    const SeparatorItem = () => (
        <Box
            sx={{
                height: 16,
                width: '1px',
                backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.4),
                mx: 1.5,
            }}
        />
    );

    const downloadDisabled = useConfigConstantValue('DISABLE_DOWNLOADS');
    const allowedDownload = useIsAdminOrHasPrivilege('PRIV_ALLOW_DOWNLOAD');
    const showDownloadButton = allowedDownload || !downloadDisabled;

    const showLineageButton = useIsAdminOrHasPrivilege('PRIV_ACCESS_LINEAGE_DIAGRAM');

    //for preview
    let settings: any = [];
    if (element.row.type == 'metric' || element.row.type == 'multi-metric chart') {
        if (context == 'preview') {
            settings = [
                showLineageButton ? (
                    <LineageIconButton
                        elementType={element.row.type}
                        elementId={element.row.elementId.toString()}
                        isPanelIcon
                    />
                ) : null,
                showLineageButton ? <SeparatorItem /> : null,
                <FavoriteButton element={element} segmentValueId={segmentValueId} onChange={onFavoritesChange} />,
                <ShareButton element={element} segmentValueId={segmentValueId} />,
                showDownloadButton ? (
                    <DownloadIcon uco={userChartOverlay} element={element} segmentValueId={segmentValueId} />
                ) : null,
                <AlertSubscriptionIcon
                    dataTest={'alert-subscription_button'}
                    elementId={element.row.elementId}
                    segmentValueId={segmentValueId}
                    elementType={element.row.type}
                    alertStatus={alertStatus}
                />,
                element.row.dataFetchMethod == 'manual' && element.permissions.edit ? (
                    <EditManualData element={element} />
                ) : null,
            ];
        } else {
            settings = [
                <InfoIcon element={element} />,
                <LineageIconButton
                    elementType={element.row.type}
                    elementId={element.row.elementId.toString()}
                    isPanelIcon
                />,
                <SeparatorItem />,
                <FavoriteButton element={element} segmentValueId={segmentValueId} onChange={onFavoritesChange} />,
                <AlertSubscriptionIcon
                    elementId={element.row.elementId}
                    segmentValueId={segmentValueId}
                    elementType={element.row.type}
                    alertStatus={alertStatus}
                    dataTest={'alert-subscription_button'}
                />,
                element.row.dataFetchMethod == 'manual' && element.permissions.edit ? (
                    <EditManualData element={element} />
                ) : null,
                <DocumentsButton docs={element.documents} />,
                showDownloadButton ? (
                    <DownloadIcon uco={userChartOverlay} element={element} segmentValueId={segmentValueId} />
                ) : null,
                <TargetIcon targets={targets} />,
            ];
        }
    }
    if (element.row.type == 'external report') {
        if (context == 'preview') {
            settings = [
                bookmarksContext?.showIcon ? (
                    <BookmarkIcon
                        bookmarksContext={bookmarksContext}
                        elementId={element.row.elementId}
                        segmentValueId={segmentValueId}
                    />
                ) : null,
                bookmarksContext?.showIcon ? <SeparatorItem /> : null,
                showLineageButton ? (
                    <LineageIconButton
                        elementType={element.row.type}
                        elementId={element.row.elementId.toString()}
                        isPanelIcon
                    />
                ) : null,
                showLineageButton ? <SeparatorItem /> : null,
                <FavoriteButton element={element} segmentValueId={segmentValueId} onChange={onFavoritesChange} />,
                <ShareButton filters={filters} element={element} segmentValueId={segmentValueId} />,
                showDownloadButton ? (
                    <DownloadIcon
                        useNativeFilters={viewerRequestExtraData?.additionalData?.useNativeFiltersInd}
                        filters={filters}
                        element={element}
                        segmentValueId={segmentValueId}
                    />
                ) : null,
                <AlertSubscriptionIcon
                    elementId={element.row.elementId}
                    segmentValueId={segmentValueId}
                    elementType={element.row.type}
                    bookmarksContext={bookmarksContext}
                    alertStatus={alertStatus}
                />,
                externalLink ? (
                    <ExternalLinkButton externalLinkTitle={externalLinkTitle} externalLink={externalLink} />
                ) : null,
            ];
        } else {
            settings = [
                <BookmarkIcon
                    bookmarksContext={bookmarksContext}
                    elementId={element.row.elementId}
                    segmentValueId={segmentValueId}
                />,
                <InfoIcon viewerRequestExtraData={viewerRequestExtraData} element={element} />,
                <LineageIconButton
                    elementType={element.row.type}
                    elementId={element.row.elementId.toString()}
                    isPanelIcon
                />,
                <SeparatorItem />,
                <FavoriteButton element={element} segmentValueId={segmentValueId} onChange={onFavoritesChange} />,
                <DocumentsButton docs={element.documents} />,
                showDownloadButton ? (
                    <DownloadIcon
                        useNativeFilters={viewerRequestExtraData?.additionalData?.useNativeFiltersInd}
                        filters={filters}
                        element={element}
                        segmentValueId={segmentValueId}
                    />
                ) : null,
                externalLink ? (
                    <ExternalLinkButton externalLinkTitle={externalLinkTitle} externalLink={externalLink} />
                ) : null,
                fullScreenHandle ? <FullScreenButton fullScreenHandle={fullScreenHandle} /> : null,
            ];
        }
    }
    if (element.row.type == 'internal report') {
        if (context == 'preview') {
            settings = [
                showLineageButton ? (
                    <LineageIconButton
                        elementType={element.row.type}
                        elementId={element.row.elementId.toString()}
                        isPanelIcon
                    />
                ) : null,
                showLineageButton ? <SeparatorItem /> : null,
                <FavoriteButton element={element} segmentValueId={segmentValueId} onChange={onFavoritesChange} />,
                <ShareButton element={element} segmentValueId={segmentValueId} />,
                showDownloadButton ? <DownloadIcon element={element} segmentValueId={segmentValueId} /> : null,
                <AlertSubscriptionIcon
                    elementId={element.row.elementId}
                    segmentValueId={segmentValueId}
                    elementType={element.row.type}
                    bookmarksContext={bookmarksContext}
                    alertStatus={alertStatus}
                />,
            ];
        } else {
            settings = [
                <InfoIcon element={element} />,
                <LineageIconButton
                    elementType={element.row.type}
                    elementId={element.row.elementId.toString()}
                    isPanelIcon
                />,
                <SeparatorItem />,
                <FavoriteButton element={element} segmentValueId={segmentValueId} onChange={onFavoritesChange} />,
                <DocumentsButton docs={element.documents} />,
                showDownloadButton ? <DownloadIcon element={element} segmentValueId={segmentValueId} /> : null,
                <AlertSubscriptionIcon
                    elementId={element.row.elementId}
                    segmentValueId={segmentValueId}
                    elementType={element.row.type}
                    bookmarksContext={bookmarksContext}
                    alertStatus={alertStatus}
                />,
            ];
        }
    }
    if (element.row.type == 'other external content') {
        if (context == 'preview') {
            settings = [
                showLineageButton ? (
                    <LineageIconButton
                        elementType={element.row.type}
                        elementId={element.row.elementId.toString()}
                        isPanelIcon
                    />
                ) : null,
                showLineageButton ? <SeparatorItem /> : null,
                <FavoriteButton element={element} segmentValueId={segmentValueId} onChange={onFavoritesChange} />,
            ];
        } else {
            settings = [
                <InfoIcon element={element} />,
                <LineageIconButton
                    elementType={element.row.type}
                    elementId={element.row.elementId.toString()}
                    isPanelIcon
                />,
                <SeparatorItem />,
                <FavoriteButton element={element} segmentValueId={segmentValueId} onChange={onFavoritesChange} />,
                <DocumentsButton docs={element.documents} />,
            ];
        }
    }
    if (element.permissions.edit) {
        settings = [...settings, ...[<SeparatorItem />, <EditButton element={element} />]];
    }

    //SetUp actions menu
    let actionsMenuData: any = [];
    if (context != 'preview' && (element.row.type == 'metric' || element.row.type == 'multi-metric chart')) {
        actionsMenuData = [
            {
                key: 'share',
                component: <ShareButton element={element} segmentValueId={segmentValueId} isActionMenuItem />,
            },
            {
                key: 'rating',
                component: (
                    <RatingIconButton
                        elementId={element.row.elementId.toString()}
                        itemType={'element'}
                        isActionMenuItem
                    />
                ),
            },
        ];
    }
    if (context != 'preview' && element.row.type == 'external report') {
        actionsMenuData = [
            {
                key: 'share',
                component: (
                    <ShareButton filters={filters} element={element} segmentValueId={segmentValueId} isActionMenuItem />
                ),
            },
        ]
            .concat(
                element.row.showCollaborationInd
                    ? [
                          {
                              key: 'expert_analysis',
                              component: <ExpertAnalysis isActionMenuItem />,
                          },
                      ]
                    : [],
            )
            .concat([
                {
                    key: 'alert_subscription',
                    component: (
                        <AlertSubscriptionIcon
                            elementId={element.row.elementId}
                            segmentValueId={segmentValueId}
                            elementType={element.row.type}
                            bookmarksContext={bookmarksContext}
                            alertStatus={alertStatus}
                            isActionMenuItem
                        />
                    ),
                },
                {
                    key: 'rating',
                    component: (
                        <RatingIconButton
                            elementId={element.row.elementId.toString()}
                            itemType={'element'}
                            isActionMenuItem
                        />
                    ),
                },
            ]);
    }
    if (context != 'preview' && element.row.type == 'internal report') {
        actionsMenuData = [
            {
                key: 'share',
                component: <ShareButton element={element} segmentValueId={segmentValueId} isActionMenuItem />,
            },
        ];
    }
    if (context != 'preview' && element.row.type == 'other external content') {
    }

    if (actionsMenuData.length) {
        settings = [
            ...settings,
            ...[
                <SeparatorItem />,
                <span data-test={'top-actions-menu'}>
                    <ActionMenu items={actionsMenuData} />
                </span>,
            ],
        ];
    }

    const hasActionsBar = useEmbeddingContextVisible('actionsBar');
    if (!hasActionsBar) {
        return null;
    }
    return (
        <Stack direction="row" alignItems="center">
            {settings.map((component: any, i: number) => (
                <Fragment key={i}>{component}</Fragment>
            ))}
        </Stack>
    );
}

function BookmarkIcon({
    bookmarksContext,
    elementId,
    segmentValueId,
}: {
    bookmarksContext?: IBookmarksContext;
    elementId: number;
    segmentValueId: number;
}) {
    const { t } = useBundleTranslation([
        'components/external-reference/bookmark',
        'extreport',
        'components/external-reference/filter_block',
    ]);

    const defaultPopupSettings: PopupSettings = {
        title: t('bookmarks'),
        textOK: t('form:button_done'),
        noCancel: true,
    };

    const [popupSettings, setPopupSettings] = useState(defaultPopupSettings);
    const [managerPopup, setManagerPopup] = useState<boolean>(false);

    if (!bookmarksContext?.showIcon) {
        return null;
    }

    const handleManagerOnEditMode = (onEditMode: boolean) => {
        if (onEditMode) {
            setPopupSettings({ ...defaultPopupSettings, noButtons: true });
        } else {
            setPopupSettings(defaultPopupSettings);
        }
    };

    const handleHideManager = function () {
        setManagerPopup(false);
    };
    const handleShowManager = function () {
        setManagerPopup(true);
    };

    return (
        <div>
            <Button
                aria-haspopup="true"
                onClick={handleShowManager}
                variant="outlined"
                color="primary"
                className={'min-width--icon'}
            >
                <IconMi icon="bookmark" fontSize="16" />
            </Button>

            <Popup maxWidth="popupMd" settings={popupSettings} onHide={handleHideManager} open={managerPopup}>
                <BookmarkManager
                    managerContextType={'bookmark'}
                    setOnEditMode={handleManagerOnEditMode}
                    elementId={elementId}
                    segmentValueId={segmentValueId}
                    provideLink
                    bookmarks={bookmarksContext.bookmarks}
                    filters={bookmarksContext.filters}
                    siblingTabs={bookmarksContext.siblingTabs}
                    onDeleteClick={bookmarksContext.APIDeleteBookmark}
                    onDuplicateClick={bookmarksContext.APIDuplicateBookmark}
                    onSortEnd={bookmarksContext.APISortBookmarks}
                    onUpdateClick={(bookmark: BookmarkType) =>
                        bookmarksContext?.APIUpdateBookmark(bookmark, bookmarksContext.selectedReferenceId)
                    }
                />
            </Popup>
        </div>
    );
}
