import React, { useCallback, useEffect, useRef, useState } from 'react';
import { announcementAPI, AnnouncementData } from 'api/announcement';
import { alpha, Box, Stack, Theme } from '@mui/material';
import Announcement from './Announcement';
import { useQuery } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';
import useBundleTranslation from 'i18n';
import { useBlendColors } from 'hooks/useDesign';

export interface AnnouncementsComponentProps {
    elementId: number;
    collapseMode?: boolean;
    onChangeCollapsedState?: (isExpanded: boolean) => void;
    placeIntegration?: string;
}

function AnnouncementsComponent(props: AnnouncementsComponentProps) {
    const { t } = useBundleTranslation(['components/announcements/announcements']);

    const { elementId, collapseMode = true, onChangeCollapsedState = () => {}, placeIntegration } = props;
    const containerRef = useRef<any | null>(null);
    const contentHolderRef = useRef<any | null>(null);

    //Collapsed or expanded by user
    const [showMoreStateActive, setShowMoreStateActive] = useState(false);
    const showMoreStateActiveRef = useRef<boolean>(false);

    //show collapsed/expanded control by content height (on window resize)
    const [showCollapseControl, setShowCollapseControl] = useState(false);
    const showCollapseControlRef = useRef<boolean>(false);

    const resizeObserver = useRef<any>(null);
    /*
    const [announcements, setAnnouncements] = useState<AnnouncementData[]>([]);
    const loadAnnouncements = useCallback(() => {
        announcementAPI.getAnnouncementsData(elementId).then((response) => {
            if (response.status === 'OK') {
                setAnnouncements(response.announcements);
            }
        });
    }, []);

    useEffect(() => {
        loadAnnouncements();
    }, []);
    */

    const queryClient = useQueryClient();
    const queryKey = 'announcements_' + elementId;
    const { data: announcements, refetch } = useQuery<AnnouncementData[], Error>({
        queryKey: [queryKey],
        queryFn: async () => {
            return announcementAPI.getAnnouncementsData(elementId).then((response) => {
                if (response.status === 'OK') {
                    return response.announcements;
                }
                return [];
            });
        },
        refetchOnMount: true,
    });

    const loadAnnouncements = () => {
        refetch();
    };

    useEffect(() => {
        window.dispatchEvent(new Event('resize'));
        if (!announcements?.length) {
            setShowMoreStateActive(false);
            onChangeCollapsedState(false);
        }
    }, [announcements]);

    const onResizeContent = () => {
        const container = containerRef.current;
        const content = contentHolderRef.current;
        if (container && content && !showMoreStateActiveRef.current) {
            setShowCollapseControl(content.offsetHeight > container.offsetHeight);

            //recalc tiles block size
            if (placeIntegration == 'home') window.dispatchEvent(new Event('resize'));
        }
    };

    useEffect(() => {
        if (collapseMode && contentHolderRef.current) {
            resizeObserver.current = new ResizeObserver(onResizeContent);
            resizeObserver.current.observe(contentHolderRef.current);
        }
        return () => {
            if (resizeObserver.current) resizeObserver.current.disconnect();
        };
    }, []);

    useEffect(() => {
        //set data for EventListener
        showMoreStateActiveRef.current = showMoreStateActive;
    }, [showMoreStateActive]);

    useEffect(() => {
        //set data for EventListener
        showCollapseControlRef.current = showCollapseControl;
    }, [showCollapseControl]);

    return (
        <Box
            className={collapseMode ? 'announcement--collapse-mod' : ''}
            sx={{
                position: 'relative',
                '&.announcement--collapse-mod': {
                    height: '100%',
                    overflow: 'hidden',
                },
            }}
            ref={containerRef}
        >
            <Box
                className={'announcement-scroll-container ' + (showMoreStateActive ? 'announcement--expanded' : '')}
                sx={{
                    '&.announcement--expanded': {
                        height: '100%',
                        overflow: 'auto',
                        pb: 5,
                    },
                }}
            >
                <Box className={'announcement-list'} ref={contentHolderRef}>
                    {announcements && announcements.length > 0 && (
                        <Stack id={'announcementList'} direction={'column'} spacing={2} key={'announcements-wrapper'}>
                            {announcements.map((announcement, k) => (
                                <Announcement
                                    key={k}
                                    announcement={announcement}
                                    elementId={elementId}
                                    loadAnnouncements={loadAnnouncements}
                                />
                            ))}
                        </Stack>
                    )}
                </Box>
            </Box>
            {showCollapseControl && (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        backgroundImage: (theme) =>
                            `linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, ${theme.palette.background.default} 56%, ${theme.palette.background.default} 100%)`,
                        pt: '16px',
                        zIndex: 1,
                        borderBottom: showMoreStateActive ? 0 : '1px solid',
                        borderColor: (theme) => useBlendColors(alpha(theme.palette.text.primary, 0.24)),
                    }}
                >
                    <Box
                        component={'span'}
                        sx={{
                            position: 'relative',
                            display: 'inline-block',
                            color: 'primary.main',
                            lineHeight: '24px',
                            '&:hover': {
                                cursor: 'pointer',
                                opacity: 0.85,
                            },
                        }}
                        onClick={() => {
                            setShowMoreStateActive(!showMoreStateActive);
                            onChangeCollapsedState(!showMoreStateActive);
                        }}
                    >
                        {showMoreStateActive ? t('collapse_button') : t('expand_button')}
                    </Box>
                </Box>
            )}
        </Box>
    );
}

export default AnnouncementsComponent;
