import { Box, Button, Grid, IconButton, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReactSelect from 'components/common/react-select/ReactSelect';
import IconMi from 'components/common/icon/IconMi';
import SimpleFieldsSelectorTable from './SimpleFieldsSelectorTable';
import { FormComponentValue } from 'components/common/form/layout/control';
import { JoinFieldConfig, RulesConfig } from 'components/dataset-viewer/index';
import { TrackTabUpdateDTO } from '../helpers';
import useBundleTranslation from 'i18n';

function TrackChangesTab({
    showHistory,
    filter,
    onTrackDataUpdate,
    fieldIds,
}: {
    showHistory: boolean;
    filter: RulesConfig;
    onTrackDataUpdate: (value: TrackTabUpdateDTO) => void;
    fieldIds: JoinFieldConfig[];
}) {
    const { t } = useBundleTranslation(['components/dataset-viewer/dataset_viewer']);

    const [selectedValues, setSelectedValues] = useState<string[]>([]);
    const [joinFields, setJoinFields] = useState<JoinFieldConfig[]>([]);

    useEffect(() => {
        const values: string[] = [];

        if (filter.trackChangedRows) {
            values.push('changed');
        }
        if (filter.trackNewRows) {
            values.push('new');
        }
        if (filter.trackRemovedRows) {
            values.push('removed');
        }

        setSelectedValues(values);
        if (filter.joinFields && filter.joinFields.length > 0) {
            setJoinFields(filter.joinFields);
        } else {
            setJoinFields([fieldIds[0]]);
        }
    }, []);

    useEffect(() => {
        onTrackDataUpdate({
            joinFields: joinFields,
            selectedTrackOptions: selectedValues,
        });
    }, [selectedValues, joinFields]);

    const onSelectedChange = (keys: string[], checked: boolean) => {
        setSelectedValues((currentValue) => {
            const existSet = new Set(currentValue);

            keys.forEach((key) => {
                if (checked) {
                    existSet.add(key);
                } else {
                    existSet.delete(key);
                }
            });

            return [...existSet];
        });
    };

    const onJoinSelectedChange = (value: string, index: number) => {
        const fieldConfig = fieldIds.find((field) => {
            return field.id === value;
        });

        if (fieldConfig) {
            setJoinFields((prevState) => {
                const newState = [...prevState];
                newState[index] = fieldConfig;

                return newState;
            });
        }
    };

    const onJoinAdd = () => {
        if (joinFields.length === fieldIds.length) {
            return;
        }

        const availableField = fieldIds.find((field) => {
            return !joinFields.find((joinField) => {
                return joinField.id === field.id;
            });
        });

        if (availableField) {
            setJoinFields((prevState) => {
                return [...prevState, availableField];
            });
        }
    };

    const onJoinDelete = (index: number) => {
        setJoinFields((prevState) => {
            const newState = [...prevState];
            newState.splice(index, 1);

            return newState;
        });
    };

    return showHistory ? (
        <>
            <SimpleFieldsSelectorTable
                data={[
                    {
                        label: t('track_changes.changed'),
                        id: 'changed',
                    },
                    {
                        label: t('track_changes.new'),
                        id: 'new',
                    },
                    {
                        label: t('track_changes.removed'),
                        id: 'removed',
                    },
                ]}
                selectedColumns={selectedValues}
                onSelectedChange={onSelectedChange}
                type={'track'}
            />
            <Box>
                <Box fontWeight={600} component={'span'}>
                    {t('track_changes.define_filters')}
                </Box>{' '}
                <Box component={'span'}>{t('track_changes.to_track')}</Box>
            </Box>
            <Stack direction={'column'}>
                <Typography>{t('track_changes.for_each')}</Typography>
                <Stack direction={'row'}>
                    <Stack direction={'column'} spacing={1} flexGrow={1}>
                        {joinFields.map((fieldId, index) => {
                            if (fieldId) {
                                const availableData: FormComponentValue[] = [];

                                const currentSelected = fieldIds.find((fieldIdName) => {
                                    return fieldIdName.id === fieldId.id;
                                });

                                if (currentSelected) {
                                    availableData.push({
                                        label: currentSelected.name,
                                        value: currentSelected.id,
                                    });
                                }

                                fieldIds.forEach((fieldIdName) => {
                                    if (
                                        !joinFields.find((joinField) => {
                                            return joinField.id === fieldIdName.id;
                                        })
                                    ) {
                                        availableData.push({
                                            label: fieldIdName.name,
                                            value: fieldIdName.id,
                                        });
                                    }
                                });

                                const showCreateJoinField =
                                    index + 1 === joinFields.length && joinFields.length !== fieldIds.length;
                                const showDeleteButton = joinFields.length > 1;

                                return (
                                    <TrackChangeRow
                                        key={'change-row-' + index.toString()}
                                        availableData={availableData}
                                        selectedValue={fieldId.id}
                                        onChange={onJoinSelectedChange}
                                        index={index}
                                        showDeleteButton={showDeleteButton}
                                        onDelete={onJoinDelete}
                                    />
                                );
                            }
                        })}
                    </Stack>
                    <Box
                        sx={{
                            visibility: joinFields.length == fieldIds.length ? 'hidden' : undefined,
                            pl: 1,
                            alignSelf: 'flex-end',
                        }}
                    >
                        <Button
                            variant={'light'}
                            startIcon={<IconMi icon={'new'} />}
                            onClick={onJoinAdd}
                            data-test={'track-change-add-button'}
                        >
                            {t('track_changes.field')}
                        </Button>
                    </Box>
                </Stack>
            </Stack>
        </>
    ) : (
        <Box>
            <Box component={'span'}>{t('track_changes.must_select')}</Box>{' '}
            <Box component={'span'} fontWeight={600}>{`"${t('track_changes.last_two')}"`}</Box>{' '}
            <Box component={'span'}>{t('track_changes.order_to_track')}</Box>
        </Box>
    );
}

function TrackChangeRow({
    availableData,
    selectedValue,
    onChange,
    index,
    showDeleteButton,
    onDelete,
}: {
    availableData: FormComponentValue[];
    selectedValue: string;
    onChange: (value: string, index: number) => void;
    index: number;
    showDeleteButton: boolean;
    onDelete: (index: number) => void;
}) {
    const { t } = useBundleTranslation(['components/dataset-viewer/dataset_viewer']);

    return (
        <Stack direction={'row'}>
            <Box flexGrow={1}>
                <ReactSelect
                    dataTest={`track-change-row-${index}`}
                    data={availableData}
                    value={selectedValue}
                    update={(value) => {
                        onChange(value, index);
                    }}
                />
            </Box>
            <Stack direction={'row'} sx={{ paddingLeft: '8px' }}>
                {showDeleteButton && (
                    <IconButton
                        sx={{ padding: '3px' }}
                        onClick={() => {
                            onDelete(index);
                        }}
                    >
                        <IconMi icon={'trash'} fontSize="16" />
                    </IconButton>
                )}
            </Stack>
        </Stack>
    );
}

export default TrackChangesTab;
