import { Theme } from '@mui/material';
import { alpha } from '@mui/material/styles';

export default {
    filtersWrapper: {
        order: 0,
        flexGrow: 1,
        width: 'auto',
        mt: '-8px !important',
    },
    resetFilters: {
        backgroundColor: (theme: Theme) => alpha(theme.palette.primary.main, 0.08),
        color: 'primary.main',
        textAlign: 'center',
    },
    item: {
        padding: '0 8px 0 0',
    },
    checkbox: {
        padding: '8px',
    },
    search: {
        flexGrow: 0,
        flexShrink: 0,
        width: 192,
    },
    divider: {
        width: '1px',
        height: '16px',
        backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.08),
        mx: 1.5,
        alignSelf: 'center',
    },
    infoButton: {
        flexGrow: 0,
        flexShrink: 0,
        pl: 2,
    },
    filtersHeader: {
        borderBottom: '1px solid',
        borderColor: 'divider',
        padding: '6px 8px',
    },
};
