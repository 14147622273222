import React, { useLayoutEffect } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ReactHookFormController from 'components/common/form/layout/ReactHookFormController';
import { ReportContentNS, TextAlignType } from 'components/report-content/index';
import BlockComponent = ReportContentNS.BlockComponent;
import useCustomSimplifiedForm from 'components/common/form/hooks/useCustomSimplifiedForm';
import { prepareFormElementProps } from 'components/common/form/formTools';
import { alpha, Box, MenuItem, Popper, Stack, Typography } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import ComponentSettingsPivot = ReportContentNS.ComponentSettingsPivot;
import PivotDataCell = ReportContentNS.PivotDataCell;
import PivotDataCellStyle = ReportContentNS.PivotDataCellStyle;
import { PivotHeaderDropDownFieldType } from 'components/report-content/components/dataset';
import useBundleTranslation from 'i18n';
import ComponentSettingsPivotUIField = ReportContentNS.ComponentSettingsPivotUIField;
import { ReportContentActions } from 'app/editor/report';

export default function PivotHeaderColumnDropDown({
    field,
    onChangeColumnSettings,
    onClickAway,
    component,
    editPanel,
    actions,
    blockId,
}: {
    field: PivotHeaderDropDownFieldType;
    onChangeColumnSettings: (
        cell: PivotDataCell,
        newSettings: PivotDataCellStyle,
        unsetAutoStretch: boolean,
        realIndex: number,
    ) => void;
    onClickAway: (event: any) => void;
    component: BlockComponent<ComponentSettingsPivot>;
    actions: ReportContentActions;
    editPanel: any;
    blockId?: string;
}) {
    let uiField: ComponentSettingsPivotUIField | null = null;
    let isColumn = false;
    if (field.field?.type == 'row') {
        isColumn = true;
        //@ts-ignore
        uiField = component.settings.rowFields.find((f) => f.reference_name == field.field?.reference_name);
    } else if (field.field?.type == 'total') {
        //@ts-ignore
        uiField = component.settings.rowTotalsSettings;
    } else {
        if (field.uiFieldIndex != null && component.settings.uiFields[field.uiFieldIndex]) {
            uiField = component.settings.uiFields[field.uiFieldIndex];
        }
    }

    const elementProps = useCustomSimplifiedForm({
        bgColor: uiField?.bgColor ?? '',
        textColor: uiField?.textColor ?? '',
        textAlign: uiField?.textAlign ?? '',
    });

    const { t } = useBundleTranslation(['components/report-content']);
    useLayoutEffect(() => {
        if (uiField == null) {
            return;
        }
        elementProps.form.hookFormSetValue('bgColor', uiField?.bgColor);
        elementProps.form.hookFormSetValue('textColor', uiField?.textColor);
        elementProps.form.hookFormSetValue('textAlign', uiField?.textAlign ?? 'left');
        elementProps.form.hookFormSetValue('textWrap', uiField?.textWrap);
    }, [uiField]);

    const [bgColor, textColor, textAlign, textWrap] = elementProps.form.hookFormWatch([
        'bgColor',
        'textColor',
        'textAlign',
        'textWrap',
    ]);
    useLayoutEffect(() => {
        if (uiField == null || field.field == null) {
            return;
        }

        const newSettings: PivotDataCellStyle = {};

        if (bgColor) newSettings.bgColor = bgColor;
        if (textColor) newSettings.textColor = textColor;
        if (typeof textWrap != 'undefined') newSettings.textWrap = textWrap;
        if (textAlign) newSettings.textAlign = textAlign;

        onChangeColumnSettings(field.field, newSettings, false, field.realIndex);
    }, [bgColor, textColor, textAlign, textWrap]);

    const setTextAlign = (textAlign: TextAlignType) => {
        elementProps.form.hookFormSetValue('textAlign', textAlign);
    };

    const handleWrapText = () => {
        elementProps.form.hookFormSetValue('textWrap', !textWrap);
    };

    const handleEditColumn = () => {
        if (field == null || !blockId) {
            return;
        }
        actions.openBlockEditor(
            blockId,
            editPanel,
            isColumn ? { editField: field.field?.reference_name } : { editMeasure: true },
        );
    };

    const anchorEl = document.getElementById(`dDropIcon_${field.realIndex}`);
    if (uiField == null || !anchorEl) {
        return <></>;
    }

    return (
        <ClickAwayListener onClickAway={(event) => onClickAway(event)}>
            <Popper open={true} anchorEl={anchorEl} placement={'bottom-start'}>
                <Box
                    sx={{
                        mt: 0.25,
                        backgroundColor: 'background.default',
                        border: '1px solid',
                        borderColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                        borderRadius: 1,
                    }}
                >
                    {/*Alignment*/}
                    <ReactHookFormController
                        elementProps={prepareFormElementProps({
                            ...elementProps,
                            component: {
                                component: 'FormHidden',
                                name: 'textAlign',
                            },
                        })}
                    />
                    <MenuItem sx={{ position: 'relative', '&:hover .submenu': { display: 'block' } }}>
                        <Stack direction={'row'} spacing={1} justifyContent="flex-start" alignItems="center">
                            <IconMi icon={'alignment-left'} fontSize="14" sx={{ color: 'primary.main' }} />
                            <Typography>{t('dataset.alignment')}</Typography>
                        </Stack>
                        <IconMi icon={'chevron-right'} sx={{ position: 'absolute', right: 0.5, fontSize: '14px' }} />
                        <Box
                            className={'submenu'}
                            sx={{
                                position: 'absolute',
                                left: '100%',
                                top: 0,
                                backgroundColor: 'background.default',
                                display: 'none',
                                border: '1px solid',
                                borderColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                                borderRadius: 1,
                            }}
                        >
                            <MenuItem onClick={(event) => setTextAlign('left')}>
                                <Stack direction={'row'} spacing={1} justifyContent="flex-start" alignItems="center">
                                    <IconMi icon={'alignment-left'} fontSize="14" sx={{ color: 'primary.main' }} />
                                    <Typography sx={{ fontWeight: textAlign == 'left' ? 'bold' : 'normal' }}>
                                        {t('dataset.left')}
                                    </Typography>
                                </Stack>
                            </MenuItem>
                            <MenuItem onClick={(event) => setTextAlign('center')}>
                                <Stack direction={'row'} spacing={1} justifyContent="flex-start" alignItems="center">
                                    <IconMi icon={'alignment-center'} fontSize="14" sx={{ color: 'primary.main' }} />
                                    <Typography sx={{ fontWeight: textAlign == 'center' ? 'bold' : 'normal' }}>
                                        {t('dataset.center')}
                                    </Typography>
                                </Stack>
                            </MenuItem>
                            <MenuItem onClick={(event) => setTextAlign('right')}>
                                <Stack direction={'row'} spacing={1} justifyContent="flex-start" alignItems="center">
                                    <IconMi icon={'alignment-right'} fontSize="14" sx={{ color: 'primary.main' }} />
                                    <Typography sx={{ fontWeight: textAlign == 'right' ? 'bold' : 'normal' }}>
                                        {t('dataset.right')}
                                    </Typography>
                                </Stack>
                            </MenuItem>
                        </Box>
                    </MenuItem>
                    {/*Wrap Text*/}
                    {isColumn && (
                        <MenuItem onClick={(event) => handleWrapText()}>
                            <Stack direction={'row'} spacing={1} justifyContent="flex-start" alignItems="center">
                                <IconMi icon={'text-control'} fontSize="14" sx={{ color: 'primary.main' }} />
                                <Typography>{t(textWrap ? 'dataset.unwrap_text' : 'dataset.wrap_text')}</Typography>
                            </Stack>
                        </MenuItem>
                    )}
                    <MenuItem sx={{ p: 0 }}>
                        <Stack
                            direction={'row'}
                            justifyContent="flex-start"
                            alignItems="center"
                            sx={{
                                width: 1,
                                '.colorPicker': {
                                    width: 1,
                                },
                                '.colorPickerIcon': {
                                    border: '1px solid',
                                    backgroundColor: (theme) => textColor ?? theme.palette.text.primary,
                                    borderColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                                    display: 'flex',
                                    p: '2px',
                                    m: '-2px',
                                    color: 'background.default',
                                },
                                '.colorPickerIconHolder': {
                                    width: 1,
                                    px: 2,
                                    py: 0.75,
                                    display: 'flex',
                                    alignItems: 'center',
                                    backgroundColor: (theme) => `${theme.palette.background.default} !important`,
                                    '.colorPickerIconHolderLabel': {
                                        pl: 1,
                                        flexGrow: 1,
                                    },
                                },
                            }}
                        >
                            <ReactHookFormController
                                elementProps={prepareFormElementProps({
                                    ...elementProps,
                                    component: {
                                        component: 'ColorPicker',
                                        props: {
                                            pickerType: 'default',
                                            view: 'icon',
                                            icon: { type: 'mi', value: 'text-color', fill: 'background' },
                                            buttonText: 'Text Color',
                                            toggleShow: true,
                                        },
                                        name: 'textColor',
                                    },
                                })}
                            />
                        </Stack>
                    </MenuItem>

                    <MenuItem sx={{ p: 0 }}>
                        <Stack
                            direction={'row'}
                            justifyContent="flex-start"
                            alignItems="center"
                            sx={{
                                width: 1,
                                '.colorPicker': {
                                    width: 1,
                                },
                                '.colorPickerIcon': {
                                    border: '1px solid',
                                    backgroundColor: bgColor ?? undefined,
                                    borderColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                                    display: 'flex',
                                    p: '2px',
                                    m: '-2px',
                                    color: 'text.primary',
                                },
                                '.colorPickerIconHolder': {
                                    width: 1,
                                    px: 2,
                                    py: 0.75,
                                    display: 'flex',
                                    alignItems: 'center',
                                    backgroundColor: (theme) => `${theme.palette.background.default} !important`,
                                    '.colorPickerIconHolderLabel': {
                                        pl: 1,
                                        flexGrow: 1,
                                    },
                                },
                            }}
                        >
                            <ReactHookFormController
                                elementProps={prepareFormElementProps({
                                    ...elementProps,
                                    component: {
                                        component: 'ColorPicker',
                                        props: {
                                            pickerType: 'default',
                                            view: 'icon',
                                            icon: { type: 'mi', value: 'color-fill', fill: 'background' },
                                            buttonText: 'Fill Color',
                                            toggleShow: true,
                                        },
                                        name: 'bgColor',
                                    },
                                })}
                            />
                        </Stack>
                    </MenuItem>
                    {/*Edit Column*/}
                    <MenuItem
                        onClick={(event) => {
                            handleEditColumn();
                            onClickAway(event);
                        }}
                    >
                        <Stack direction={'row'} spacing={1} justifyContent="flex-start" alignItems="center">
                            <IconMi icon={'gear'} fontSize="14" sx={{ color: 'primary.main' }} />
                            <Typography>{t(isColumn ? 'dataset.edit_column' : 'dataset.edit_measure')}</Typography>
                        </Stack>
                    </MenuItem>
                </Box>
            </Popper>
        </ClickAwayListener>
    );
}
