import { instance } from 'api/api';
import { editorExternalReportAPI } from 'api/editor/external-report';

// Use to open external view. Will count interaction with element.
export function openExternalView(elementId: number, segmentValueId: number) {
    return instance.get(`/data/service/internal/element/${elementId}/${segmentValueId}/log-view`).finally(() =>
        editorExternalReportAPI.buildViewerUrl(elementId, segmentValueId).then((url) => {
            window.open(url, '_blank');
        }),
    );
}

export function copyExternalViewUrl(elementId: number, segmentValueId: number) {
    return instance.get(`/data/service/internal/element/${elementId}/${segmentValueId}/log-view`).finally(() =>
        editorExternalReportAPI.buildViewerUrl(elementId, segmentValueId).then((url) => {
            navigator.clipboard.writeText(url);
        }),
    );
}
