import React, { useState } from 'react';
import useBundleTranslation from 'i18n';
import {
    FormComponentValue,
    FormControlProps,
    FormElementControlPropsType,
} from 'components/common/form/layout/control';
import {
    Box,
    Button,
    ButtonGroup,
    Checkbox,
    Chip,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    Stack,
    Switch,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography,
} from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import styles from './ThemeExample.styles';
import { FormComponentBuilder } from 'components/common/form/layout';
import Divider from '@mui/material/Divider';
import ReactSelect from 'components/common/react-select/ReactSelect';
import GridComponent from 'components/common/grid/GridComponent';

export interface FormControlThemeExamplePropsProps extends FormControlProps {}

export default function ThemeExample({
    controlProps: props,
}: FormElementControlPropsType<FormControlThemeExamplePropsProps>) {
    const { t } = useBundleTranslation(['app/editor/brand-theme/theme-example']);
    const [toggleValue, setToggleValue] = React.useState<string>('option_0');
    const [dimensionVal, setDimensionVal] = useState<string>('region');
    const [nameVal, setNameVal] = useState<string>(t('name_value'));
    const [tagsVal, setTagsVal] = useState<string[]>(['finance', 'retail', 'hr']);

    const dimension_options: FormComponentValue[] = [
        'channel',
        'country',
        'department',
        'region',
        'state',
        'team',
        'year',
    ].map((optionKey: string) => {
        return {
            label: t(`dimension_option.` + optionKey),
            value: optionKey,
        };
    });

    const tags_options: FormComponentValue[] = [
        'channel',
        'country',
        'customer_service',
        'finance',
        'hr',
        'mobile',
        'retail',
    ].map((optionKey: string) => {
        return {
            label: t(`tags_option.` + optionKey),
            value: optionKey,
        };
    });

    const handleToggleChange = (event: React.MouseEvent<HTMLElement>, toggleVal: string) => {
        if (toggleVal) {
            setToggleValue(toggleVal);
        }
    };

    const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNameVal(event.target.value);
    };

    return (
        <Stack spacing={2}>
            <Stack direction={'row'} spacing={2}>
                <Stack width={'528px'} flexShrink={0} spacing={2} sx={styles.sectionBlock}>
                    <Stack direction="row" spacing={2}>
                        <Chip label={t('tag_label')} color="primary" />
                        <Chip label={t('tag_label')} color="success" />
                        <Chip label={t('tag_label')} color="error" />
                        <Chip label={t('tag_label')} />
                    </Stack>
                    <Divider />
                    <Box>
                        <Stack direction="row" spacing={2} sx={{ ml: -1 }}>
                            <FormControlLabel control={<Switch defaultChecked />} label={t('switch_label')} />
                            <FormControlLabel
                                control={<Switch defaultChecked color={'success'} />}
                                label={t('switch_label')}
                            />
                            <FormControlLabel
                                control={<Switch defaultChecked color={'error'} />}
                                label={t('switch_label')}
                            />
                        </Stack>
                    </Box>
                    <Divider />
                    <Box>
                        <Stack direction="row" spacing={2} sx={{ ml: -1 }}>
                            <FormControlLabel control={<Checkbox defaultChecked />} label={t('checkbox_label')} />
                            <FormControlLabel control={<Checkbox />} label={t('checkbox_label')} />
                        </Stack>
                    </Box>
                    <Divider />
                    <Box>
                        <RadioGroup row name="row-radio-buttons-group" defaultValue="option_1">
                            <FormControlLabel value="option_1" control={<Radio />} label={t('radio_label')} />
                            <FormControlLabel value="option_2" control={<Radio />} label={t('radio_label')} />
                        </RadioGroup>
                    </Box>
                    <Divider />
                    <Box>
                        <ToggleButtonGroup color="primary" value={toggleValue} exclusive onChange={handleToggleChange}>
                            {[...Array(4)].map((item, index) => {
                                return (
                                    <ToggleButton key={index} value={`option_${index}`}>
                                        {t('toggle_label')}
                                    </ToggleButton>
                                );
                            })}
                        </ToggleButtonGroup>
                    </Box>
                    <Divider />
                    <Stack direction="row" spacing={2}>
                        <Button sx={{ minWidth: '84px' }}>{t('btn_filter_label')}</Button>
                        <Button sx={{ minWidth: '84px' }} startIcon={<IconMi icon={'filter'} />}>
                            {t('btn_filter_label')}
                        </Button>
                        <Button sx={{ minWidth: '84px' }} variant="light">
                            {t('btn_filter_label')}
                        </Button>
                        <Button sx={{ minWidth: '84px' }} variant="light" startIcon={<IconMi icon={'filter'} />}>
                            {t('btn_filter_label')}
                        </Button>
                        <Button variant="outlined" className={'min-width--icon'}>
                            <IconMi icon={'filter'} fontSize={'16'} />
                        </Button>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        <Button color="success" sx={{ minWidth: '84px' }}>
                            {t('btn_save_label')}
                        </Button>
                        <Button color="success" sx={{ minWidth: '84px' }} startIcon={<IconMi icon={'save'} />}>
                            {t('btn_save_label')}
                        </Button>
                        <Button color="success" sx={{ minWidth: '84px' }} variant="light">
                            {t('btn_save_label')}
                        </Button>
                        <Button
                            color="success"
                            sx={{ minWidth: '84px' }}
                            variant="light"
                            startIcon={<IconMi icon={'save'} />}
                        >
                            {t('btn_save_label')}
                        </Button>
                        <Button color="success" variant="outlined" className={'min-width--icon'}>
                            <IconMi icon={'save'} fontSize={'16'} />
                        </Button>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        <Button color="error" sx={{ minWidth: '84px' }}>
                            {t('btn_delete_label')}
                        </Button>
                        <Button color="error" sx={{ minWidth: '84px' }} startIcon={<IconMi icon={'delete'} />}>
                            {t('btn_delete_label')}
                        </Button>
                        <Button color="error" sx={{ minWidth: '84px' }} variant="light">
                            {t('btn_delete_label')}
                        </Button>
                        <Button
                            color="error"
                            sx={{ minWidth: '84px' }}
                            variant="light"
                            startIcon={<IconMi icon={'delete'} />}
                        >
                            {t('btn_delete_label')}
                        </Button>
                        <Button color="error" variant="outlined" className={'min-width--icon'}>
                            <IconMi icon={'delete'} fontSize={'16'} />
                        </Button>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        <Button color="neutral" sx={{ minWidth: '84px' }} variant="light">
                            {t('btn_action_label')}
                        </Button>
                        <Button
                            color="neutral"
                            sx={{ minWidth: '84px' }}
                            variant="light"
                            startIcon={<IconMi icon={'chevron-down'} />}
                        >
                            {t('btn_action_label')}
                        </Button>
                        <Button color="neutral" variant="outlined" className={'min-width--icon'}>
                            <IconMi icon={'chevron-down'} fontSize={'16'} />
                        </Button>
                    </Stack>
                </Stack>
                <Box width={1} sx={styles.sectionBlock}>
                    <Box sx={styles.gridHolder}>
                        <GridComponent
                            formGrid
                            settingsUrl={`/data/fullmock/editor/brand-theme/grid-example`}
                            t={t}
                            uid={'example-grid'}
                        />
                    </Box>
                </Box>
            </Stack>
            <Stack direction={'row'} spacing={2}>
                <Stack width={'528px'} flexShrink={0} sx={styles.sectionBlock} alignItems={'center'}>
                    <Stack sx={styles.popup} alignItems={'center'} spacing={2}>
                        <IconMi icon="success" sx={styles.popupIcon} />
                        <Box sx={styles.popupTitle}>{t('popup_title')}</Box>
                        <Box sx={styles.popupText}>{t('popup_text')}</Box>
                        <Divider sx={{ width: 1 }} />
                        <Box>
                            <Button>{t('popup_btn')}</Button>
                        </Box>
                    </Stack>
                </Stack>
                <Stack width={1} spacing={2} sx={styles.sectionBlock}>
                    <Box>
                        <FormLabel sx={{ display: 'block' }}>{t('dimension_label')}</FormLabel>

                        <Stack direction={'row'}>
                            <Box sx={{ flexGrow: 1 }}>
                                <ReactSelect
                                    data={dimension_options}
                                    value={dimensionVal}
                                    update={(val) => {
                                        setDimensionVal(val);
                                    }}
                                />
                            </Box>
                            <Box sx={{ ml: 1, flexShrink: 0 }}>
                                <ButtonGroup>
                                    <Button>
                                        <IconMi icon="new" fontSize="16" />
                                    </Button>
                                    <Button>
                                        <IconMi icon="gear" fontSize="16" />
                                    </Button>
                                </ButtonGroup>
                            </Box>
                        </Stack>
                    </Box>
                    <Box>
                        <FormLabel sx={{ display: 'block' }}>{t('visible_label')}</FormLabel>
                        <Stack direction={'row'} spacing={2}>
                            <FormControlLabel
                                control={<Checkbox defaultChecked />}
                                label={t('visible_homepage_label')}
                            />
                            <FormControlLabel control={<Checkbox defaultChecked />} label={t('visible_search_label')} />
                            <FormControlLabel control={<Checkbox defaultChecked />} label={t('visible_mobile_label')} />
                        </Stack>
                    </Box>
                    <Box>
                        <FormLabel sx={{ display: 'block' }}>{t('name_label')}</FormLabel>
                        <Box>
                            <TextField value={nameVal} fullWidth onChange={handleChangeName} />
                        </Box>
                    </Box>
                    <Box>
                        <Typography variant="heading" sx={{ pt: 2 }}>
                            <IconMi className={'heading-icon'} icon={'tags'} />
                            {t('tags_heading')}
                        </Typography>
                    </Box>
                    <Box>
                        <FormLabel sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box component="span">{t('tags_label')}</Box>
                            <Tooltip
                                title={
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: t('tags_tooltip'),
                                        }}
                                    ></span>
                                }
                                placement={'top-start'}
                            >
                                <Box sx={{ display: 'inline-flex', ml: 0.5, color: 'primary.main' }}>
                                    <IconMi icon="info-circle" fontSize="16" />
                                </Box>
                            </Tooltip>
                        </FormLabel>
                        <Box>
                            <ReactSelect
                                data={tags_options}
                                value={tagsVal}
                                update={(val) => {
                                    setTagsVal(val);
                                }}
                                isMulti
                            />
                        </Box>
                    </Box>
                </Stack>
            </Stack>
        </Stack>
    );
}

export class FormThemeExampleBuilder extends FormComponentBuilder {
    prepareProps(): FormControlThemeExamplePropsProps {
        return { ...this.controlProps };
    }
}
