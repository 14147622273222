import { SeriesOptionsType } from 'highcharts/highstock';
import { ChartPoint, ChartSeriesType, DATA_X_MULTIPLIER } from 'components/metric/chart/index';
import { Chart as HChart, PointOptionsObject } from 'highcharts';
import { AssocArray } from 'tools/types';
import { findPointsInPeriod } from 'components/metric/chart/seriesBuilder';
import { AdditionalToDrawType, drawNumberOnIcon, getSVGIconsGroup } from 'components/metric/chart/draw/drawRanges';
import { ChartPointEvent } from 'components/metric/chart/series/getEventSeries';
import { ChartSVGPointEvents } from 'components/metric/chart/MetricChartWrapper';

export const SVG_GROUP_EVENT_ICONS = 'dGroupEventIcons';

let plotLinesMap = <AssocArray<string>>{};

export function manageEventSVG(
    chart: HChart,
    eventPoint: ChartPointEvent,
    plotX: number,
    plotY: number,
    svgIconsEvents?: ChartSVGPointEvents,
    display?: 'mark' | 'line' | 'mark and line',
) {
    const iconsGroup = getSVGIconsGroup(chart, SVG_GROUP_EVENT_ICONS);
    const pointId = `event_${eventPoint.x}_${eventPoint.metadata.index}`;
    let svg = document.getElementById(pointId);

    const getEventPoint = (svg: HTMLElement): ChartPointEvent => {
        const x = Number(svg.getAttribute('x'));
        const y = Number(svg.getAttribute('y')) - 18;
        return { ...eventPoint, plotX: x, plotY: y } as ChartPointEvent;
    };

    if (!svg) {
        svg = eventPoint.iconSvg.cloneNode(true) as HTMLElement;
        svg.setAttribute('id', pointId);
        if (eventPoint.metadata.color) {
            svg.getElementsByTagName('path')[0].setAttribute('fill', eventPoint.metadata.color);
        }
        if (display == 'line') {
            svg.getElementsByTagName('path')[0].setAttribute('opacity', '0');
        }

        //@ts-ignore
        iconsGroup.append(svg);
        svg.append(
            drawNumberOnIcon(chart, eventPoint.metadata.index, undefined, display == 'line' ? 'black' : 'white'),
        );
        if (svgIconsEvents) {
            svg.addEventListener('mouseover', function (event: any) {
                svgIconsEvents.mouseOver('event', getEventPoint(this));
            });
            svg.addEventListener('click', function () {
                svgIconsEvents.click('event', getEventPoint(this));
            });
        }
    }

    if (isNaN(plotY)) {
        plotY = 0;
    }
    svg.setAttribute('x', String(plotX));
    svg.setAttribute('y', String(plotY));
}

const drawLine = (chart: HChart, event: ChartPointEvent, previousPointPosition: number) => {
    const metadata = (event.options as ChartPointEvent).metadata;
    const id = `event_line_${event.x}`;

    let lineText = metadata.label.text;
    const plotPosition = chart.xAxis[0].toPixels(event.x, false);
    if (previousPointPosition > 0 && previousPointPosition - plotPosition < 5) {
        lineText = '';
    }

    if (plotLinesMap[id] == lineText) {
        return;
    }
    plotLinesMap[id] = lineText;

    const style = `height: 16px;
        display: inline-block;
        textOverflow: ellipsis;
        overflow: hidden;
        width: ${chart.plotHeight - 8};
        whiteSpace: nowrap;
    `;
    chart.xAxis[0].removePlotLine(id);
    chart.xAxis[0].addPlotLine({
        id: id,
        value: event.x,
        width: 1,
        color: metadata.color,
        dashStyle: 'Solid',
        zIndex: 2,
        label: {
            useHTML: false,
            text: lineText,
            verticalAlign: 'top',
            formatter: () => `<span style="${style}">${lineText}</span>`,
        },
    });
};

export default function chartRedrawEvents(
    chart: HChart,
    mainSeriesRef: any,
    additional: AdditionalToDrawType,
    svgIconsEvents: ChartSVGPointEvents,
) {
    const stackIcons = false;
    const eventSeries = chart.series.find((s) => {
        return (s.userOptions as SeriesOptionsType & ChartSeriesType).seriesType == 'event';
    });

    plotLinesMap = <AssocArray<string>>{};

    if (!eventSeries || eventSeries.visible == false) {
        // Remove plot lines
        //@ts-ignore
        if (chart.xAxis[0]?.plotLinesAndBands?.length > 0) {
            //@ts-ignore
            chart.xAxis[0].plotLinesAndBands.slice().forEach((plotLine: any) => {
                if (plotLine.id.includes('event_line_')) {
                    chart.xAxis[0].removePlotLine(plotLine.id);
                }
            });
        }
        return additional;
    }

    const iconsGroup = getSVGIconsGroup(chart, SVG_GROUP_EVENT_ICONS);

    if (!eventSeries.visible) {
        //@ts-ignore
        iconsGroup.classList.add('d-none');
        Object.keys(plotLinesMap).forEach((id) => chart.xAxis[0].removePlotLine(id));
        plotLinesMap = <AssocArray<string>>{};
        return additional;
    }
    //@ts-ignore
    iconsGroup.classList.remove('d-none');

    if (!stackIcons && eventSeries) {
        const xExt = chart.xAxis[0].getExtremes();
        let maxEventIcons = 0;
        chart.series.forEach((series) => {
            if ((series.userOptions as SeriesOptionsType & ChartSeriesType).seriesType != 'event') {
                return;
            }
            series.data.forEach((point) => {
                if (!((xExt?.userMin ?? 0) < point.x && point.x < (xExt?.userMax ?? 0))) {
                    return;
                }
                let iconNumber = (point as ChartPointEvent).iconNumber;
                maxEventIcons = iconNumber > maxEventIcons ? iconNumber : maxEventIcons;
            });
        });
    }

    const iconsMap: Array<number> = [];
    const actualEventsList = eventSeries.data
        .map((point) => {
            const options = point.options as PointOptionsObject & ChartPointEvent;
            if (stackIcons && options.iconNumber > 0) {
                return false;
            }
            const position = chart.xAxis[0].toPixels(point.x, false);
            const amount = iconsMap.filter((x) => Math.abs(x - position) < 20).length;
            iconsMap.push(position);
            return {
                ...point,
                metadata: options?.metadata ?? null,
                iconSvg: options.iconSvg,
                iconNumber: amount,
            } as ChartPointEvent;
        })
        .filter((point) => point != false) as Array<ChartPointEvent>;
    actualEventsList.sort((p0, p1) => p1.x - p0.x);

    let previousPointPosition: number = 0;
    // Remove old plot lines
    //@ts-ignore
    if (chart.xAxis[0]?.plotLinesAndBands?.length > 0) {
        //@ts-ignore
        chart.xAxis[0].plotLinesAndBands.slice().forEach((plotLine: any) => {
            if (
                plotLine.id.includes('event_line_') &&
                !actualEventsList.find((event) => event.x == plotLine.options.value)
            ) {
                chart.xAxis[0].removePlotLine(plotLine.id);
                delete plotLinesMap[plotLine.id];
            }
        });
    }

    actualEventsList.forEach((eventPoint) => {
        //@ts-ignore
        const plotOffset = chart?.axisOffset?.[3] ?? 0;
        let position = (eventPoint.plotX ?? 0) - 3;

        if (
            eventPoint.rangeEnd == 0 ||
            eventPoint.metadata.eventEndTimeFormatted == eventPoint.metadata.eventStartTimeFormatted
        ) {
            if (Number(eventPoint.plotX ?? 0) < 0) {
                position = -10000;
            }
            manageEventSVG(
                chart,
                eventPoint,
                position + plotOffset,
                22 * eventPoint.iconNumber,
                svgIconsEvents,
                eventPoint.metadata.display,
            );
            if (eventPoint.metadata.display == 'line' || eventPoint.metadata.display == 'mark and line') {
                drawLine(chart, eventPoint, previousPointPosition);
                previousPointPosition = position + plotOffset;
            }
        } else {
            const dataPoints = mainSeriesRef.current[0].data.map((d: ChartPoint) => {
                let y = d.y ?? 0;
                additional.ranges.forEach((range) => {
                    if (range.x <= d.x && range.x1 >= d.x) {
                        y = range.y > y ? range.y : y;
                    }
                });
                return { ...d, y: y };
            });

            const eventEnd = eventPoint.rangeEnd * DATA_X_MULTIPLIER;
            const points = findPointsInPeriod(chart, dataPoints, eventPoint.x, eventEnd);

            const newY = chart.yAxis[0].toPixels(Math.max(points[0].y, points[1].y), false) - 12;

            additional.ranges.push({
                type: 'event',
                x: eventPoint.x,
                x1: eventPoint.rangeEnd * DATA_X_MULTIPLIER,
                y: chart.yAxis[0].toValue(newY),
                point: eventPoint,
                leftBorder: true,
                rightBorder: true,
            });
        }
    });

    return additional;
}
