import { FormComponentBuilder } from 'components/common/form/layout';
import { Params as UrlParams } from '@remix-run/router/dist/utils';

import { FormControlProps } from 'components/common/form/layout/control';

export interface FormControlCodeEditorProps extends FormControlProps {
    placeholder?: string;
    mode: string;
    gridUrl: string;
    urlParams: UrlParams;
    showHistory?: boolean;
    height?: string;
    fetchOnTab?: string;
    showCopyButton?: boolean;
}

export class FormCodeEditorBuilder extends FormComponentBuilder {
    prepareProps(): FormControlCodeEditorProps {
        return {
            ...this.controlProps,
            urlParams: {
                ...this.elementProps.urlParams,
                ...this.elementProps.componentProps?.urlParams,
            } as UrlParams,
            placeholder: this.elementProps.componentProps?.placeholder,
            mode: this.elementProps.componentProps?.mode ?? 'html',
            gridUrl: this.elementProps.componentProps?.gridUrl ?? '',
            showHistory: this.elementProps.componentProps?.showHistory ?? false,
            height: this.elementProps.componentProps?.height,
            fetchOnTab: this.elementProps.componentProps?.fetchOnTab,
            showCopyButton: this.elementProps.componentProps?.showCopyButton ?? false,
        };
    }
}
