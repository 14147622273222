import { alpha, Theme } from '@mui/material';
import 'quill/dist/quill.bubble.css';
import { useBlendColors } from 'hooks/useDesign';

export default {
    root: {
        border: (theme: Theme) => `1px solid ${useBlendColors(alpha(theme.palette.text.primary, 0.4))}`,
        borderRadius: '3px',
        backgroundColor: (theme: Theme) => theme.palette.background.default,
        '&:hover': {
            borderColor: (theme: Theme) => alpha(theme.palette.primary.main, 0.8),
        },
        '&.focused': {
            borderColor: (theme: Theme) => theme.palette.primary.main,
            boxShadow: (theme: Theme) => `0 0 0 2px ${alpha(theme.palette.primary.main, 0.24)}`,
        },
        '.ql-toolbar': {
            p: '4px 0 4px 4px',
        },
        '.ql-bubble .ql-tooltip': {
            zIndex: (theme: Theme) => theme.zIndex.tooltip,
            borderRadius: '4px',
            backgroundColor: (theme: Theme) => theme.palette.background.default,
            color: (theme: Theme) => theme.palette.text.primary,
            boxShadow: (theme: Theme) => `0px 2px 8px 0px ${alpha(theme.palette.text.primary, 0.16)}`,
            '.ql-formats': {
                display: 'inline-flex',
                alignItems: 'center',
                color: (theme: Theme) => theme.palette.text.primary,
                margin: '0px',
                '&>*': {
                    marginRight: '4px',
                },
                button: {
                    color: (theme: Theme) => theme.palette.text.primary,
                    width: '24px',
                    height: '24px',
                    padding: 0,
                    display: 'inline-flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    svg: {
                        width: '16px',
                        height: '16px',
                        fill: 'currentColor',
                    },
                },

                '&:before': {
                    content: '""',
                    float: 'left',
                    width: '1px',
                    height: '16px',
                    marginTop: '3px',
                    marginRight: '4px',
                    borderRight: (theme: Theme) => `1px solid ${alpha(theme.palette.text.primary, 0.08)}`,
                },

                '&:first-of-type:before': {
                    content: 'none',
                },
            },
        },
        '.ql-tooltip-arrow': {
            display: 'none',
        },
        '.ql-bubble .ql-stroke': {
            stroke: (theme: Theme) => theme.palette.text.primary,
            strokeWidth: '1px',
        },
        '.ql-bubble .ql-fill': {
            fill: (theme: Theme) => theme.palette.text.primary,
        },
        'button.ql-bold .ql-stroke': {
            strokeWidth: '2px',
        },
        '.ql-bubble .ql-picker': {
            fontSize: '12px',
            fontWeight: '400',
        },
        '.ql-bubble .ql-picker .ql-picker-label svg': {
            fill: 'currentColor',
        },
        '.ql-bubble .ql-picker:not(.ql-color-picker) .ql-picker-label': {
            paddingLeft: '4px',
            paddingRight: '24px',
        },
        '.ql-bubble .ql-picker, .ql-picker-label:hover, .ql-bubble .ql-toolbar .ql-picker-item:hover, .ql-bubble .ql-toolbar .ql-picker-label.ql-active':
            {
                color: (theme: Theme) => `${theme.palette.text.primary} !important`,
            },
        '.ql-bubble .ql-picker.ql-expanded .ql-picker-label': {
            '&, &:hover': {
                color: (theme: Theme) => `${theme.palette.primary.main} !important`,
            },
        },
        '.ql-bubble .ql-picker-options .ql-picker-item': {
            paddingTop: '6px',
            paddingBottom: '6px',
            lineHeight: 1,
            '&:hover': {
                color: (theme: Theme) => `${theme.palette.primary.main} !important`,
            },
        },
        '.ql-bubble .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg': {
            width: '16px',
            marginTop: '-8px',
            right: '4px',
        },
        'button.ql-active .ql-stroke, button:hover .ql-stroke, .ql-bubble .ql-picker.ql-expanded .ql-picker-label .ql-stroke, .ql-bubble .ql-color-picker .ql-picker-label:hover .ql-stroke':
            {
                stroke: (theme: Theme) => theme.palette.primary.main + '!important',
            },
        '.ql-bubble .ql-picker.ql-expanded .ql-picker-label, .ql-bubble .ql-color-picker .ql-picker-label:hover, button.ql-active, button:hover':
            {
                backgroundColor: (theme: Theme) => alpha(theme.palette.primary.main, 0.08),
                borderRadius: '4px',
                color: (theme: Theme) => `${theme.palette.primary.main} !important`,
            },
        '.ql-picker-options': {
            zIndex: (theme: Theme) => theme.zIndex.tooltip + 1,
            maxWidth: '110px',
            borderRadius: '4px',
            backgroundColor: (theme: Theme) => theme.palette.background.default,
            boxShadow: (theme: Theme) => `0px 2px 8px 0px ${alpha(theme.palette.text.primary, 0.16)}`,
        },
        'button.ql-active .ql-fill, button:hover .ql-fill': {
            fill: (theme: Theme) => theme.palette.primary.main + '!important',
        },
        '.ql-tooltip-editor input[type=text]': {
            color: (theme: Theme) => theme.palette.text.primary,
            padding: '5px',
        },
        '.ql-bubble .ql-color-picker .ql-picker-item': {
            borderRadius: '2px',
        },
        '.ql-container.ql-bubble:not(.ql-disabled) a::before': {
            content: 'none', //turn off
            backgroundColor: (theme: Theme) => theme.palette.background.default,
            color: (theme: Theme) => theme.palette.text.primary,
            borderRadius: '2px',
            boxShadow: (theme: Theme) => `0px 2px 8px 0px ${alpha(theme.palette.text.primary, 0.16)}`,
        },
        '.ql-container.ql-bubble:not(.ql-disabled) a::after': {
            content: 'none', //turn off
            borderTopColor: (theme: Theme) => theme.palette.background.default,
            zIndex: 1,
        },
        '.ql-editor': {
            overflowY: 'visible',
            py: 0.75,
            px: 1.5,
            color: (theme: Theme) => theme.palette.primary.main,
        },
        '.ql-editor ol, .ql-editor ul': {
            pl: 3,
            li: {
                pl: 0,
                '&:before': {
                    content: 'none',
                },
            },
        },
        '.ql-editor ol li[data-list="bullet"], .ql-editor ul li[data-list="bullet"]': {
            listStyleType: 'disc',
        },
        '.ql-editor ol li[data-list="ordered"], .ql-editor ul li[data-list="ordered"]': {
            listStyleType: 'decimal',
        },
        '.ql-bubble .ql-editor': {
            h2: {
                fontSize: '1.5em',
                margin: 0,
                padding: 0,
                fontWeight: 600,
            },
            h3: {
                fontSize: '1.17em',
                margin: 0,
                padding: 0,
                fontWeight: 600,
            },
        },
    },
    colors: [
        'rgba(34, 34, 34, 1)',
        'rgba(34, 34, 34, 0.8)',
        'rgba(34, 34, 34, 0.24)',
        'rgba(174, 110, 211, 1)',
        'rgba(123, 67, 156, 1)',
        'rgba(58, 109, 157, 1)',
        'rgba(82, 150, 213, 1)',
        'rgba(67, 143, 122, 1)',
        'rgba(96, 191, 116, 1)',
        'rgba(216, 131, 59, 1)',
        'rgba(234, 198, 69, 1)',
        'rgba(172, 65, 50, 1)',
        'rgba(207, 75, 56, 1)',
    ],
};
