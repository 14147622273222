import { FormControlRichTextProps, FormElementControlPropsType } from './index';
import { FormComponentBuilder } from '../index';
import Quill from 'quill';
import styles from './FormRichText.styles';
import { Box, ClickAwayListener } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { renderToString } from 'react-dom/server';
import IconMi from 'components/common/icon/IconMi';
import RichTextLinkTooltip from './FormRichTextParts/RichTextLinkTooltip';
import { useDispatch } from 'react-redux';
import { setFormElementSharedState } from 'store/formSlice';

let icons: any = Quill.import('ui/icons');
icons['bold'] = renderToString(<IconMi icon={'bold'} />);
icons['italic'] = renderToString(<IconMi icon={'italic'} />);
icons['underline'] = renderToString(<IconMi icon={'underline'} />);
icons['list']['bullet'] = renderToString(<IconMi icon={'sort-list'} />);
icons['list']['ordered'] = renderToString(<IconMi icon={'sort-numbers'} />);
icons['link'] = renderToString(<IconMi icon={'link'} />);
icons['image'] = renderToString(<IconMi icon={'image'} />);
icons['color'] = renderToString(<IconMi icon={'text-color'} />);

export default function FormRichText({
    controlProps,
    elementProps,
}: FormElementControlPropsType<FormControlRichTextProps>) {
    const initialValue = useRef<string>(controlProps.value);
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const richTextContainer = useRef<any>(null);
    const quillRef = useRef<any>(null);
    const quillEditorRef = useRef<any>(null);
    const dispatch = useDispatch();

    const [showLinkTooltip, setShowLinkTooltip] = useState<false | number>(false);

    const selectionChangeHandler = (
        range: null | { index: number; length: number },
        oldRange: null | { index: number; length: number },
        source: string,
    ) => {
        if (range) {
            setShowLinkTooltip(false);
            if (range.length == 0) {
                //set cursor without selection (like focus)
                const currentFormat = quillRef.current.getFormat();
                if (currentFormat.link) {
                    setShowLinkTooltip(Date.now());
                }
                //console.log('User cursor is on', range.index);
            } else {
                //selection range
                //console.log('User has highlighted', quill.getText(range.index, range.length));
            }
        } else {
            //like blur, click out off editor
            //console.log('Cursor not in the editor');
        }
    };

    const textChangeHandler = (delta: any, oldDelta: any, source: string) => {
        controlProps.onChange(quillRef.current.getSemanticHTML());
    };

    useEffect(() => {
        quillRef.current = new Quill(quillEditorRef.current, {
            readOnly: controlProps.readOnly,
            theme: 'bubble',
            modules: {
                toolbar: [
                    [{ header: [2, 3, false] }],
                    ['bold', 'italic', 'underline'],
                    [
                        {
                            color: styles.colors,
                        },
                        { list: 'ordered' },
                        { list: 'bullet' },
                    ],
                    ['link', 'image'],
                ],
            },
        });
        quillRef.current.on('selection-change', selectionChangeHandler);
        quillRef.current.on('text-change', textChangeHandler);

        if (richTextContainer.current) {
            const selectIcons = richTextContainer.current.querySelectorAll('.ql-header .ql-picker-label svg');
            selectIcons.forEach((icon: any) => {
                icon.outerHTML = renderToString(<IconMi icon={'chevron-down'} />);
            });
        }

        return () => {
            quillRef.current.off('selection-change', selectionChangeHandler);
            quillRef.current.off('text-change', textChangeHandler);
        };
    }, []);

    useEffect(() => {
        let pureText = '';
        if (quillRef.current) {
            pureText = quillRef.current.getText().trim();
        }

        dispatch(
            setFormElementSharedState({
                formKey: controlProps.form.formKey,
                componentName: controlProps.name,
                sharedState: {
                    pure_text: pureText,
                },
            }),
        );
    }, [controlProps.value]);

    return (
        <>
            <ClickAwayListener
                onClickAway={(event: Event | React.SyntheticEvent) => {
                    setShowLinkTooltip(false);
                }}
            >
                <Box
                    sx={styles.root}
                    onFocus={() => {
                        setIsFocused(true);
                    }}
                    onBlur={() => {
                        setIsFocused(false);
                    }}
                    className={isFocused || showLinkTooltip ? 'focused' : undefined}
                    ref={richTextContainer}
                >
                    <Box
                        ref={quillEditorRef}
                        dangerouslySetInnerHTML={{
                            __html: initialValue.current,
                        }}
                    />
                    {quillRef.current && (
                        <RichTextLinkTooltip
                            showTooltip={showLinkTooltip}
                            setShowTooltip={setShowLinkTooltip}
                            quill={quillRef.current}
                            richTextContainerRef={richTextContainer}
                        />
                    )}
                </Box>
            </ClickAwayListener>
        </>
    );
}

export class FormRichTextBuilder extends FormComponentBuilder {
    prepareProps(): FormControlRichTextProps {
        return {
            ...this.controlProps,
        };
    }
}
