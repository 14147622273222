import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { Fade, Grid, IconButton, Skeleton, Stack, TextField, Tooltip } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import ReactSelect from 'components/common/react-select/ReactSelect';
import { ColumnData, Rule as RuleType } from 'components/dataset-viewer/index';
import { FormComponentValue } from 'components/common/form/layout/control';
import {
    compareConditions,
    compareDateShifts,
    datetimeFieldConditions,
    datetimeSecondFieldConditions,
    getDefaultCondition,
    getTranslatedArray,
    numericFieldConditions,
    numericLongDataConditions,
    textFieldConditions,
    textLongDataConditions,
    textSecondFieldConditions,
} from '../helpers';
import useBundleTranslation from 'i18n';

function Rule({
    rule,
    fields,
    onRuleDelete,
    onRuleUpdate,
    ruleIndex,
    type,
}: {
    rule: RuleType;
    fields: ColumnData[];
    onRuleDelete: (ruleIndex: number) => void;
    onRuleUpdate: (ruleIndex: number, data: RuleType) => void;
    ruleIndex: number;
    type: 'current' | 'new';
}) {
    const { t } = useBundleTranslation(['components/dataset-viewer/dataset_viewer']);
    const textFormRef = useRef<HTMLDivElement | null>(null);

    const [selectedField, setSelectedField] = useState<ColumnData>();
    const [conditions, setConditions] = useState<FormComponentValue[]>([]);
    const [secondFieldOptions, setSecondFieldOptions] = useState<FormComponentValue[]>([]);

    const fieldsSelectData: FormComponentValue[] = useMemo(() => {
        return fields.map((field) => {
            const select: FormComponentValue = {
                label: field.label,
                value: field.key,
            };

            return select;
        });
    }, [fields]);

    useEffect(() => {
        const selectedFieldFind = fields.find((field) => field.key === rule.key);

        if (selectedFieldFind) {
            setSelectedField(selectedFieldFind);
        }
    }, [fields]);

    const isDateField = selectedField && selectedField.value_type === 'datetime';
    const isTextField = selectedField && selectedField.value_type === 'text';

    const onSelectedFieldUpdate = (value: string) => {
        const selectedFieldFind = fields.find((field) => {
            return field.key === value;
        });

        if (selectedFieldFind) {
            setSelectedField(selectedFieldFind);
        }
    };

    const showSecondField = (): boolean => {
        if (selectedField && secondFieldOptions.length > 0) {
            if (isTextField && textSecondFieldConditions.includes(rule.condition)) {
                return true;
            }
            if (isDateField && datetimeSecondFieldConditions.includes(rule.condition)) {
                return true;
            }
            if (selectedField.value_type === 'numeric' && datetimeSecondFieldConditions.includes(rule.condition)) {
                return true;
            }
        }

        return false;
    };

    const showDataField = (): boolean => {
        if (selectedField) {
            if (
                rule.field_second === 'a value' &&
                !['is empty', 'is not empty', 'is in list', 'is not in list'].includes(rule.condition)
            ) {
                return true;
            }
        }

        return false;
    };

    const showLongDataField = (): boolean => {
        if (selectedField && !showDataField()) {
            if (isTextField && textLongDataConditions.includes(rule.condition)) {
                return true;
            }
            if (
                selectedField.value_type === 'numeric' &&
                numericLongDataConditions.includes(rule.condition) &&
                rule.field_second === 'a value'
            ) {
                return true;
            }
        }

        return false;
    };

    const showDateDataInput = (): boolean => {
        if (selectedField) {
            if (
                selectedField.value_type === 'datetime' &&
                rule.field_second > '' &&
                rule.field_second !== 'a value' &&
                !['is empty', 'is not empty'].includes(rule.condition)
            ) {
                return true;
            }
        }

        return false;
    };

    const showNumericDataInput = (): boolean => {
        if (selectedField) {
            if (
                selectedField.value_type === 'numeric' &&
                rule.field_second > '' &&
                rule.field_second !== 'a value' &&
                !['equals', 'does not equal'].includes(rule.condition)
            ) {
                return true;
            }
        }

        return false;
    };

    const onDataUpdate = (event: ChangeEvent<HTMLInputElement>) => {
        const changedRule = { ...rule };
        changedRule.data = event.target.value;
        onRuleUpdate(ruleIndex, changedRule);
    };

    const rulePostfix = `${type}-${ruleIndex}`;

    useEffect(() => {
        if (selectedField) {
            const tmpSecondFieldOptions: FormComponentValue[] = [{ label: t('rule.value'), value: 'a value' }];

            if (isTextField) {
                setConditions(getTranslatedArray(textFieldConditions, t));
                fields.forEach((field) => {
                    if (field.value_type === 'text' && field.label !== selectedField.label) {
                        tmpSecondFieldOptions.push({
                            label: field.label,
                            value: field.key,
                        });
                    }
                });
            }
            if (selectedField.value_type === 'numeric') {
                setConditions(getTranslatedArray(numericFieldConditions, t));
                fields.forEach((field) => {
                    if (field.value_type === 'numeric' && field.label !== selectedField.label) {
                        tmpSecondFieldOptions.push({
                            label: field.label,
                            value: field.key,
                        });
                    }
                });
            }
            if (isDateField) {
                setConditions(getTranslatedArray(datetimeFieldConditions, t));
                tmpSecondFieldOptions.push({
                    label: t('rule.snapshot_date'),
                    value: 'snapshot date',
                });
                fields.forEach((field) => {
                    if (field.value_type === 'datetime' && field.label !== selectedField.label) {
                        tmpSecondFieldOptions.push({
                            label: field.label,
                            value: field.key,
                        });
                    }
                });
            }

            if (selectedField.key !== rule.key) {
                const changedRule = { ...rule };
                changedRule.key = selectedField.key;
                changedRule.label = selectedField.name;
                changedRule.label_new = selectedField.name;
                changedRule.field = selectedField.id;
                changedRule.condition = getDefaultCondition(selectedField.value_type);
                changedRule.instance = selectedField.current ? 'current' : 'prior';
                onRuleUpdate(ruleIndex, changedRule);
            }

            setSecondFieldOptions(tmpSecondFieldOptions);
        }
    }, [selectedField]);

    const tooltipHint = isDateField
        ? `${selectedField?.label} ${t('rule.date_validation_hint')}`
        : isTextField
          ? t('rule.text_validation_hint')
          : '';

    useEffect(() => {
        const input = textFormRef.current?.getElementsByTagName('input').item(0);
        console.log(input);
        if (input) {
            input.setAttribute('data-test', `rule-value_input-${rulePostfix}`);
        }
    }, [textFormRef, selectedField]);

    return selectedField ? (
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ paddingLeft: '8px' }}>
            <Grid container direction={'row'} spacing={1} justifyContent="flex-start">
                <Grid item xs={2} className={'inline-edit--select'}>
                    <ReactSelect
                        data={fieldsSelectData}
                        isLoading={false}
                        dataTest={`rule-field-${rulePostfix}`}
                        value={selectedField.key}
                        update={onSelectedFieldUpdate}
                    />
                </Grid>
                <Grid item xs={3} className={'inline-edit--select'}>
                    <ReactSelect
                        data={conditions}
                        isLoading={false}
                        dataTest={`rule-condition-${rulePostfix}`}
                        value={rule.condition}
                        update={(value) => {
                            const changedRule = { ...rule };
                            changedRule.condition = value;
                            onRuleUpdate(ruleIndex, changedRule);
                        }}
                    />
                </Grid>
                {showSecondField() && (
                    <Grid item xs={2} className={'inline-edit--select'}>
                        <ReactSelect
                            data={secondFieldOptions}
                            isLoading={false}
                            dataTest={`rule-key-second-${rulePostfix}`}
                            value={rule.key_second}
                            update={(value) => {
                                const changedRule = { ...rule };
                                const selectedField = fields.find((field) => field.key === value);

                                if (selectedField) {
                                    changedRule.field_second = selectedField.id;
                                    changedRule.key_second = selectedField.key;
                                    changedRule.instance_second = selectedField.current ? 'current' : 'prior';
                                } else {
                                    changedRule.field_second = value;
                                    changedRule.key_second = value;
                                }

                                onRuleUpdate(ruleIndex, changedRule);
                            }}
                        />
                    </Grid>
                )}
                {showDataField() && (
                    <Grid item xs={5} className={'inline-edit--text-field'}>
                        <Tooltip
                            title={tooltipHint}
                            arrow
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 500 }}
                            placement={'bottom-start'}
                            leaveDelay={500}
                        >
                            <TextField
                                ref={textFormRef}
                                fullWidth
                                value={rule.data}
                                onChange={onDataUpdate}
                                placeholder={isDateField ? 'YYYY-MM-DD' : t('text_field_placeholder')}
                            />
                        </Tooltip>
                    </Grid>
                )}
                {showLongDataField() && (
                    <Grid item xs={7} className={'inline-edit--text-field'}>
                        <TextField
                            ref={textFormRef}
                            fullWidth
                            value={rule.data}
                            onChange={onDataUpdate}
                            placeholder={t('text_field_placeholder')}
                        />
                    </Grid>
                )}
                {showDateDataInput() && (
                    <Grid xs={5} item container spacing={1} alignItems={'center'}>
                        <Grid item xs={2} justifyContent={'flex-end'}>
                            {['equals', 'does not equal'].includes(rule.condition) ? t('rule.offset_by') : t('rule.by')}
                        </Grid>
                        <Grid item xs={7} className={'inline-edit--text-field'}>
                            <TextField
                                ref={textFormRef}
                                fullWidth
                                value={rule.data}
                                onChange={onDataUpdate}
                                placeholder={t('text_field_placeholder')}
                            />
                        </Grid>
                        <Grid item xs={3} className={'inline-edit--select'}>
                            <ReactSelect
                                data={getTranslatedArray(compareDateShifts, t)}
                                isLoading={false}
                                dataTest={`rule-compare-date-${rulePostfix}`}
                                value={rule.compare_date_shift}
                                update={(value) => {
                                    const changedRule = { ...rule };
                                    changedRule.compare_date_shift = value;
                                    onRuleUpdate(ruleIndex, changedRule);
                                }}
                            />
                        </Grid>
                    </Grid>
                )}
                {showNumericDataInput() && (
                    <Grid xs={5} item container spacing={1} alignItems={'center'}>
                        <Grid item xs={1} justifyContent={'flex-end'}>
                            {t('rule.by')}
                        </Grid>
                        <Grid item xs={4} className={'inline-edit--select'}>
                            <ReactSelect
                                dataTest={`rule-compare-condition-${rulePostfix}`}
                                data={getTranslatedArray(compareConditions, t)}
                                isLoading={false}
                                value={rule.compare_condition}
                                update={(value) => {
                                    const changedRule = { ...rule };
                                    changedRule.compare_condition = value;
                                    onRuleUpdate(ruleIndex, changedRule);
                                }}
                            />
                        </Grid>
                        {rule.compare_condition !== 'any amount' && (
                            <Grid item xs={7} className={'inline-edit--text-field'}>
                                <TextField
                                    fullWidth
                                    value={rule.data}
                                    onChange={onDataUpdate}
                                    placeholder={t('text_field_placeholder')}
                                />
                            </Grid>
                        )}
                    </Grid>
                )}
            </Grid>
            <IconButton
                data-test={`delete-rule_button-${rulePostfix}`}
                sx={{ p: '6px' }}
                onClick={() => {
                    onRuleDelete(ruleIndex);
                }}
            >
                <IconMi icon={'trash'} fontSize="16" />
            </IconButton>
        </Stack>
    ) : (
        <Skeleton key={`${rule.key}_${ruleIndex}_skeleton`} />
    );
}

export default Rule;
