import { alpha } from '@mui/material';

export const MuiTypography = {
    defaultProps: {
        variantMapping: {
            h1: 'h1',
            h2: 'h2',
            h3: 'h3',
            h4: 'h4',
            h5: 'h5',
            h6: 'h6',
            heading: 'div',
            subtitle1: 'h6',
            subtitle2: 'h6',
            body1: 'p',
            body2: 'p',
            inherit: 'p',
            label: 'p',
        },
    },
    styleOverrides: {
        root: ({ theme }: { theme: any }) => {
            return {};
        },
        heading: ({ theme }: { theme: any }) => {
            return {
                borderBottom: `1px solid ${theme.palette.primary.main}`,
                color: theme.palette.primary.main,
                '.heading-icon': {
                    verticalAlign: 'text-bottom',
                    fontSize: '1.142857142857143em', //16px from default 14px
                    marginRight: '0.357142857142857em', //5px from default 14px
                },
            };
        },
        label: ({ theme }: { theme: any }) => {
            return {
                background: `${alpha(theme.palette.text.primary, 0.08)}`,
                padding: '7px 8px',
                borderRadius: '3px',
                marginBottom: 0,
            };
        },
    },
};
