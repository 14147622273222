import { Box, FormControl, IconButton, Input, Popper, Stack, alpha, Divider } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import IconMi from 'components/common/icon/IconMi';
import { Instance } from '@popperjs/core';
import { useClipboard } from 'use-clipboard-copy';

interface RichTextLinkTooltipProps {
    showTooltip: boolean | number;
    quill: any;
    richTextContainerRef: any;
    setShowTooltip: (value: false | number) => void;
}
export default function RichTextLinkTooltip(props: RichTextLinkTooltipProps) {
    const { showTooltip = false, quill, richTextContainerRef, setShowTooltip = () => {} } = props;
    const [activeState, setActiveState] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [currentEditLinkHref, setCurrentEditLinkHref] = useState<string>('');
    const [currentEditLinkText, setCurrentEditLinkText] = useState<string>('');
    const currentEditLinkRangeRef = useRef<{ index: number; length: number }>({
        index: 0,
        length: 0,
    });

    const clipboard = useClipboard();
    const positionLinkTooltipRef = useRef<{ x: number; y: number }>({
        x: 0,
        y: 0,
    });
    const linkTooltipPopperRef = useRef<Instance>(null);

    const onShowLinkTooltip = () => {
        const selectionRange = quill.getSelection();

        const containerBounds = quill.container.getBoundingClientRect();
        const linkBounds = quill.getBounds(selectionRange.index);
        positionLinkTooltipRef.current = {
            x: containerBounds.left + linkBounds.left,
            y: containerBounds.top + linkBounds.top - 5, //5px tooltip arrow
        };

        if (linkTooltipPopperRef.current != null) {
            linkTooltipPopperRef.current.update();
        }

        const currentFormat = quill.getFormat();
        setCurrentEditLinkHref(currentFormat.link);

        const [leaf, leafOffset] = quill.getLeaf(selectionRange.index);
        currentEditLinkRangeRef.current = { index: selectionRange.index - leafOffset, length: leaf.domNode.length };
        setCurrentEditLinkText(leaf.text);
        setActiveState(true);
    };

    const removeLink = () => {
        quill.formatText(currentEditLinkRangeRef.current.index, currentEditLinkRangeRef.current.length, 'link', false);
        setActiveState(false);
    };

    const changeLinkText = () => {
        const currentFormat = quill.getFormat(
            currentEditLinkRangeRef.current.index,
            currentEditLinkRangeRef.current.length,
        );
        quill.deleteText(currentEditLinkRangeRef.current.index, currentEditLinkRangeRef.current.length);
        if (currentEditLinkText !== '') {
            quill.insertText(currentEditLinkRangeRef.current.index, currentEditLinkText, currentFormat);
            currentEditLinkRangeRef.current.length = currentEditLinkText.length;
        }
    };

    const changeLinkUrl = () => {
        quill.formatText(
            currentEditLinkRangeRef.current.index,
            currentEditLinkRangeRef.current.length,
            'link',
            currentEditLinkHref,
        );
        setActiveState(false);
    };

    const updateLinkSettings = () => {
        changeLinkText();

        if (currentEditLinkText === '') {
            setActiveState(false);
            return false;
        }

        if (currentEditLinkHref === '') {
            removeLink();
            return false;
        }
        changeLinkUrl();
    };

    const closeLinkTooltipOnChangePos = () => {
        quill.blur();
        setActiveState(false);
    };

    useEffect(() => {
        if (showTooltip) {
            onShowLinkTooltip();
        } else {
            setIsEdit(false);
            setActiveState(false);
        }
    }, [showTooltip]);

    useEffect(() => {
        if (!activeState) {
            setShowTooltip(false);
        }
    }, [activeState]);

    useEffect(() => {
        let scrollContainer: any;
        if (richTextContainerRef.current) {
            const linkField = richTextContainerRef.current.querySelector('.ql-tooltip-editor input[data-link]');
            if (linkField) {
                linkField.dataset.link = 'https://metricinsights.com';
            }

            scrollContainer = richTextContainerRef.current.closest('.scroll-content-container');
            if (scrollContainer) {
                scrollContainer.addEventListener('scroll', closeLinkTooltipOnChangePos);
            }
        }

        window.addEventListener('scroll', closeLinkTooltipOnChangePos);
        window.addEventListener('resize', closeLinkTooltipOnChangePos);

        return () => {
            window.removeEventListener('scroll', closeLinkTooltipOnChangePos);
            window.removeEventListener('resize', closeLinkTooltipOnChangePos);
            if (scrollContainer) {
                scrollContainer.removeEventListener('scroll', closeLinkTooltipOnChangePos);
            }
        };
    }, []);

    return (
        <Popper
            placement={'top'}
            open={activeState}
            popperRef={linkTooltipPopperRef}
            anchorEl={{
                getBoundingClientRect: () => {
                    return new DOMRect(positionLinkTooltipRef.current.x, positionLinkTooltipRef.current.y, 0, 0);
                },
            }}
        >
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    borderRadius: 1,
                    boxShadow: (theme) => `0px 1px 4px 0px ${alpha(theme.palette.text.primary, 0.32)}`,
                    position: 'relative',
                }}
            >
                <Box
                    className={'arrow'}
                    sx={{
                        position: 'absolute',
                        top: '100%',
                        left: '50%',
                        ml: '-8px',
                        overflow: 'hidden',
                        width: '16px',
                        height: '10px',
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        '&:before': {
                            content: '""',
                            display: 'block',
                            width: '7px',
                            height: '7px',
                            backgroundColor: 'background.default',
                            boxShadow: (theme) => `0px 1px 4px 0px ${alpha(theme.palette.text.primary, 0.32)}`,
                            transform: 'rotate(45deg)',
                            mb: '10px',
                        },
                    }}
                />
                {!isEdit ? (
                    <Stack direction={'row'} alignItems={'center'} sx={{ py: 0.75, pl: 1.5, pr: 1 }}>
                        <Stack
                            direction={'row'}
                            component={'a'}
                            href={currentEditLinkHref}
                            target={'_blank'}
                            alignItems={'center'}
                            sx={{
                                maxWidth: '300px',
                                color: (theme) => alpha(theme.palette.text.primary, 0.8),
                                textDecoration: 'none',
                                '&:hover': {
                                    textDecoration: 'underline',
                                    color: 'primary.main',
                                },
                            }}
                        >
                            <Box sx={{ display: 'inline-flex', mr: 1 }}>
                                <IconMi icon={'globe'} sx={{ fontSize: '16px' }} />
                            </Box>
                            <Box sx={{ overflow: 'hidden' }}>
                                <Box
                                    sx={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    {currentEditLinkHref}
                                </Box>
                            </Box>
                        </Stack>

                        <IconButton
                            sx={{ p: 0, ml: 2 }}
                            onClick={() => {
                                removeLink();
                            }}
                        >
                            <IconMi icon="unlink" sx={{ fontSize: '16px' }} />
                        </IconButton>

                        <Divider orientation="vertical" sx={{ height: 16, mx: 1.5, alignSelf: 'center' }} />

                        <IconButton
                            sx={{ p: 0, mr: 1 }}
                            onClick={() => {
                                clipboard.copy(currentEditLinkHref);
                                setActiveState(false);
                            }}
                        >
                            <IconMi icon="copy" sx={{ fontSize: '16px' }} />
                        </IconButton>

                        <IconButton
                            sx={{ p: 0 }}
                            onClick={() => {
                                setIsEdit(true);
                            }}
                        >
                            <IconMi icon="gear" sx={{ fontSize: '16px' }} />
                        </IconButton>
                    </Stack>
                ) : (
                    <Stack sx={{ width: '320px' }}>
                        <Stack sx={{ p: 1.5 }} spacing={1.5}>
                            <Box>
                                <Box sx={{ pb: 0.5, lineHeight: '12px', fontSize: '11px' }}>URL</Box>
                                <FormControl fullWidth>
                                    <Input
                                        value={currentEditLinkHref}
                                        fullWidth
                                        onChange={(event) => {
                                            setCurrentEditLinkHref(event.target.value);
                                        }}
                                    />
                                </FormControl>
                            </Box>

                            <Box>
                                <Box sx={{ pb: 0.5, lineHeight: '12px', fontSize: '11px' }}>Link Title</Box>
                                <FormControl fullWidth>
                                    <Input
                                        value={currentEditLinkText}
                                        fullWidth
                                        onChange={(event) => {
                                            setCurrentEditLinkText(event.target.value);
                                        }}
                                    />
                                </FormControl>
                            </Box>
                        </Stack>
                        <Stack
                            direction={'row'}
                            justifyContent={'center'}
                            sx={{
                                borderTop: (theme) => `1px solid ${alpha(theme.palette.text.primary, 0.16)}`,
                                p: 1,
                            }}
                        >
                            <Stack
                                direction={'row'}
                                alignItems={'center'}
                                sx={{
                                    '&:hover': {
                                        color: 'primary.main',
                                        cursor: 'pointer',
                                    },
                                }}
                                onClick={() => {
                                    updateLinkSettings();
                                }}
                            >
                                <Box sx={{ mr: 1, display: 'inline-flex' }}>
                                    <IconMi icon={'save'} sx={{ fontSize: '16px' }} />
                                </Box>
                                <Box>Save</Box>
                            </Stack>
                        </Stack>
                    </Stack>
                )}
            </Box>
        </Popper>
    );
}
