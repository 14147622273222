import { alpha, Theme } from '@mui/material';

export default {
    wrapper: {
        position: 'absolute',
        left: 0,
        bottom: 0,
        top: '86px',
        pt: 6,
        width: '220px',
        overflow: 'auto',
        zIndex: 1,
        '&:not(.is-scrollable)': {
            display: 'none !important',
        },
        '@media (max-width:1599px)': {
            display: 'none',
        },
        '.anchor-navigation-list': {
            listStyle: 'none',
            pl: 2,
            m: 0,
            backgroundColor: 'background.default',
        },
        '.section-item': {
            '&-enter': {
                opacity: 0,
            },
            '&-enter-active': {
                opacity: 1,
                transition: 'opacity 500ms ease-in',
            },
        },
    },
    item: {
        display: 'flex',
        cursor: 'pointer',
        color: (theme: Theme) => alpha(theme.palette.text.primary, 0.4),
        padding: '2px 0',
        wordWrap: 'break-word',
        userSelect: 'none',
        pr: '20px',
        transition: 'padding-right 100ms ease-in',
        '&:not(.active):hover': {
            color: (theme: Theme) => theme.palette.primary.main,
        },
        '&:before': {
            content: '""',
            position: 'relative',
            display: 'inline-flex',
            width: '0',
            height: '2px',
            marginRight: '0',
            transition: 'all 100ms ease-in',
            backgroundColor: (theme: Theme) => theme.palette.primary.main,
            verticalAlign: 'top',
            top: '8px',
        },
        '&.active': {
            pr: 0,
            transition: 'padding-right 100ms ease-in',
            color: (theme: Theme) => theme.palette.primary.main,
            '&:before': {
                width: '12px',
                marginRight: '8px',
                transition: 'all 100ms ease-in',
            },
        },
    },
    label: {
        overflow: 'hidden',
    },
};
