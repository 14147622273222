import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import IconMi from '../icon/IconMi';

const Collapse = ({ title, children }: { title: string; children: React.ReactNode }) => {
    const [open, setOpen] = useState(false);

    const toggle = () => {
        setOpen((open) => !open);
    };

    return (
        <>
            <Box
                sx={{ marginBottom: (theme) => theme.size.pxValue(theme.size.componentFormIndent), cursor: 'pointer' }}
            >
                <Typography variant="heading" onClick={toggle}>
                    <IconMi className={'heading-icon'} icon={open ? 'nav-arrows-expand' : 'nav-arrows-collapse'} />
                    {title}
                </Typography>
            </Box>
            {open && children}
        </>
    );
};

export default Collapse;
