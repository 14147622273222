import { Box } from '@mui/material';
import React, { createRef, useEffect, useRef, useState } from 'react';
import styles from './AnchorNavigation.styles';
import { FormRendererAPIType } from 'components/common/form';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

interface AnchorNavigationProps {
    tabName?: string;
    isTabActive?: boolean;
}

interface AnchorNavigationItemProps {
    label: string;
    key: string;
    nodeRef: any;
}

export default function AnchorNavigation(props: AnchorNavigationProps) {
    const { tabName, isTabActive } = props;
    const initialSetup = useRef<boolean>(false);
    const anchorNavigationHolderRef = useRef<any>(null);
    const tabContentRef = useRef<any>(null);
    const ATTRIBUTE_NAME = 'data-heading-key';
    const SCROLL_TO_INDENT = 16;

    const sectionListRef = useRef<AnchorNavigationItemProps[]>([]);
    const [sectionList, setSectionList] = useState<AnchorNavigationItemProps[]>(sectionListRef.current);
    const updateSectionList = (newData: AnchorNavigationItemProps[]) => {
        //const data = newData.length > 1 ? newData : [];
        sectionListRef.current = newData;
        setSectionList(newData);
    };

    const activeSectionRef = useRef<string>('');
    const activeSectionPosRef = useRef<number | null>(null);
    const [activeSection, setActiveSection] = useState<string>(activeSectionRef.current);
    const updateActiveSection = (newActiveSection: string) => {
        activeSectionRef.current = newActiveSection;
        setActiveSection(newActiveSection);
    };

    const updateActiveSectionPos = () => {
        const el = getHeadingElByKey(activeSectionRef.current);
        activeSectionPosRef.current = el ? el.getBoundingClientRect().top : null;
    };

    const scrollEndTimer = useRef<any>(null);
    const scrollContainer = useRef<any>(null);

    const selectionElement = useRef<any>(null);
    const selectionElementAnimationTimer = useRef<any>(null);
    const onSelectSectionActive = useRef<boolean>(false);

    const intersectionObserver = useRef<any>(null);
    const mutationObserver = useRef<any>(null);

    const checkIsActiveTab = () => {
        return tabContentRef.current?.classList.contains('active-tab-content');
    };

    const updateIsScrollableContainer = (force?: boolean) => {
        if (force || checkIsActiveTab()) {
            if (anchorNavigationHolderRef.current && scrollContainer.current) {
                const minimalScrollSize = 80;
                anchorNavigationHolderRef.current.classList.toggle(
                    'is-scrollable',
                    scrollContainer.current.scrollHeight > scrollContainer.current.clientHeight + minimalScrollSize,
                );
            }
        }
    };

    const checkIsHeadingEl = (element: any) => {
        if (!(element instanceof HTMLElement)) return false;

        if (element.matches('.heading-item__label')) return true;

        if (element.querySelector('.heading-item__label')) return true;

        return false;
    };

    const getHeadingElByKey = (key: string) => {
        return scrollContainer.current.querySelector(`.heading-item__label[${ATTRIBUTE_NAME}="${key}"]`);
    };

    const animateSelectedItem = () => {
        if (selectionElement.current) {
            selectionElement.current.classList.add('anim-flash');
            selectionElement.current.addEventListener(
                'animationend',
                () => {
                    selectionElement.current.classList.remove('anim-flash');
                },
                { once: true },
            );
        }
    };
    const clearAnimatedClass = () => {
        const clearAnimElements = scrollContainer.current.querySelectorAll('.heading-item.anim-flash');
        if (clearAnimElements) {
            clearAnimElements.forEach((animEl: any) => {
                animEl.classList.remove('anim-flash');
            });
        }
    };

    const checkIsHiddenByStyles = (el: any) => {
        if (el.classList.contains('d-none')) return true;
        if (el.style.display == 'none') return true;
        return false;
    };
    const isVisibleElement = (headingEl: any) => {
        if (checkIsHiddenByStyles(headingEl)) return false;

        let parentEl = headingEl.parentNode;
        while (!parentEl.classList.contains('default-components')) {
            if (checkIsHiddenByStyles(parentEl)) {
                return false;
            } else {
                parentEl = parentEl.parentNode;
            }
        }
        return true;
    };
    const updateAnchorList = () => {
        const headingElements = scrollContainer.current.querySelectorAll('.heading-item .heading-item__label');
        const newSectionList: AnchorNavigationItemProps[] = [];
        if (headingElements.length) {
            headingElements.forEach((heading: any) => {
                //&& heading.checkVisibility()
                if (heading.textContent && isVisibleElement(heading)) {
                    const key = String(newSectionList.length);
                    heading.setAttribute(ATTRIBUTE_NAME, key);
                    newSectionList.push({
                        label: heading.textContent,
                        key: key,
                        nodeRef: createRef(),
                    });
                    intersectionObserver.current.observe(heading);
                }
            });
        }
        updateSectionList(newSectionList);
    };

    const onSelectSection = (sectionKey: string) => {
        onSelectSectionActive.current = true;
        selectionElement.current = null;

        clearAnimatedClass();

        if (selectionElementAnimationTimer.current) {
            clearTimeout(selectionElementAnimationTimer.current);
        }

        const headingLabelEl = getHeadingElByKey(sectionKey);

        if (headingLabelEl) {
            const headingWrapper = headingLabelEl.closest('.heading-item');

            if (headingWrapper) {
                selectionElement.current = headingWrapper;
                selectionElementAnimationTimer.current = setTimeout(() => {
                    animateSelectedItem();
                }, 70);
            }

            scrollContainer.current.scrollTo({
                behavior: 'smooth',
                top:
                    headingLabelEl.getBoundingClientRect().top -
                    scrollContainer.current.getBoundingClientRect().top +
                    scrollContainer.current.scrollTop -
                    SCROLL_TO_INDENT,
            });
        }
        updateActiveSection(sectionKey);
    };

    const getFirstActiveSection = () => {
        let firstSection = '';

        if (sectionListRef.current.length) {
            const firstHeadingEl = getHeadingElByKey(sectionListRef.current[0].key);
            if (!firstHeadingEl) return firstSection;

            const formHeadingEl = firstHeadingEl.closest('.form-component-holder');
            if (!formHeadingEl) return firstSection;

            let isExistPreviousVisibleElement = false;
            let previousSiblingElement = formHeadingEl.previousSibling;

            while (!isExistPreviousVisibleElement && previousSiblingElement) {
                isExistPreviousVisibleElement = (previousSiblingElement as HTMLElement).checkVisibility();
                previousSiblingElement = previousSiblingElement.previousSibling;
            }
            if (!isExistPreviousVisibleElement) firstSection = sectionListRef.current[0].key;
        }
        return firstSection;
    };
    const processActiveSection = () => {
        if (activeSectionRef.current) {
            const currentPos = getHeadingElByKey(activeSectionRef.current).getBoundingClientRect().top;
            if (currentPos == activeSectionPosRef.current) return false;
        }

        let newActive = getFirstActiveSection();
        if (sectionListRef.current.length && scrollContainer.current) {
            const containerTopPos = scrollContainer.current.getBoundingClientRect().y;
            sectionListRef.current.some((heading) => {
                const element = getHeadingElByKey(heading.key);
                if (element) {
                    const sectionTopPos = element.getBoundingClientRect().y;
                    if (containerTopPos > sectionTopPos) {
                        newActive = heading.key;
                        return false;
                    } else {
                        return true;
                    }
                }
                return false;
            });
        }
        updateActiveSection(newActive);
        updateActiveSectionPos();
    };
    const onWindowResize = () => {
        updateIsScrollableContainer();
    };

    const onContainerScroll = () => {
        if (onSelectSectionActive.current) {
            if (selectionElementAnimationTimer.current) {
                clearTimeout(selectionElementAnimationTimer.current);
            }
            if (scrollEndTimer.current) {
                clearTimeout(scrollEndTimer.current);
            }
            scrollEndTimer.current = setTimeout(() => {
                onSelectSectionActive.current = false;
                updateActiveSectionPos();
                animateSelectedItem();
            }, 60);
        }
    };

    const onIntersectionObserver = (entries: any, observer: any) => {
        if (!initialSetup.current) return false;

        if (!onSelectSectionActive.current && checkIsActiveTab()) {
            processActiveSection();
        }
    };

    const onMutationObserver = (mutationsList: any) => {
        if (!initialSetup.current) return false;
        const isNeedUpdate = mutationsList.some((mutationRecord: any) => {
            let isExistHeading = false;
            if (mutationRecord.type === 'childList') {
                for (let node of mutationRecord.addedNodes) {
                    isExistHeading = checkIsHeadingEl(node);
                    if (isExistHeading) break;
                }
                if (isExistHeading) return true;

                for (let node of mutationRecord.removedNodes) {
                    isExistHeading = checkIsHeadingEl(node);
                    if (isExistHeading) break;
                }
                if (isExistHeading) return true;
            }
            if (mutationRecord.type === 'attributes') {
                const headingAttEl = checkIsHeadingEl(mutationRecord.target);
                switch (mutationRecord.attributeName) {
                    case 'class':
                        if (headingAttEl) {
                            const hadNoneClass = mutationRecord.oldValue.split(' ').includes('d-none');
                            const hasNoneClass = mutationRecord.target.classList.contains('d-none');
                            if (hadNoneClass !== hasNoneClass) isExistHeading = true;
                        }
                        break;
                    case 'style':
                        if (headingAttEl) {
                            const hadNoneClass = mutationRecord.oldValue.includes('display: none');
                            const hasNoneClass = mutationRecord.target.style.display == 'none';
                            if (hadNoneClass !== hasNoneClass) isExistHeading = true;
                        }
                        break;
                }
                if (isExistHeading) return true;
            }
            return false;
        });

        updateIsScrollableContainer();
        if (isNeedUpdate) updateAnchorList();
    };

    useEffect(() => {
        tabContentRef.current = anchorNavigationHolderRef.current.closest('.tab-content');
        scrollContainer.current = tabContentRef.current.querySelector('.default-components');
        window.addEventListener('resize', onWindowResize);

        if (scrollContainer.current) {
            scrollContainer.current.addEventListener('scroll', onContainerScroll);
            intersectionObserver.current = new IntersectionObserver(onIntersectionObserver, {
                root: scrollContainer.current,
                rootMargin: '0px',
                threshold: 1.0,
            });

            mutationObserver.current = new MutationObserver(onMutationObserver);
            mutationObserver.current.observe(scrollContainer.current, {
                attributes: true,
                childList: true,
                subtree: true,
                attributeFilter: ['class', 'style'],
                attributeOldValue: true,
            });
        }

        return () => {
            window.removeEventListener('resize', onWindowResize);
            if (scrollContainer.current) {
                scrollContainer.current.removeEventListener('scroll', onContainerScroll);
            }

            if (intersectionObserver.current) intersectionObserver.current.disconnect();
            if (mutationObserver.current) mutationObserver.current.disconnect();
        };
    }, []);

    useEffect(() => {
        if (!initialSetup.current && isTabActive) {
            updateAnchorList();
            initialSetup.current = true;
            processActiveSection();
        }
        if (initialSetup.current && !isTabActive) {
            clearAnimatedClass();
        }
    }, [isTabActive]);

    useEffect(() => {
        if (scrollContainer.current.scrollHeight > 0) {
            updateIsScrollableContainer(true);
        }
    }, [scrollContainer.current?.scrollHeight]);
    return (
        <Box
            ref={anchorNavigationHolderRef}
            component={'nav'}
            sx={styles.wrapper}
            className={`anchor-navigation-holder ${!(sectionListRef.current.length > 1) ? 'd-none' : ''}`}
        >
            <TransitionGroup component={'ul'} className="anchor-navigation-list">
                {sectionListRef.current.map((sectionItem: any) => (
                    <CSSTransition
                        key={sectionItem.label}
                        nodeRef={sectionItem.nodeRef}
                        timeout={500}
                        classNames="section-item"
                    >
                        <Box
                            ref={sectionItem.nodeRef}
                            component="li"
                            className={activeSectionRef.current === sectionItem.key ? 'active' : ''}
                            onClick={() => onSelectSection(sectionItem.key)}
                            sx={styles.item}
                        >
                            <Box component={'span'} sx={styles.label}>
                                {sectionItem.label}
                            </Box>
                        </Box>
                    </CSSTransition>
                ))}
            </TransitionGroup>
        </Box>
    );
}
