import { alpha, Box, Button, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import React, { useCallback } from 'react';
import Rule from './Rule';
import SeparatorItem from 'components/dataset-viewer/SeparatorItem';
import { ColumnData, GroupRule, LogicalOperator, Rule as RuleType } from 'components/dataset-viewer/index';
import { getDefaultCondition } from '../helpers';
import useBundleTranslation from 'i18n';
import { useBlendColors } from 'hooks/useDesign';

function Group({
    groupIndex,
    groupRule,
    onGroupDelete,
    fields,
    onUpdate,
    onChildUpdate,
    type,
    isRoot,
    applyButton,
    showGroupApply,
    compactView,
}: {
    groupIndex: number;
    groupRule: GroupRule;
    onGroupDelete?: (ruleIndex: number) => void;
    fields: ColumnData[];
    onUpdate?: (newRule: GroupRule, isNew?: boolean) => void;
    onChildUpdate?: (newRule: GroupRule, groupIndex: number) => void;
    type: 'current' | 'new';
    isRoot?: boolean;
    applyButton?: JSX.Element;
    showGroupApply?: boolean;
    compactView?: boolean;
}) {
    const { t } = useBundleTranslation(['components/dataset-viewer/dataset_viewer']);

    const updateGroupRule = (newGroupRule: GroupRule) => {
        if (onUpdate) {
            onUpdate(newGroupRule, type === 'new');
        }
        if (onChildUpdate) {
            onChildUpdate(newGroupRule, groupIndex);
        }
    };

    const onFilterLogicChange = (event: React.MouseEvent<HTMLElement>, value: LogicalOperator) => {
        if (!value) return false;
        const newGroupRule = { ...groupRule };

        newGroupRule.operator = value;
        updateGroupRule(newGroupRule);
    };

    const onRuleAdd = () => {
        const firstField = fields[0];
        const condition = getDefaultCondition(firstField.value_type);

        if (firstField) {
            const newRule: RuleType = {
                key: `${firstField.id.replace(' ', '_')}-${firstField.current ? 'current' : 'prior'}`,
                field: firstField.id,
                label: firstField.label,
                label_new: firstField.label,
                condition: condition,
                data: '',
                instance: firstField.current ? 'current' : 'prior',
                aggregation: '',
                field_second: 'a value',
                label_second: 'a value',
                key_second: 'a value',
                instance_second: '',
                aggregation_second: '',
                compare_date_shift: 'day',
            };

            const newGroupRule = { ...groupRule };

            newGroupRule.rules.push(newRule);
            updateGroupRule(newGroupRule);
        }
    };

    const onRuleDelete = useCallback(
        (ruleIndex: number) => {
            const newGroupRule = { ...groupRule };

            newGroupRule.rules.splice(ruleIndex, 1);
            updateGroupRule(newGroupRule);
        },
        [groupRule],
    );

    const onRuleUpdate = useCallback(
        (ruleIndex: number, data: RuleType) => {
            const newGroupRule = { ...groupRule };

            newGroupRule.rules[ruleIndex] = data;

            updateGroupRule(newGroupRule);
        },
        [groupRule],
    );

    const onGroupAdd = () => {
        const newGroup: RuleType = {
            group: {
                operator: 'AND',
                rules: [],
            },
            key: '',
            label: '',
            label_new: '',
            condition: '',
            data: '',
            instance: '',
            aggregation: '',
            field_second: '',
            label_second: '',
            key_second: '',
            instance_second: '',
            aggregation_second: '',
            compare_date_shift: '',
        };

        const newGroupRule = { ...groupRule };

        newGroupRule.rules.push(newGroup);
        updateGroupRule(newGroupRule);
    };

    const onChildGroupUpdate = (newRule: GroupRule, ruleIndex: number) => {
        const newGroupRule = { ...groupRule };

        newGroupRule.rules[ruleIndex].group = newRule;
        updateGroupRule(newGroupRule);
    };

    return (
        <Box
            key={groupIndex}
            sx={{
                border: '1px solid',
                borderColor: (theme) => useBlendColors(alpha(theme.palette.text.primary, isRoot ? 0.08 : 0.24)),
                borderRadius: '3px',
                backgroundColor: (theme) => useBlendColors(alpha(theme.palette.primary.main, 0.02)),
            }}
        >
            <Stack direction={'column'} alignItems="stretch" sx={{ padding: 1.5 }}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <ToggleButtonGroup
                        color="primary"
                        value={groupRule.operator}
                        exclusive
                        onChange={onFilterLogicChange}
                        className={'toggle-button--mod-light'}
                        sx={{ width: '160px' }}
                    >
                        <ToggleButton data-test={`and-button-${groupIndex}`} value="AND">
                            {t('group.and')}
                        </ToggleButton>
                        <ToggleButton data-test={`or-button-${groupIndex}`} value="OR">
                            {t('group.or')}
                        </ToggleButton>
                    </ToggleButtonGroup>
                    {compactView && groupRule.rules.filter((rule) => !rule.group).length == 0 && t('no_filters_yet')}
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Button
                            data-test={`add-rule-${groupIndex}`}
                            variant={'light'}
                            startIcon={<IconMi icon={'new'} />}
                            onClick={onRuleAdd}
                        >
                            {t('group.rule')}
                        </Button>
                        <Button
                            data-test={`add-group-${groupIndex}`}
                            variant={'light'}
                            startIcon={<IconMi icon={'new'} />}
                            onClick={onGroupAdd}
                        >
                            {t('group.group')}
                        </Button>
                        {isRoot && showGroupApply && applyButton}
                        {onGroupDelete && <SeparatorItem />}
                        {onGroupDelete && (
                            <Button
                                data-test={`remove-rule-${groupIndex}`}
                                variant="outlined"
                                color="neutral"
                                className={'min-width--icon'}
                                onClick={() => {
                                    onGroupDelete(groupIndex);
                                }}
                            >
                                <IconMi icon={'trash'} fontSize="16" />
                            </Button>
                        )}
                    </Stack>
                </Stack>
                {groupRule.rules.map((rule, index) => {
                    const firstRowInSection = index == 0 || groupRule.rules[index - 1].group;
                    const lastRowInSection = index == groupRule.rules.length - 1 || groupRule.rules[index + 1].group;

                    if (rule.group) {
                        return (
                            <Stack key={`${rule.key}_${index}_container`}>
                                <Box sx={{ pt: 2 }}>
                                    <Group
                                        compactView={compactView}
                                        groupIndex={index}
                                        key={rule.key}
                                        groupRule={rule.group}
                                        onGroupDelete={onRuleDelete}
                                        fields={fields}
                                        onChildUpdate={onChildGroupUpdate}
                                        type={type}
                                    />
                                </Box>
                            </Stack>
                        );
                    }

                    return (
                        <Stack
                            key={`${rule.key}_${index}_container`}
                            sx={{
                                border: (theme) =>
                                    `1px solid ${useBlendColors(alpha(theme.palette.text.primary, 0.08))}`,
                                borderTop: (theme) =>
                                    firstRowInSection
                                        ? `1px solid ${useBlendColors(alpha(theme.palette.text.primary, 0.08))}`
                                        : 0,
                                mt: firstRowInSection ? 2 : 0,
                                backgroundColor: 'background.default',
                                py: 1,
                                borderTopLeftRadius: firstRowInSection ? '3px' : 0,
                                borderTopRightRadius: firstRowInSection ? '3px' : 0,
                                borderBottomLeftRadius: lastRowInSection ? '3px' : 0,
                                borderBottomRightRadius: lastRowInSection ? '3px' : 0,
                            }}
                        >
                            <Rule
                                key={`${rule.key}_${index}`}
                                rule={rule}
                                fields={fields}
                                onRuleDelete={onRuleDelete}
                                onRuleUpdate={onRuleUpdate}
                                ruleIndex={index}
                                type={type}
                            />
                        </Stack>
                    );
                })}
            </Stack>
        </Box>
    );
}

export default Group;
