import TabsHeader from 'components/common/form/tab/TabsHeader';
import FormTopControlPanel from 'components/common/form/top-control-panel/FormTopControlPanel';
import SharedControls from 'components/common/form/shared-controls/SharedControls';
import React, { useEffect, useRef } from 'react';
import FormPageHeader from 'components/common/form/renderer/page/FormPageHeader';
import FormOnPageSaveButton from 'components/common/form/renderer/page/FormOnPageSaveButton';
import { IFormLayoutProps } from 'components/common/form/renderer/FormLayoutComposer';
import { FormLayoutContext } from 'components/common/form/layout/tab';
import FormTabsContent from 'components/common/form/layout/tab/FormTabsContent';
import PanelBackButton from 'components/common/form/panel-back-button/PanelBackButton';
import { Box } from '@mui/material';
import { getFormId } from 'components/common/form/formTools';

interface IFormPageLayoutProps extends Omit<IFormLayoutProps, 'confirmPopupProps' | 'subFormPopup'> {}

export default function FormPageLayout({
    elementProps,
    tabs,
    t,
    sharedComponents,
    panelSettings,
    hookForm,
    componentsMap,
    customTabs,
    isNew,
    uid,
}: IFormPageLayoutProps) {
    const tabsHeader = (
        <TabsHeader formActions={hookForm.form} tabs={tabs.formTabs} onChange={tabs.handleTabChange} t={t} />
    );
    const formTopControlPanel = <FormTopControlPanel panelSettings={panelSettings} formActions={hookForm.form} />;
    const panelBackButton = <PanelBackButton settings={panelSettings?.backButton} />;
    const id = getFormId(uid, isNew);
    const formRef = useRef<any>(null);
    const scrollContainerRef = useRef<any>(null);
    const scrollSizeRef = useRef<number>(0);

    useEffect(() => {
        if (isNew) {
            scrollContainerRef.current = formRef.current.querySelector('.default-components');
        }
    }, []);

    useEffect(() => {
        if (!isNew) return;
        //EXPERIMENTAL: scroll to new appear fields on New page
        const subscription = hookForm.form.hookFormWatch((data, { name, type }) => {
            if (type) {
                if (!scrollSizeRef.current) scrollSizeRef.current = scrollContainerRef.current.scrollHeight;

                setTimeout(() => {
                    if (scrollContainerRef.current.scrollHeight > scrollSizeRef.current) {
                        scrollContainerRef.current.scrollTo({
                            top: scrollContainerRef.current.scrollHeight,
                            behavior: 'smooth',
                        });
                    }
                    scrollSizeRef.current = scrollContainerRef.current.scrollHeight;
                }, 0);
            } else {
                setTimeout(() => {
                    scrollSizeRef.current = scrollContainerRef.current.scrollHeight;
                }, 0);
            }
        });

        return () => {
            if (isNew) subscription?.unsubscribe();
        };
    }, [hookForm.form.hookFormGetValues()]);

    return (
        <form
            autoComplete={'off'}
            id={id}
            onSubmit={hookForm.form.hookFormHandleSubmit(function (data) {
                return hookForm.form.formAct.act.submit.exec(data);
            })}
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            }}
            ref={formRef}
        >
            {!isNew &&
                (panelSettings?.hidden ? (
                    <span className="d-none">{formTopControlPanel}</span>
                ) : (
                    <FormPageHeader
                        tabsHeader={tabsHeader}
                        formTopControlPanel={formTopControlPanel}
                        panelBackButton={panelBackButton}
                    />
                ))}
            {/*ToDo: Refactor Add New element through Page, not popup*/}
            <Box
                sx={{
                    my: isNew ? 4 : undefined,
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    overflow: 'hidden',
                }}
            >
                {sharedComponents.length > 0 && (
                    <SharedControls elementProps={elementProps} components={sharedComponents}></SharedControls>
                )}
                <FormLayoutContext.Provider value={{ formLayout: 'page', formType: isNew ? 'new' : 'edit' }}>
                    <FormTabsContent
                        componentsMap={componentsMap}
                        formTabs={tabs.formTabs}
                        elementProps={elementProps}
                        customTabs={customTabs}
                    />
                </FormLayoutContext.Provider>
                {isNew && <FormOnPageSaveButton hookForm={hookForm} />}
            </Box>
        </form>
    );
}
