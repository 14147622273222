import {
    ChartPoint,
    ChartPointSeriesMetadata,
    ChartSeriesType,
    ChartSeriesTypeRaw,
    DATA_X_MULTIPLIER,
    getChartIcon,
    MetricChartStateLegend,
} from 'components/metric/chart/index';
import { Chart as HChart } from 'highcharts';
import { AssocArray } from 'tools/types';
import { strToSvg, svgElementFromString } from 'components/metric/chart/svgTools';
import { ElementRowData } from 'components/element-viewer';
import { Theme } from '@mui/material';

export interface ChartEventPointTypeRaw {
    event_start_time: number;
    event_start_time_formatted: string;
    event_end_time?: number;
    event_end_time_formatted?: string;
    label: { text: string; description: string };
    mydata?: any;
    nec?: any;
    color: string;
    display: 'mark' | 'line' | 'mark and line';
}

export interface ChartEventSeriesTypeRaw {
    id: string;
    data: { point: Array<ChartEventPointTypeRaw>; range: Array<ChartEventPointTypeRaw> };
}

export interface ChartPointEventSeriesMetadata extends ChartPointSeriesMetadata {
    index: number;
    label: { text: string; description: string };
    color: string;
    eventStartTimeFormatted: string;
    eventEndTimeFormatted: string;
    nec?: any;
    display: 'mark' | 'line' | 'mark and line';
}

export interface ChartPointEvent extends ChartPoint {
    marker: any;
    iconNumber: number;
    pointId: string;
    iconSvg: SVGSVGElement;
    rangeEnd: number;
    metadata: ChartPointEventSeriesMetadata;
}

export default function getEventSeries(
    series: Array<ChartSeriesTypeRaw>,
    eventSeries: Array<ChartEventSeriesTypeRaw>,
    legendState: AssocArray<MetricChartStateLegend>,
    chart: HChart,
    elementRow: ElementRowData,
    theme: Theme,
): Array<ChartSeriesType> {
    if (!chart?.series || chart.series.length == 0) {
        return [];
    }

    const data: Array<ChartPoint> = [];

    const buildEventPoint = (
        seriesPoint: ChartPoint,
        eventPointRaw: ChartEventPointTypeRaw,
        iconNumber: number,
    ): ChartPointEvent => {
        // TODO:
        const toPixels = chart.yAxis[0].toPixels(Number(seriesPoint.y), false) + (-20 - iconNumber * 38) * 0;
        const iconSvg = strToSvg(getChartIcon('eventChartIcon', theme));

        const metadata: ChartPointEventSeriesMetadata = {
            ...(eventPointRaw.mydata ?? {}),
            label: eventPointRaw.label,
            color: eventPointRaw.color,
            eventStartTimeFormatted: eventPointRaw.event_start_time_formatted,
            eventEndTimeFormatted: eventPointRaw?.event_end_time_formatted ?? '',
            nec: eventPointRaw.nec ?? {},
            display: eventPointRaw.display,
        };

        return {
            x: seriesPoint.x * DATA_X_MULTIPLIER,
            y: chart.yAxis[0].toValue(toPixels),

            marker: {
                enabled: false,
            },
            metadata: metadata,
            iconNumber: iconNumber,
            pointId: `event_${seriesPoint.x}_${iconNumber}_${eventPointRaw?.mydata?.index}`,
            iconSvg: iconSvg,
            rangeEnd: eventPointRaw.event_end_time ?? 0,
        } as ChartPointEvent;
    };

    eventSeries.forEach((event) => {
        const chartSeries = series.find((s) => s.id == event.id);
        if (!chartSeries) {
            return;
        }

        const chartSeriesData = chartSeries.data.filter((row) => {
            return !(
                (elementRow?.metricMaxValueToChart && row.y > Number(elementRow.metricMaxValueToChart)) ||
                (elementRow?.metricMinValueToChart && row.y < Number(elementRow.metricMinValueToChart))
            );
        });

        event.data.point.forEach((eventPoint) => {
            const point = chartSeriesData.find((seriesPoint) => seriesPoint.x == eventPoint.event_start_time);
            if (!point) {
                return;
            }
            data.push(buildEventPoint(point, eventPoint, 0));
        });

        event.data.range.forEach((eventPoint) => {
            const point = chartSeriesData.find((seriesPoint) => seriesPoint.x == eventPoint.event_start_time);
            if (!point) {
                return;
            }
            data.push(buildEventPoint(point, eventPoint, 0));
        });
    });

    const id = 'events';
    return [
        {
            seriesType: 'event',
            data: data,
            type: 'scatter',
            id: id,
            name: 'Events',
            dashStyle: 'Dot',
            color: 'red',
            zIndex: -1,
            lineWidth: 0,
            marker: {
                enabled: true,
                symbol: svgElementFromString(getChartIcon('eventChartIcon', theme)),
            },
            states: {
                hover: {
                    lineWidthPlus: 0,
                    enabled: false,
                },
            },
            visible: legendState ? (legendState[id]?.visible ?? true) : true,
        },
    ];
}
