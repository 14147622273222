import { alpha, Box, Button, Stack, Tooltip } from '@mui/material';
import IconMi from 'components/common/icon/IconMi';
import React from 'react';
import { EventCalendarType } from 'components/metric/MetricViewer';
import useBundleTranslation from 'i18n';
import CollaborationEvent from 'components/metric/collaboration-controls/CollaborationEvent';
import { MetricChartCompareLineType, MetricChartOverlayType } from 'components/metric/chart';
import OverlayMetrics from 'components/metric/collaboration-controls/overlay-metrics/OverlayMetrics';
import CollaborationAnnotation from 'components/metric/collaboration-controls/CollaborationAnnotation';
import useWallContext from 'components/wall/hooks/useWallContext';
import { useIsAdminOrHasPrivilege } from 'hooks/useUserPrivilege';

export default function MetricChartCollaborationControls({
    manualCalendars,
    elementId,
    segmentValueId,
    userChartOverlay,
    addCompareLines,
    removeCompareLines,
    updateCompareLine,
    addOverlays,
    removeOverlays,
    updateOverlay,
    onReset,
    refetch,
    expertCommentaryAllowEdit,
}: {
    manualCalendars: Array<EventCalendarType>;
    elementId: number;
    segmentValueId: number;
    userChartOverlay: number;
    addCompareLines: (compareLines: Array<MetricChartCompareLineType>) => void;
    removeCompareLines: (compareLines: Array<number>) => void;
    updateCompareLine: (id: number, color: string, dashStyle: string) => void;
    addOverlays: (overlays: Array<MetricChartOverlayType>) => void;
    removeOverlays: (overlays: Array<{ elementId: number; segmentValueId: number }>) => void;
    updateOverlay: (overlay: MetricChartOverlayType) => void;
    onReset: () => void;
    refetch: () => void;
    expertCommentaryAllowEdit: boolean;
}) {
    const { t } = useBundleTranslation('components/metric/chart');

    const { wallActions } = useWallContext(false);

    const canAddAnnotation = useIsAdminOrHasPrivilege('PRIV_ADD_ANNOTATION');

    return (
        <>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                    pt: 3,
                    position: 'relative',
                }}
            >
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <OverlayMetrics
                        elementId={elementId}
                        segmentValueId={segmentValueId}
                        userChartOverlay={userChartOverlay}
                        addCompareLines={addCompareLines}
                        removeCompareLines={removeCompareLines}
                        updateCompareLine={updateCompareLine}
                        addOverlays={addOverlays}
                        removeOverlays={removeOverlays}
                        updateOverlay={updateOverlay}
                    />
                    {manualCalendars.length > 0 && (
                        <CollaborationEvent
                            afterSave={() => refetch()}
                            eventId={0}
                            elementId={elementId}
                            segmentValueId={segmentValueId}
                        >
                            <Button variant="light" startIcon={<IconMi icon="add-event" />}>
                                {t('collaboration_add_event')}
                            </Button>
                        </CollaborationEvent>
                    )}
                    {canAddAnnotation && (
                        <CollaborationAnnotation
                            annotationId={0}
                            elementId={elementId}
                            segmentValueId={segmentValueId}
                            metricInstanceId={0}
                            afterSave={() => refetch()}
                        >
                            <Button variant="light" startIcon={<IconMi icon="add-annotation" />}>
                                {t('collaboration_add_annotation')}
                            </Button>
                        </CollaborationAnnotation>
                    )}
                </Stack>
                <Stack direction="row" alignItems="center" sx={{ position: 'relative' }}>
                    {expertCommentaryAllowEdit && (
                        <Button
                            sx={{
                                '&.MuiButton-root:not(:hover)': {
                                    borderColor: (theme) => alpha(theme.palette.primary.main, 0.4),
                                },
                            }}
                            data-test={'add_expert_analysis_button'}
                            variant="outlined"
                            startIcon={<IconMi icon="expert-analysis" />}
                            onClick={() => {
                                wallActions.setExpertAnalysisField(true);
                            }}
                        >
                            {t('add_expert_analysis')}
                        </Button>
                    )}
                </Stack>
                <Stack direction="row" alignItems="center" sx={{ position: 'relative' }}>
                    <Tooltip title={t('revert_changes')}>
                        <Button
                            sx={{
                                '&.MuiButton-root:not(:hover)': {
                                    borderColor: (theme) => alpha(theme.palette.primary.main, 0.4),
                                },
                            }}
                            variant="outlined"
                            onClick={onReset}
                            className={'min-width--icon'}
                        >
                            <IconMi icon="rotate-ccw" sx={{ fontSize: '16px' }} />
                        </Button>
                    </Tooltip>
                </Stack>
            </Stack>
        </>
    );
}
