import React, { useEffect, useState } from 'react';
import {
    Menu,
    MenuItem,
    Checkbox,
    ListItemText,
    Stack,
    Button,
    Grid,
    Box,
    Typography,
    alpha,
    Tooltip,
    ToggleButtonGroup,
    ToggleButton,
} from '@mui/material';
import useBundleTranslation from 'i18n';
import IconMi from 'components/common/icon/IconMi';
import { GridChangeViewConfig, GridFilterConfig, InfoButtonConfig, VisibleColumns } from './index';
import { openInNewTab } from 'tools/tools';
import styles from './TableFilters.styles';
import { gridAPI } from 'api/grid';
import SearchFilter from './filter/SearchFilter';
import { getFilterComponent } from './filter';
import ControlButtons from './control-buttons';
import StaticInfo from 'components/common/StaticInfo';
import { TFunction } from 'i18next';

export default function TableFilters({
    filtersList,
    searchField,
    setFiltersValue,
    removeFilterValue,
    filterValues,
    resetFilters,
    resetColumns,
    switchColumns,
    resetGridSettings,
    resetFiltersButton,
    gridTitle,
    gridDescription,
    gridTranslate,
    infoButton,
    gridName,
    initVisibleFilters,
    handleColumnSwitch,
    visibleColumns,
    fullwidthMode,
    widthToggle,
    onFullwidthChange,
    setPageIndex,
    isEmptyState,
    removeHeaderIndent,
    uid,
    isLoading,
    onChangeView,
    changeViewConfig,
    gridView,
}: {
    filtersList: Array<Array<GridFilterConfig>>;
    searchField: any;
    setFiltersValue: (filterName: string, value: any) => void;
    removeFilterValue: (filterName: string) => void;
    filterValues: any;
    resetColumns: boolean;
    switchColumns: boolean;
    resetFilters: () => void;
    resetGridSettings: () => void;
    resetFiltersButton: boolean;
    gridTitle?: string;
    gridDescription?: any;
    gridTranslate: TFunction;
    infoButton?: InfoButtonConfig;
    gridName: string;
    initVisibleFilters?: string[];
    visibleColumns: VisibleColumns;
    handleColumnSwitch: (columnName: string, visible: boolean) => void;
    fullwidthMode: string;
    widthToggle: boolean;
    onFullwidthChange: (value: string) => void;
    setPageIndex: (value: number) => void;
    isEmptyState: boolean;
    removeHeaderIndent?: boolean;
    uid?: string;
    isLoading: boolean;
    onChangeView: (value: string) => void;
    changeViewConfig: GridChangeViewConfig[] | null;
    gridView: string;
}) {
    function handleFilterChange(filterName: string, value: any) {
        setFiltersValue(filterName, value);
    }

    const { t } = useBundleTranslation(['components/common/grid/grid_filter']);
    const [showFilters, setShowFilters] = useState<string[]>([]);
    const [filtersChanged, setFiltersChanged] = useState(false);

    const handleChange = (blockIndex: number, itemIndex: number, filterItem: GridFilterConfig) => {
        const elementIndex = showFilters.indexOf(`${blockIndex}-${itemIndex}`);
        setFiltersChanged(true);

        if (elementIndex > -1) {
            const newVal = [...showFilters];
            newVal.splice(elementIndex, 1);
            setShowFilters(newVal);
            if (filterValues.hasOwnProperty(filterItem.name)) {
                removeFilterValue(filterItem.name);
            }

            return false;
        } else {
            setShowFilters([...showFilters, `${blockIndex}-${itemIndex}`]);
            return true;
        }
    };

    useEffect(() => {
        if (filtersList && filtersList.length > 0 && filtersChanged) {
            gridAPI.saveGridFilters({ gridName: gridName, filters: showFilters });
        }
    }, [showFilters]);

    useEffect(() => {
        if (filtersList && filtersList.length > 0) {
            if (initVisibleFilters) {
                setShowFilters(initVisibleFilters);
                return;
            }

            const visibleFilters: string[] = [];
            filtersList.map((filtersRow, blockIndex) => {
                filtersRow.map((component, itemIndex) => {
                    if (component.visible) {
                        visibleFilters.push(`${blockIndex}-${itemIndex}`);
                    }
                });
            });
            setShowFilters(visibleFilters);
        }
    }, [filtersList]);

    const [openFilterMenu, setOpenFilterMenu] = React.useState(false);
    const filterAnchorRef = React.useRef<HTMLButtonElement>(null);
    const handleClose = (event: Event | React.SyntheticEvent) => {
        if (filterAnchorRef.current && filterAnchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpenFilterMenu(false);
    };
    const handleToggle = () => {
        setOpenFilterMenu((prevOpen) => !prevOpen);
    };

    const showAddFilterMenu =
        filtersList?.filter((filtersRow) => {
            return (
                filtersRow.filter((component) => {
                    return !component.unhideable;
                }).length > 0
            );
        }).length > 0;

    return (
        <>
            <Stack
                sx={{ pb: 2, py: !removeHeaderIndent ? 2 : undefined }}
                direction="row"
                alignItems="flex-end"
                justifyContent="flex-end"
                spacing={1.5}
            >
                {gridTitle && (
                    <Grid key={`row-grid-title`} item container>
                        <Typography variant="heading" className={'heading-item'} width={'100%'}>
                            <Box component={'span'} className={'heading-item__label'}>
                                {gridTitle}
                            </Box>
                        </Typography>
                    </Grid>
                )}
                {filtersList?.length > 0 &&
                    filtersList.map((filtersRow, index) => {
                        {
                            return (
                                <Grid
                                    key={`row-${index}`}
                                    item
                                    container
                                    spacing={1}
                                    sx={{
                                        ...styles.filtersWrapper,
                                        flexShrink: gridTitle ? 0 : 1,
                                        ml: gridTitle ? '4px !important' : '-8px !important',
                                        flexWrap: showFilters.length < 3 || !showAddFilterMenu ? 'nowrap' : 'wrap',
                                    }}
                                >
                                    <>
                                        {filtersRow.map((component, comIndex) => {
                                            //ignore showFilters user settings if it is "component.unhideable"
                                            if (
                                                component.unhideable ||
                                                showFilters.indexOf(`${index}-${comIndex}`) > -1
                                            ) {
                                                return getFilterComponent({
                                                    ...component,
                                                    value: filterValues[component.name] ?? component.value ?? '',
                                                    onChange: handleFilterChange,
                                                    key: `row-${index}-filter-${component.name}-${comIndex}`,
                                                });
                                            }
                                        })}
                                        {filtersRow.length > 1 &&
                                            index + 1 === filtersList.length &&
                                            showAddFilterMenu && (
                                                <Grid item key={'plus-filter'}>
                                                    <Button
                                                        ref={filterAnchorRef}
                                                        onClick={handleToggle}
                                                        variant="light"
                                                        startIcon={
                                                            showFilters.length < 3 ? <IconMi icon="new" /> : undefined
                                                        }
                                                        data-test={`grid_${uid}_filters_add_button`}
                                                        sx={{
                                                            backgroundColor: (theme) =>
                                                                openFilterMenu
                                                                    ? `${alpha(
                                                                          theme.palette.primary.main,
                                                                          0.24,
                                                                      )} !important`
                                                                    : '',
                                                        }}
                                                        className={
                                                            showFilters.length > 2 ? 'min-width--icon' : undefined
                                                        }
                                                    >
                                                        {showFilters.length < 3 && (
                                                            <Box component="span">{t('filter')}</Box>
                                                        )}
                                                        {showFilters.length > 2 && <IconMi icon="new" fontSize="16" />}
                                                    </Button>
                                                    <Menu
                                                        open={openFilterMenu}
                                                        onClose={handleClose}
                                                        anchorEl={filterAnchorRef.current}
                                                        sx={{ '.MuiMenu-list': { py: 0 } }}
                                                    >
                                                        <Stack
                                                            direction={'row'}
                                                            sx={styles.filtersHeader}
                                                            justifyContent={'space-between'}
                                                            alignItems={'center'}
                                                        >
                                                            <Typography fontWeight={600}>{t('filter')}</Typography>
                                                            <Box
                                                                sx={{ cursor: 'pointer', paddingTop: '2px' }}
                                                                onClick={handleToggle}
                                                            >
                                                                <IconMi icon="times" fontSize="16" />
                                                            </Box>
                                                        </Stack>
                                                        {filtersList.map((filterBlock, filterBlockIndex) => {
                                                            return (
                                                                <Box key={`${filterBlockIndex}-filter-block`}>
                                                                    {filterBlock.map((filterItem, filterItemIndex) => {
                                                                        if (filterItem.unhideable) return null;
                                                                        return (
                                                                            <MenuItem
                                                                                sx={styles.item}
                                                                                key={filterItem.label}
                                                                                value={filterItem.label}
                                                                                onClick={(e) => {
                                                                                    handleChange(
                                                                                        filterBlockIndex,
                                                                                        filterItemIndex,
                                                                                        filterItem,
                                                                                    );
                                                                                }}
                                                                                data-test={`grid_${uid}_filters_menu_item_${filterItem.name}`}
                                                                            >
                                                                                <Checkbox
                                                                                    sx={styles.checkbox}
                                                                                    checked={
                                                                                        showFilters.indexOf(
                                                                                            `${filterBlockIndex}-${filterItemIndex}`,
                                                                                        ) > -1
                                                                                    }
                                                                                />
                                                                                <ListItemText
                                                                                    primary={t(filterItem.label)}
                                                                                />
                                                                            </MenuItem>
                                                                        );
                                                                    })}
                                                                </Box>
                                                            );
                                                        })}

                                                        {resetFiltersButton && (
                                                            <MenuItem
                                                                onClick={resetFilters}
                                                                sx={styles.resetFilters}
                                                                data-test={`grid_${uid}_filters_menu_reset_button`}
                                                            >
                                                                <ListItemText primary={t('clear_filters')} />
                                                            </MenuItem>
                                                        )}
                                                    </Menu>
                                                </Grid>
                                            )}
                                    </>
                                </Grid>
                            );
                        }
                    })}
                {!isEmptyState && gridTitle && filtersList?.length > 0 && <Box sx={{ ...styles.divider, ml: 0 }} />}
                <Stack direction="row" divider={<Box sx={styles.divider} />}>
                    {!isEmptyState && searchField?.name && (
                        <Box sx={styles.search}>
                            <SearchFilter
                                id={uid}
                                key={`search-field_${searchField.name}`}
                                value={filterValues?.[searchField.name] ?? ''}
                                onChange={handleFilterChange}
                                name={searchField.name}
                                label={searchField.label}
                                component={'search'}
                                setPageIndex={setPageIndex}
                            />
                        </Box>
                    )}
                    {infoButton && (
                        <>
                            <Button
                                sx={styles.infoButton}
                                variant={'outlined'}
                                startIcon={<IconMi icon={'info-circle'} />}
                                onClick={() => {
                                    openInNewTab(infoButton.url);
                                }}
                                data-test={`grid_${uid}_filters_info_button`}
                            >
                                {infoButton.label}
                            </Button>
                        </>
                    )}
                    {changeViewConfig && changeViewConfig.length > 0 && (
                        <ToggleButtonGroup
                            color="primary"
                            value={gridView}
                            exclusive
                            onChange={(_, value) => onChangeView(value)}
                        >
                            {changeViewConfig.map((option) => {
                                return (
                                    <ToggleButton value={option.name} sx={{ padding: '5px' }}>
                                        <Tooltip title={t(option.label)}>
                                            <Box sx={{ height: '16px', width: '16px' }}>
                                                <IconMi icon={option.icon} fontSize={'16'} />
                                            </Box>
                                        </Tooltip>
                                    </ToggleButton>
                                );
                            })}
                        </ToggleButtonGroup>
                    )}
                    {!isEmptyState &&
                        (gridView == null || gridView === 'default') &&
                        (resetColumns || switchColumns) && (
                            <ControlButtons
                                switchColumns={switchColumns}
                                resetColumns={resetColumns}
                                resetGridSettings={resetGridSettings}
                                uid={uid}
                                visibleColumns={visibleColumns}
                                handleColumnSwitch={handleColumnSwitch}
                                onFullwidthChange={onFullwidthChange}
                                fullwidthMode={fullwidthMode}
                                widthToggle={widthToggle}
                            />
                        )}
                </Stack>
            </Stack>

            {/*TODO: Pass props to static info*/}
            {!isLoading && gridDescription?.text && (
                <Box sx={{ mb: 2 }}>
                    <StaticInfo
                        label={gridTranslate(gridDescription?.text)}
                        align={gridDescription?.align ?? 'center'}
                    />
                </Box>
            )}
        </>
    );
}
