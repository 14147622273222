import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AlertColor } from '@mui/material/Alert';

import { formAPI } from 'api/form';
import StaticInfo from './StaticInfo';
import { IconType } from 'components/common/icon/IconHandler';

interface IProps {
    type?: AlertColor;
    refreshUrl: string;
    refreshInterval?: number;
    icon?: IconType | boolean;
    variant?: any;
    align?: string;
}

interface IntervalResponse {
    data: {
        data: string;
        status: 'OK' | 'ERROR';
    };
}

const ONE_MINUTE_IN_MILLISECONDS = 60 * 1000;

const IntervalStaticInfo: React.FC<IProps> = ({
    type,
    refreshInterval = ONE_MINUTE_IN_MILLISECONDS,
    refreshUrl,
    icon,
    variant,
    align,
}) => {
    const { data: response, refetch } = useQuery<IntervalResponse, Error>({
        queryKey: ['fetchIntervalStaticInfo'],
        queryFn: () => (refreshUrl ? formAPI.load(refreshUrl) : {}),
    });

    useEffect(() => {
        let id: NodeJS.Timeout | null = null;
        if (refreshUrl) {
            id = setInterval(() => refetch(), refreshInterval);
        }

        return () => {
            id && clearInterval(id);
        };
    }, [refreshUrl]);

    return response?.data?.data ? (
        <Box>
            <StaticInfo type={type} label={response.data.data ?? ''} icon={icon} variant={variant} align={align} />
        </Box>
    ) : null;
};

export default IntervalStaticInfo;
