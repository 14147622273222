import React, { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { reportAPI } from 'api/report';
import { logWarning } from 'tools/tools';
import { ReportContentNS } from 'components/report-content/index';
import AdditionalContentSettingsDataset = ReportContentNS.AdditionalContentSettingsDataset;
import DataRowType = ReportContentNS.DataRowType;
import useReportContentContext from 'components/report-content/hooks/useReportContentContext';
import ComponentUpdateProps = ReportContentNS.ComponentUpdateProps;
import ComponentSettingsPivot = ReportContentNS.ComponentSettingsPivot;
import PivotInitialDataResponse = ReportContentNS.PivotInitialDataResponse;
import PivotBody from 'components/report-content/components/pivot/parts/PivotBody';
import { applyAutoStretch } from 'components/report-content/components/dataset';
import PivotHeaderLine from 'components/report-content/components/pivot/parts/header/PivotHeaderLine';
import PivotDataCell = ReportContentNS.PivotDataCell;
import PivotDataCellStyle = ReportContentNS.PivotDataCellStyle;
import ComponentSettingsChartDataQueries = ReportContentNS.ComponentSettingsChartDataQueries;
import { PivotCellStyler } from 'components/report-content/components/pivot/PivotCellStyler';
import ComponentSettingsPivotUIField = ReportContentNS.ComponentSettingsPivotUIField;
import useComponentReady from 'components/report-content/hooks/useComponentReady';
import { Box } from '@mui/material';
import ReportContentLoadingPlaceholder from 'components/report-content/utils/ReportContentLoadingPlaceholder';
import useOnSVChange from 'components/report-content/hooks/useOnSVChange';
import useOnApplyAccessMapChange from 'components/report-content/hooks/useOnApplyAccessMapChange';
import useOnMeasurementTime from 'components/report-content/hooks/useOnMeasurementTime';

export const pivotDefaultColumnSize = 80;

export default function Pivot({
    contentSettings,
    component,
    updateBlockSettings,
    actions,
    blockId,
    editPanel,
}: ComponentUpdateProps<ComponentSettingsPivot>) {
    const { filtersString, extViz } = useReportContentContext(() => resetData(true));

    const getHeaderWidth = (currentHeaderRows: PivotInitialDataResponse) => {
        const list = currentHeaderRows.slice();
        if (list.length > 1) {
            const mainRow = list[list.length - 1];
            for (let i = 0; i < list.length - 1; i++) {
                const row = list[i];
                row.forEach((cell) => {
                    const newWidth = mainRow
                        .filter((mainCell) => {
                            return (
                                mainCell.shift == cell.shift ||
                                (mainCell.shift > cell.shift && mainCell.shift < cell.shift + (cell.colspan ?? 0))
                            );
                        })
                        .reduce((accumulator, cell) => {
                            const uiField = component.settings.uiFields.find(
                                (f) => String(f.column_sequence) == String(cell.shift),
                            );
                            let width = 0;
                            if (uiField?.columnSize) {
                                width = uiField.columnSize;
                            }
                            width = width < pivotDefaultColumnSize ? pivotDefaultColumnSize : width;
                            return accumulator + width;
                        }, 0);
                    cell.style.columnWidth = newWidth;
                });
            }
        }
        return list;
    };

    const handleChangeColumnSettings = (
        cell: PivotDataCell,
        newSettings: PivotDataCellStyle,
        unsetAutoStretch: boolean = false,
        realIndex: number,
    ) => {
        const index = component.settings.uiFields.findIndex((f) => f.reference_name == realIndex);

        if (index == -1) {
            logWarning('Field not found: ${cell.shift}');
            return;
        }

        if (cell.type == 'row') {
            let index = component.settings.rowFields.findIndex((f) => f.reference_name == cell.reference_name);
            if (index != -1) {
                component.settings.rowFields[index] = { ...component.settings.rowFields[index], ...newSettings };
                if (newSettings.textAlign) {
                    component.settings.rowFields[index].headerSettings.textAlign = newSettings.textAlign;
                }
            }
            if (newSettings.columnSize) {
                component.settings.uiFields[index].columnSize = newSettings.columnSize;
            }
            if (newSettings.textAlign) {
                component.settings.uiFields[index].textAlign = newSettings.textAlign;
            }
        } else if (cell.type == 'total') {
            component.settings.rowTotalsSettings = { ...component.settings.rowTotalsSettings, ...newSettings };
            component.settings.uiFields[index] = { ...component.settings.uiFields[index], ...newSettings };
            if (newSettings.columnSize) {
                component.settings.uiFields[index].columnSize = newSettings.columnSize;
            }
        } else {
            component.settings.uiFields[index] = { ...component.settings.uiFields[index], ...newSettings };
        }
        setHeaderRows(getHeaderWidth(headerRows));

        updateBlockSettings({
            ...component.settings,
            autoStretch: unsetAutoStretch ? false : component.settings.autoStretch,
        });
    };

    useEffect(() => {
        if (component.settings.autoStretch) {
            setHeaderRows(getHeaderWidth(headerRows));
        }
    }, [component.settings.autoStretch]);

    const queryClient = useQueryClient();
    const initialQueryKey = `report_block_initial_${component.datasetRendererBlockComponentId}`;
    const removeInitialData = () => queryClient.removeQueries({ queryKey: [initialQueryKey] });
    const { data: initialData } = useQuery<PivotInitialDataResponse, Error>({
        queryKey: [initialQueryKey],
        queryFn: () => {
            const datasetSettings: AdditionalContentSettingsDataset = {
                ...contentSettings,
                getAmount: 'Y',
                getTotals: 'Y',
                page: 0,
            };
            setDataQueries(component.settings.dataQueries);
            return reportAPI.getComponentData(component.settings, datasetSettings, filtersString);
        },
    });

    const [pivotCellStyler] = useState(new PivotCellStyler(component));
    // Unmount Block
    useEffect(() => {
        return () => {
            removeInitialData();
        };
    }, []);

    // Contains dataset data divided by pages
    const [loadedPages, setLoadedPages] = useState<Array<Array<DataRowType>>>([]);
    // List of already loaded pages
    const [requiredPages, setRequiredPages] = useState<Array<number>>([]);

    const componentReady = useComponentReady(initialData);
    const resetData = (resetInitialData: boolean) => {
        if (!componentReady) {
            return;
        }
        setRowsList([]);
        setLoadedPages([]);
        setRequiredPages([]);
        setHeaderRows([]);
        setTotalRows(-1);
        if (resetInitialData) {
            removeInitialData();
        }
    };

    // Reset all data on segment value change
    useOnSVChange(contentSettings.segmentValueId, resetData);
    // Reset all data on Restrict Data with User Map change
    useOnApplyAccessMapChange(contentSettings.applyAccessMap, resetData);
    // Full reset on Measurement Interval Change
    useOnMeasurementTime(contentSettings.measurement_time, resetData);
    // Reset all data on Component Filter Change | Totals settings change
    useEffect(() => {
        resetData(true);
    }, [
        component.settings.groupFilter,
        component.settings.show_column_totals_as_percent,
        component.settings.show_row_totals_as_percent,
    ]);
    // Reset all data on Fields Change
    useEffect(() => {
        resetData(true);
    }, [JSON.stringify(component.settings.fields)]);
    // Last request to server was made with this data
    const [lastDataQueries, setDataQueries] = useState<ComponentSettingsChartDataQueries | null>(null);
    // Reset all data on dataQueries change
    const [totalsState, setTotalsState] = useState(
        component.settings.show_column_totals + component.settings.show_row_totals,
    );
    useEffect(() => {
        const state = component.settings.show_column_totals + component.settings.show_row_totals;
        if (
            (lastDataQueries != null &&
                JSON.stringify(lastDataQueries) != JSON.stringify(component.settings.dataQueries)) ||
            state != totalsState
        ) {
            setTotalsState(state);
            resetData(true);
        }
    }, [component.settings]);

    useEffect(() => {
        if (requiredPages.length == 0 && initialData) {
            handlePageChange(0);
        }
    }, [requiredPages, initialData]);

    const [countOfLoadingPages, setCountOfLoadingPages] = useState<number>(0);

    const handlePageChange = (page: number) => {
        if (requiredPages.includes(page)) {
            return;
        }

        setCountOfLoadingPages((current) => current + 1);
        const list = requiredPages.slice();
        requiredPages.push(page);
        list.push(page);
        setRequiredPages(list);

        const datasetSettings: AdditionalContentSettingsDataset = {
            ...contentSettings,
            getAmount: 'N',
            getTotals: 'N',
            page: page,
        };

        return reportAPI.getComponentData(component.settings, datasetSettings, filtersString).then((response) => {
            const list = loadedPages.slice();
            list[page] = response;
            setLoadedPages(list);
            setCountOfLoadingPages((current) => current - 1);
        });
    };

    const [totalRows, setTotalRows] = useState<number>(-1);
    const [rowsList, setRowsList] = useState<PivotInitialDataResponse>([]);
    const [headerRows, setHeaderRows] = useState<PivotInitialDataResponse>([]);

    const [scrollLeft, setScrollLeft] = useState<number>(0);

    const handleScrollLeft = (newPosition: number) => {
        setScrollLeft(newPosition);
    };

    useEffect(() => {
        if (!initialData) {
            return;
        }

        if (initialData.length) {
            const headerRowsAmount = component.settings.columnFields.length;
            setTotalRows(initialData.length - headerRowsAmount);
            const list = initialData.splice(headerRowsAmount);

            const uiFieldsToAdd: Array<ComponentSettingsPivotUIField> = [];
            if (initialData.length) {
                initialData[initialData.length - 1].forEach((cell) => {
                    const index = component.settings.uiFields.findIndex((f) => f.reference_name == cell.shift);
                    if (index == -1) {
                        const def = {
                            columnSize: pivotDefaultColumnSize,
                            column_sequence: cell.shift,
                            field_type: 'ui',
                            reference_name: cell.shift,
                        };
                        if (cell.shift == 0) {
                            //@ts-ignore
                            def.textAlign = component.settings.rowFields?.[0]?.textAlign ?? 'left';
                        }
                        uiFieldsToAdd.push(def);
                    }
                });
            }

            let newSettings = { ...component.settings };
            if (uiFieldsToAdd.length) {
                newSettings.uiFields = newSettings.uiFields.concat(uiFieldsToAdd);
                updateBlockSettings(newSettings);
            }

            setHeaderRows(getHeaderWidth(initialData));
            if (newSettings.autoStretch) {
                setTimeout(() => {
                    const s = applyAutoStretch(newSettings, component.datasetRendererBlockComponentId);
                    if (JSON.stringify(newSettings) != JSON.stringify(s)) {
                        updateBlockSettings(newSettings);
                    }
                }, 0);
            }

            // setTimeout(() => {
            // setHeaderRows(getHeaderWidth(initialData));
            setRowsList(list);

            // }, 100);
        }
    }, [initialData]);

    useEffect(() => {
        // Hide block from viewer if component has no data
        if (contentSettings.context != 'view') {
            return;
        }
        updateBlockSettings({
            ...component.settings,
            hasNoData: totalRows == 0,
        });
    }, [totalRows]);

    // const handleRealWidthChange = (width: number) => {
    //     if (component.settings?.currentInnerWidth == width || width == 0) {
    //         return;
    //     }
    //     let newSettings: ComponentSettingsPivot = { ...component.settings, currentInnerWidth: width };
    //     if (component.settings.autoStretch) {
    //         newSettings = applyAutoStretch(newSettings);
    //     }
    //     updateBlockSettings(newSettings);
    // };

    const handleRealWidthChange = (width: number) => {
        if (component.settings?.currentInnerWidth == width || width == 0) {
            return;
        }
        let newSettings: ComponentSettingsPivot = { ...component.settings, currentInnerWidth: width };
        if (component.settings.autoStretch) {
            newSettings = applyAutoStretch(newSettings, component.datasetRendererBlockComponentId);
        }
        updateBlockSettings(newSettings);
    };

    return (
        <Box
            style={{ overflow: 'hidden' }}
            className={'pivot-holder'}
            sx={{ position: 'relative', minHeight: '100px' }}
        >
            {headerRows && totalRows > -1 ? (
                <>
                    <PivotHeaderLine
                        onChangeColumnSettings={handleChangeColumnSettings}
                        component={component}
                        contentSettings={contentSettings}
                        cellStyler={pivotCellStyler}
                        scrollLeft={scrollLeft}
                        actions={actions}
                        rows={headerRows}
                        blockId={blockId}
                        editPanel={editPanel}
                    />
                    <PivotBody
                        totalRows={totalRows}
                        component={component}
                        contentSettings={contentSettings}
                        rows={rowsList}
                        cellStyler={pivotCellStyler}
                        onPageChange={(page: number) => handlePageChange(page)}
                        onHorizontalScroll={handleScrollLeft}
                        updateRealWidth={handleRealWidthChange}
                        actions={actions}
                    />
                </>
            ) : (
                <ReportContentLoadingPlaceholder />
            )}
        </Box>
    );
}
