import { decompressFromUTF16, compressToUTF16 } from 'lz-string';

import { FormRendererAPIType } from 'components/common/form';
import { FormControlUploadFileProps } from 'components/common/upload-control/UploadFile';
import { FormComponentsBuilderMap } from 'components/common/form/buildFormControlProperties';
import React from 'react';
import { LogClickData, searchAPI, SearchRow } from 'api/search';
import { HomePageCachedData } from 'app/home/index.functions';
import { escape } from 'lodash';
import { RawTranslatedText, TranslatedText } from 'tools/types';

export const THUMBNAIL_WIDTH = 298;
export const THUMBNAIL_HEIGHT = 144;

export const PREVIEW_WIDTH = 1200;
export const PREVIEW_HEIGHT = 600;

export const openInNewTab = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
};

export const processUrl = (url: string = '', params: any) => {
    if ('object' === typeof params)
        for (let [key, value] of Object.entries(params)) {
            url = url.split(':' + key).join(value as string);
        }

    return url;
};

export const addVersionToUrl = (url: string) => {
    return (
        url +
        (url.includes('?') ? '&' : '?') +
        'ver=' +
        (undefined !== process.env.REACT_APP_BUILD_VERSION && '' !== process.env.REACT_APP_BUILD_VERSION
            ? process.env.REACT_APP_BUILD_VERSION
            : Math.floor(new Date().getTime() / 100000))
    );
};

export const ConditionalWrapper = ({
    condition,
    wrapper,
    children,
}: {
    condition: any;
    wrapper: any;
    children: any;
}) => (condition ? wrapper(children) : children);

export function logWarning(msg: string) {
    if (localStorage.getItem('debug') == 'Y') {
        console.log(`%cWarning: ${msg}`, 'color:red');
    }
}

export function prepareFormData(data: any, formActions?: FormRendererAPIType): FormData {
    const formData = new FormData();

    for (let key in data) {
        if (typeof data[key] == 'undefined' || data[key] == null) {
            continue;
        }

        if (typeof formActions != 'undefined') {
            const field = formActions.getElementValuesRegister(key);

            if (field?.elementProps.component?.props?.formIgnoreSubmit == true) {
                continue;
            }

            //ToDo review prepareFormData logic to get data from complex components
            if (field) {
                const componentKey = field.elementProps.component.component;
                const componentProps = field.elementProps.component.props;

                const className = FormComponentsBuilderMap[componentKey];
                if (className != undefined) {
                    const builder = new className(field.elementProps, componentProps);
                    builder.processFormData(formData, key, data[key]);
                    continue;
                }

                if (componentKey === 'GlobalVariables' || componentKey === 'VariablesGrid') {
                    formData.append(key, JSON.stringify(data[key]));
                    continue;
                }

                if (componentKey === 'GridComponent') {
                    continue;
                }

                if (componentKey == 'UploadFile' || componentKey == 'UploadImage' || componentKey == 'ChooseIcon') {
                    // If multiple = true append files to formData request as array
                    const uploadKey = key + ((componentProps as FormControlUploadFileProps).multiple ? '[]' : '');
                    const files = Object.values(data[key]).length ? Object.values(data[key]) : [data[key]];
                    files.forEach((file: any) => {
                        formData.append(uploadKey, file);
                    });
                    continue;
                }
            }
        }

        formData.append(key, data[key]);
    }
    return formData;
}

export function settingsUrlToTranslationNS(settingsUrl: string) {
    let preprocessTranslationPath = function (path: string) {
        let tmp = path.replace(/\/form$/, '').replace(/\/index$/, '');

        return 'app/' + tmp.split('/').slice(0, 3).join('/');
    };

    let url = settingsUrl.replace('/data/', '');

    let tmp = url.split('?');
    if (tmp.length > 1) url = tmp[0];

    url = url.replace(/\/([0-9_]+)\//g, '/');

    tmp = url.split('/:');
    if (tmp.length > 1) return preprocessTranslationPath(tmp[0]);

    tmp = url.split('/{');
    return preprocessTranslationPath(tmp[0]);
}

// Deep Object Merge
function isObject(item: any): boolean {
    return item && typeof item == 'object' && !Array.isArray(item);
}

export function mergeObjectDeep(target: any, ...sources: any): any {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, { [key]: {} });
                mergeObjectDeep(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }

    return mergeObjectDeep(target, ...sources);
}

export const getEditUrl = (type: string) => {
    switch (type) {
        case 'dataset':
            return '/editor/dataset/';
        case 'source':
            return '/editor/data-source/';
        case 'plugin':
            return '/editor/plugin-source/';
        case 'legacy-report':
        case 'report':
        case 'internal report':
            return '/editor/element/report/';
        case 'metric':
        case 'external-metric':
        case 'manual-metric':
            return '/editor/element/metric/';
        case 'burst':
        case 'distribution':
            return '/notification/burst/';
        case 'folder':
            return '/notification/folder/';
        case 'external-report':
        case 'external report':
            return '/editor/element/extreport/';
        case 'external-content':
        case 'other external content':
            return '/editor/element/ext-content/';
        case 'multi-metric':
            return '/editor/element/multimetric/';
        case 'dataset-view':
            return '/dataset/';
    }

    return '';
};

export const getViewUrl = (type: string) => {
    switch (type) {
        case 'legacy-report':
        case 'report':
        case 'internal report':
            return '/report/';
        case 'dataset':
        case 'dataset-view':
            return '/dataset/';
        case 'metric':
        case 'external-metric':
        case 'manual-metric':
        case 'multi-metric':
            return '/chart/';
        case 'external-report':
        case 'external report':
            return '/extreport/';
        case 'external-content':
        case 'other external content':
            return '/ext-content/';
        case 'external-dataset':
            return '/editor/plugin-source/metadata/reference/';
        case 'external-metadata':
        case 'external-metadata-view':
            return '/editor/plugin-source/metadata/source-dataset/';
        case 'external-source':
            return '/editor/plugin-source/metadata/external-source/';
    }

    return '';
};

export const uid = () => {
    const s4 = () => window.crypto.getRandomValues(new Uint32Array(1))[0].toString(32).substring(1);
    return s4() + s4() + s4() + s4();
};

export function removeFromStateArray<T>(
    setArray: React.Dispatch<React.SetStateAction<Array<T>>>,
    findIndex: ((element: T) => boolean) | number,
) {
    setArray((prevState) => {
        const list = prevState.slice();
        const index = typeof findIndex == 'number' ? findIndex : prevState.findIndex(findIndex);
        if (index != -1) {
            list.splice(index, 1);
        }
        return list;
    });
}

export function replaceInStateArray<T>(
    setArray: React.Dispatch<React.SetStateAction<Array<T>>>,
    findIndex: ((element: T) => boolean) | number,
    element: T,
) {
    setArray((prevState) => {
        const list = prevState.slice();
        const index = typeof findIndex == 'number' ? findIndex : prevState.findIndex(findIndex);
        if (index != -1) {
            list[index] = element;
        }
        return list;
    });
}

export function addToStateArray<T>(setArray: React.Dispatch<React.SetStateAction<Array<T>>>, element: T) {
    setArray((prevState) => {
        const list = prevState.slice();
        list.push(element);
        return list;
    });
}

export function addOrReplaceInStateArray<T>(
    setArray: React.Dispatch<React.SetStateAction<Array<T>>>,
    findIndex: (element: T) => boolean,
    element: T,
) {
    setArray((prevState) => {
        const list = prevState.slice();
        const index = prevState.findIndex(findIndex);
        if (index != -1) {
            list[index] = element;
        } else {
            list.push(element);
        }
        return list;
    });
}

export function formatPercent(value: string | number) {
    value = parseFloat(String(value));

    if (!isNaN(value)) {
        const absValue = Math.abs(value);
        if (1 < absValue && absValue < 10) value = value.toFixed(1);
        else if (absValue < 1) value = value.toFixed(2);
        else value = value.toFixed(0);

        return value + '%';
    }

    return '';
}
export function formatElementDashboardName(value: string): string {
    let a = JSON.parse(String(value));

    if (Array.isArray(a)) {
        let template: string = escape(a[0]);

        if (5 == a.length) {
            return template
                .replace(
                    '[e]',
                    ('b' == a[1] ? '<b>' : 'i' == a[1] ? '<i>' : '') +
                        escape(a[2]) +
                        ('b' == a[1] ? '</b>' : 'i' == a[1] ? '</i>' : ''),
                )
                .replace(
                    '[sv]',
                    ('b' == a[3] ? '<b>' : 'i' == a[3] ? '<i>' : '') +
                        escape(a[4]) +
                        ('b' == a[3] ? '</b>' : 'i' == a[3] ? '</i>' : ''),
                );
        } else if (7 == a.length) {
            return template
                .replace(
                    '[e]',
                    ('b' == a[1] ? '<b>' : 'i' == a[1] ? '<i>' : '') +
                        escape(a[2]) +
                        ('b' == a[1] ? '</b>' : 'i' == a[1] ? '</i>' : ''),
                )
                .replace(
                    '[psv]',
                    ('b' == a[3] ? '<b>' : 'i' == a[3] ? '<i>' : '') +
                        escape(a[4]) +
                        ('b' == a[3] ? '</b>' : 'i' == a[3] ? '</i>' : ''),
                )
                .replace(
                    '[ssv]',
                    ('b' == a[5] ? '<b>' : 'i' == a[5] ? '<i>' : '') +
                        escape(a[6]) +
                        ('b' == a[5] ? '</b>' : 'i' == a[5] ? '</i>' : ''),
                );
        } else if (a.length > 0) return escape(a[1]);
    }

    return escape(value);
}

export function simulateMouseClick(element: Element | null) {
    if (element) {
        ['mousedown', 'click', 'mouseup'].forEach(function (mouseEventType) {
            element.dispatchEvent(
                new MouseEvent(mouseEventType, {
                    view: window,
                    bubbles: true,
                    cancelable: true,
                    buttons: 1,
                }),
            );
        });
    }
}

export const clearLocalStorage = () => {
    if ('localStorage' in window && window['localStorage'] !== null) localStorage.clear();
    if ('sessionStorage' in window && window['sessionStorage'] !== null) sessionStorage.clear();
};

export function logClick(result: SearchRow, searchValue: string, searchLogId: number) {
    const logClickData: LogClickData = {
        type: result.type,
        item_id: result.id,
        second_id: result.second_id,
        icon_url: result.info && result.info.icon_url ? result.info.icon_url : '',
        external_link:
            result.info && result.info.external_link
                ? result.info.external_link
                : result.external_link && result.external_link > ''
                  ? result.external_link
                  : '',
        search_log_id: searchLogId,
        search_text: searchValue,
    };

    return searchAPI.logClick(logClickData).finally(() => {});
}

export const debounceFunc = (func: any, wait = 200) => {
    let timeout: any;
    return function executedFunction(...args: any[]) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
};

export const checkLocalStorageAvailable = function () {
    try {
        return 'localStorage' in window && window['localStorage'] !== null;
    } catch (e) {
        return false;
    }
};
export const checkSessionStorageAvailable = function () {
    try {
        return 'sessionStorage' in window && window['sessionStorage'] !== null;
    } catch (e) {
        return false;
    }
};

export const getCachedData = function (projectVersion: string, itemId: string): any | null {
    let item: HomePageCachedData | null = null;
    let isCompress: string | null = 'N';

    if (checkLocalStorageAvailable())
        try {
            if (projectVersion != localStorage.getItem('dashboard_project_version')) {
                localStorage.clear();
            } else {
                isCompress = localStorage.getItem(itemId + '_is_compress');

                let data = localStorage.getItem(itemId);
                if (null !== data) {
                    if ('Y' == isCompress) {
                        let str = decompressFromUTF16(data);
                        item = JSON.parse(str);
                    } else item = JSON.parse(data);
                }
            }
        } catch (e) {
            item = null;
        }

    if (!item && checkSessionStorageAvailable())
        try {
            if (projectVersion != sessionStorage.getItem('dashboard_project_version')) {
                sessionStorage.clear();
                item = null;
            } else {
                isCompress = sessionStorage.getItem(itemId + '_is_compress');

                let data = sessionStorage.getItem(itemId);
                if (null !== data) {
                    if ('Y' == isCompress) item = JSON.parse(decompressFromUTF16(data));
                    else item = JSON.parse(data);
                }
            }
        } catch (e) {
            item = null;
        }

    return item;
};

export const setCachedData = function (str: string, isCompress: string, projectVersion: string, itemId: string): void {
    let isCached = false;

    if (checkLocalStorageAvailable()) {
        try {
            localStorage.setItem(itemId, 'Y' === isCompress ? compressToUTF16(str) : str);
            localStorage.setItem(itemId + '_is_compress', isCompress);
            localStorage.setItem('dashboard_project_version', projectVersion);
            isCached = true;
        } catch (e: any) {
            if (
                ('undefined' != typeof DOMException.QUOTA_EXCEEDED_ERR && e == DOMException.QUOTA_EXCEEDED_ERR) ||
                e.name == 'QuotaExceededError' ||
                e.name == 'NS_ERROR_DOM_QUOTA_REACHED'
            )
                localStorage.clear();
        }
    }

    if (!isCached && checkSessionStorageAvailable()) {
        try {
            sessionStorage.setItem(itemId, 'Y' === isCompress ? compressToUTF16(str) : str);
            sessionStorage.setItem(itemId + '_is_compress', isCompress);
            sessionStorage.setItem('dashboard_project_version', projectVersion);
        } catch (e: any) {
            if (
                ('undefined' != typeof DOMException.QUOTA_EXCEEDED_ERR && e == DOMException.QUOTA_EXCEEDED_ERR) ||
                e.name == 'QuotaExceededError' ||
                e.name == 'NS_ERROR_DOM_QUOTA_REACHED'
            )
                sessionStorage.clear();
        }
    }
};

export const removeHtmlTags = (str: string) => {
    if (!str) return '';
    return str.split(/<[^>]*>/).join('');
};

export const getSlugByUrl = (url: string) => {
    const tempSettingsUrl = url[url.length - 1] === '/' ? url.substring(0, url.length - 1) : url;

    return tempSettingsUrl
        .replace(/\/data\//, '')
        .replace(/data\//, '')
        .replace(/\//g, '_')
        .replace(/\d+/g, '*');
};

//use prepareTranslatedText() to RawTranslatedText prop value to get the standard object
export const prepareTranslatedText = (textSettings?: RawTranslatedText) => {
    let translatedTextObject: TranslatedText = {
        text: '',
        translationNS: undefined,
        needTranslation: undefined,
    };
    if ('string' === typeof textSettings) {
        translatedTextObject.text = textSettings;
    } else {
        translatedTextObject = { ...translatedTextObject, ...(textSettings ?? {}) };
    }
    return translatedTextObject;
};
