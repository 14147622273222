import React, { useMemo, useRef, useState } from 'react';
import { AutoSizer, CellMeasurerCache, InfiniteLoader, List, ListRowProps } from 'react-virtualized';
import { ReportContentNS } from 'components/report-content/index';
import ComponentProps = ReportContentNS.ComponentProps;
import ComponentSettingsPivot = ReportContentNS.ComponentSettingsPivot;
import PivotLine from 'components/report-content/components/pivot/parts/PivotLine';
import PivotInitialDataResponse = ReportContentNS.PivotInitialDataResponse;
import { PivotCellStyler } from 'components/report-content/components/pivot/PivotCellStyler';
import { getFontSizeStyle } from 'components/report-content/utils/tools';
import { Box } from '@mui/material';

const DefaultHeight = 11;

export default function PivotBody({
    totalRows,
    contentSettings,
    component,
    rows,
    cellStyler,
    onPageChange,
    onHorizontalScroll,
    updateRealWidth,
    actions,
}: ComponentProps<ComponentSettingsPivot> & {
    totalRows: number;
    rows: PivotInitialDataResponse;
    cellStyler: PivotCellStyler;
    onPageChange: (page: number) => Promise<any> | void;
    onHorizontalScroll: (newPosition: number) => void;
    updateRealWidth: (width: number) => void;
}) {
    // const addOneRowForTotals = component.settings.show_totals == 'Y' ? 1 : 0;
    const wrapperRef = useRef<HTMLDivElement | null>(null);

    const firstRow = useMemo(() => rows?.[0] ?? [], [rows]);
    const fullRowHeight = DefaultHeight + Number(component.settings.mainFontSize ?? 12);
    const listRef = useRef(null);
    const cache = useMemo(() => {
        return new CellMeasurerCache({
            fixedWidth: true,
            defaultHeight: fullRowHeight,
        });
    }, []);

    const [maxColumns, setMaxColumns] = useState(0);
    useMemo(() => {
        let max = 0;
        rows.forEach((row, index) => {
            max = maxColumns < row.length ? row.length : max;
        });
        setMaxColumns(max);
    }, [rows]);

    const rowRenderer = ({ key, style, index }: ListRowProps) => {
        const isEven = component.settings.zebra_striping == 'Y' && index % 2 == 0;
        // const isTotalLine = Boolean(addOneRowForTotals && totalRows == index);

        // Trigger clientWidth change for Pivot Inner Block
        // Used for Columns autoStretch
        setTimeout(() => {
            const width = (wrapperRef.current?.firstChild as HTMLElement)?.clientWidth ?? 0;
            if (width != 0) {
                updateRealWidth(width);
            }
            let maxHeight = fullRowHeight;
            const cellList =
                wrapperRef.current?.querySelector(`[data-rowkey="${key}"]`)?.getElementsByClassName('wrapper-cell') ??
                [];
            for (let i = 0; i < cellList.length; i++) {
                if (cellList[i].clientHeight > maxHeight) {
                    maxHeight = cellList[i].clientHeight;
                }
            }

            if (cache.rowHeight({ index: index }) != maxHeight) {
                cache.set(index, 0, 0, maxHeight);
                // @ts-ignore
                listRef.current.recomputeRowHeights(index);
            }
        }, 0);
        delete style.width;

        return (
            <div
                data-rowkey={key}
                key={key}
                style={style}
                className={'block-wrapper-body-line dataset-renderer-data-line' + (isEven ? ' even' : '')}
            >
                <PivotLine
                    firstRow={firstRow}
                    maxColumns={maxColumns}
                    component={component}
                    contentSettings={contentSettings}
                    row={rows[index]}
                    cellStyler={cellStyler}
                    isEven={isEven}
                    actions={actions}
                />
            </div>
        );
    };

    const isRowLoaded = ({ index }: { index: number }) => !!rows[index];
    const loadMoreRows = ({ startIndex }: { startIndex: number }) => {
        // TODO: not work in proper way
        const pageToLoad = Math.floor(startIndex / contentSettings.pageSize);
        return onPageChange(pageToLoad);
    };

    const listElementId = 'PivotListComponent_' + component.datasetRendererBlockComponentId;

    const handleScroll = () => {
        const el = document.getElementById(listElementId);
        if (!el) {
            return;
        }
        onHorizontalScroll(el.scrollLeft);
    };

    const fontStyle = getFontSizeStyle(component.settings);
    return (
        <Box sx={{ fontStyle }} style={{ overflowX: 'auto', maxHeight: component.settings.height + 'px' }}>
            <InfiniteLoader
                isRowLoaded={isRowLoaded}
                /*@ts-ignore*/
                loadMoreRows={loadMoreRows}
                rowCount={totalRows}
            >
                {({ onRowsRendered }) => {
                    return (
                        // @ts-ignore
                        <AutoSizer disableHeight defaultWidth={1600}>
                            {({ width }) => {
                                const el = document.getElementById(listElementId);
                                if (el && el.getAttribute('scroll-event-attached') != 'true') {
                                    el.setAttribute('scroll-event-attached', 'true');
                                    el.addEventListener('scroll', handleScroll);
                                }
                                return (
                                    <div ref={wrapperRef} className={'report-data-body report-data-body--pivot'}>
                                        {/*@ts-ignore*/}
                                        <List
                                            className={'react-virtualized--fix-scroll'}
                                            id={listElementId}
                                            // className={styles.List}
                                            height={Number(component.settings.height)}
                                            autoHeight={totalRows < 80}
                                            overscanRowCount={50}
                                            onRowsRendered={onRowsRendered}
                                            ref={listRef}
                                            // ref={registerChild}
                                            rowCount={totalRows}
                                            // rowHeight={useDynamicRowHeight ? this._getRowHeight : listRowHeight}
                                            rowHeight={(index) => cache.rowHeight(index)}
                                            rowRenderer={rowRenderer}
                                            // scrollToIndex={scrollToIndex}
                                            width={width}
                                        />
                                    </div>
                                );
                            }}
                        </AutoSizer>
                    );
                }}
            </InfiniteLoader>
        </Box>
    );
}
