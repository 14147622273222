import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { reportAPI } from 'api/report';
import { logWarning } from 'tools/tools';
import { CellStyler } from 'components/report-content/components/dataset/CellStyler';
import { applyAutoStretch } from 'components/report-content/components/dataset/index';
import DatasetBody from 'components/report-content/components/dataset/parts/DatasetBody';
import { ReportContentNS } from 'components/report-content/index';
import AdditionalContentSettingsDataset = ReportContentNS.AdditionalContentSettingsDataset;
import DatasetInitialDataResponse = ReportContentNS.DatasetInitialDataResponse;
import DataRowType = ReportContentNS.DataRowType;
import ComponentSettingsDatasetField = ReportContentNS.ComponentSettingsDatasetField;
import ComponentSettingsDataset = ReportContentNS.ComponentSettingsDataset;
import ComponentSettingsDatasetSortField = ReportContentNS.ComponentSettingsDatasetSortField;
import DatasetHeaderLine from 'components/report-content/components/dataset/parts/header/DatasetHeaderLine';
import useReportContentContext, {
    getEmptyDatasetExtViz,
} from 'components/report-content/hooks/useReportContentContext';
import ComponentUpdateProps = ReportContentNS.ComponentUpdateProps;
import { Box } from '@mui/material';
import useComponentReady from 'components/report-content/hooks/useComponentReady';
import ReportContentLoadingPlaceholder from 'components/report-content/utils/ReportContentLoadingPlaceholder';
import useOnSVChange from 'components/report-content/hooks/useOnSVChange';
import useOnApplyAccessMapChange from 'components/report-content/hooks/useOnApplyAccessMapChange';
import useOnMeasurementTime from 'components/report-content/hooks/useOnMeasurementTime';
import BlockType = ReportContentNS.BlockType;
import StaticBreakLine from 'components/report-content/components/dataset/parts/DatasetStaticBreakLine';
import { arrayMoveImmutable } from 'array-move';

export default function Dataset({
    contentSettings,
    component,
    updateBlockSettings,
    actions,
    blockId,
    editPanel,
}: ComponentUpdateProps<ComponentSettingsDataset>) {
    const { filtersString, extViz } = useReportContentContext(() => resetData(true));
    // Recalculate Columns width oh hide\show columns if autoStretch set to 'Y'
    const [visibleColumnsAmount, setVisibleColumnsAmount] = useState<number>(
        component.settings.fields.filter((f) => f.show_column_in_table_display_ind == 'Y').length,
    );
    useLayoutEffect(() => {
        const newAmount = component.settings.fields.filter((f) => f.show_column_in_table_display_ind == 'Y').length;
        if (newAmount != visibleColumnsAmount) {
            setVisibleColumnsAmount(newAmount);
            if (component.settings.autoStretch) {
                updateBlockSettings(applyAutoStretch(component.settings, component.datasetRendererBlockComponentId));
            }
        }
    }, [component.settings]);

    const [tablesList, setTablesList] = useState<Array<BlockType<ComponentSettingsDataset>>>([]);
    useEffect(() => {
        setTablesList(actions.getBlocks().filter((block) => block.component.internalName == 'dataset'));
    }, [actions.getBlocks()]);

    const extVizUpdateIntervalRef = useRef(0);
    useEffect(() => {
        let currentViz = extViz;
        // Remove extViz for current Table if other valid blockUid is selected
        const validVizBlock = tablesList.find((block) => block.uid == extViz.dataset_block_uid);
        if (validVizBlock) {
            // If block exist remove Viz for all other blocks
            if (blockId != validVizBlock.uid) {
                currentViz = getEmptyDatasetExtViz();
            }
        } else {
            // If block don't exist remove Viz for all blocks except first one
            if (tablesList.length && tablesList[0].uid != blockId) {
                currentViz = getEmptyDatasetExtViz();
            }
        }

        if (JSON.stringify(component.settings.extViz) != JSON.stringify(currentViz)) {
            updateBlockSettings(
                {
                    ...component.settings,
                    extViz: currentViz,
                },
                { shouldDirty: false },
            );

            if (extVizUpdateIntervalRef.current) {
                return;
            }
            extVizUpdateIntervalRef.current = window.setInterval(() => {
                if (resetData(true)) {
                    clearInterval(extVizUpdateIntervalRef.current);
                }
            }, 500);
        }
    }, [extViz, tablesList]);

    const handleChangeColumnSettings = (
        fieldId: string,
        newSettings: ComponentSettingsDatasetField,
        unsetAutoStretch: boolean = false,
    ) => {
        const index = component.settings.fields.findIndex((f) => f.id == fieldId);
        if (index == -1) {
            logWarning('Field not found: ${fieldId}');
            return;
        }
        component.settings.fields[index] = { ...newSettings };
        updateBlockSettings({
            ...component.settings,
            autoStretch: unsetAutoStretch ? false : component.settings.autoStretch,
        });
    };

    const handleChangeColumnSortSettings = (sortFields: Array<ComponentSettingsDatasetSortField>) => {
        updateBlockSettings({ ...component.settings, sortFields: sortFields.slice() });
    };

    const [cellStyler] = useState(new CellStyler(component));

    const queryClient = useQueryClient();
    const initialQueryKey = `report_block_initial_${component.datasetRendererBlockComponentId}`;
    const removeInitialData = () => {
        queryClient.removeQueries({ queryKey: [initialQueryKey] });
    };
    const { data: initialData } = useQuery<DatasetInitialDataResponse, Error>({
        queryKey: [initialQueryKey],
        queryFn: () => {
            const datasetSettings: AdditionalContentSettingsDataset = {
                ...contentSettings,
                getAmount: 'Y',
                getTotals: 'Y',
                page: 0,
            };
            return reportAPI.getComponentData(component.settings, datasetSettings, filtersString);
        },
    });

    // Unmount Block
    useEffect(() => {
        return () => {
            removeInitialData();
        };
    }, []);

    // Used to track ComponentReady flag
    const [firstPageLoaded, setFirstPageLoaded] = useState(false);
    const componentReady = useComponentReady(firstPageLoaded);
    const componentReadyRef = useRef(componentReady);
    useEffect(() => {
        componentReadyRef.current = componentReady;
    }, [componentReady]);
    const resetData = (resetInitialData: boolean): boolean => {
        if (!componentReadyRef.current) {
            return false;
        }
        setRowsList([]);
        setLoadedPages([]);
        setRequiredPages([]);
        if (resetInitialData) {
            removeInitialData();
        }
        return true;
    };

    // Reset all data on segment value change
    useOnSVChange(contentSettings.segmentValueId, resetData);
    // Reset all data on Restrict Data with User Map change
    useOnApplyAccessMapChange(contentSettings.applyAccessMap, resetData);
    // Full reset on Measurement Interval Change
    useOnMeasurementTime(contentSettings.measurement_time, resetData);
    // Reset data on sort order change, will trigger new data request
    useEffect(() => {
        resetData(false);
    }, [component.settings.sortFields, component.settings.extViz]);
    // Reset all data on Component Filter change
    useEffect(() => {
        resetData(true);
    }, [component.settings.groupFilter]);
    // On any field aggregation, display mask or hyperlink change
    useEffect(() => {
        resetData(true);
    }, [
        component.settings.fields
            .slice()
            .sort((a, b) => a.reference_name.localeCompare(b.reference_name))
            .map((f) => [f.aggregation_method, f.display_mask_id, f.transform_label])
            .join(),
    ]);

    // Contains dataset data divided by pages
    const [loadedPages, setLoadedPages] = useState<Array<Array<DataRowType>>>([]);
    // List of already loaded pages
    const [requiredPages, setRequiredPages] = useState<Array<number>>([]);

    useEffect(() => {
        if (requiredPages.length == 0 && initialData) {
            handlePageChange(0);
        }
    }, [requiredPages, initialData]);

    const [countOfLoadingPages, setCountOfLoadingPages] = useState<number>(0);

    const handlePageChange = (page: number) => {
        if (requiredPages.includes(page)) {
            return;
        }

        setCountOfLoadingPages((current) => current + 1);
        const list = requiredPages.slice();
        requiredPages.push(page);
        list.push(page);
        setRequiredPages(list);

        const datasetSettings: AdditionalContentSettingsDataset = {
            ...contentSettings,
            getAmount: 'N',
            getTotals: 'N',
            page: page,
        };

        return reportAPI.getComponentData(component.settings, datasetSettings, filtersString).then((response) => {
            const list = loadedPages.slice();
            list[page] = response;
            setLoadedPages(list);
            setFirstPageLoaded(true);
            setCountOfLoadingPages((current) => current - 1);
        });
    };

    useEffect(() => {
        const list = rowsList.slice();

        loadedPages.forEach((rows, page) => {
            if (!rows) {
                return;
            }
            list.splice(page * contentSettings.pageSize, rows.length, ...rows);
        });

        setRowsList(list);
    }, [loadedPages]);

    const [totalRows, setTotalRows] = useState<number>(-1);
    const [totals, setTotals] = useState<DataRowType>({});
    const [rowsList, setRowsList] = useState<Array<DataRowType>>([]);

    const [scrollLeft, setScrollLeft] = useState<number>(0);

    const handleScrollLeft = (newPosition: number) => {
        setScrollLeft(newPosition);
    };

    const updateRowsAmount = () => {
        if (!initialData) {
            return;
        }
        let amount =
            component.settings.limit_total_rows == 'Y' ? component.settings.show_top_n_series : initialData[0].cnt;
        amount = amount > initialData[0].cnt ? initialData[0].cnt : amount;

        setTotalRows(amount);
    };

    useEffect(() => {
        if (!initialData) {
            return;
        }

        updateRowsAmount();
        //@ts-ignore
        rowsList[initialData[0].cnt - 1] = undefined;
        setRowsList(rowsList);
        setTotals(initialData.totals[0]);
    }, [initialData]);

    useEffect(() => {
        // Hide block from viewer if component has no data
        if (contentSettings.context != 'view') {
            return;
        }
        updateBlockSettings({
            ...component.settings,
            hasNoData: totalRows == 0,
        });
    }, [totalRows]);

    useEffect(() => {
        if (!initialData) {
            return;
        }
        updateRowsAmount();
    }, [component.settings.show_top_n_series, component.settings.limit_total_rows]);

    const handleRealWidthChange = (width: number) => {
        if (width == 0) {
            return;
        }
        let newSettings: ComponentSettingsDataset = { ...component.settings, currentInnerWidth: width };
        if (component.settings.autoStretch) {
            newSettings = applyAutoStretch(newSettings, component.datasetRendererBlockComponentId);
        }
        const currentSized = component.settings.fields.map((f) => f.columnSize).join(',');
        const newSizes = newSettings.fields.map((f) => f.columnSize).join(',');
        if (currentSized == newSizes) {
            return;
        }
        updateBlockSettings(newSettings, { shouldDirty: false });
    };

    const handleSort = (from: number, to: number) => {
        const list = component.settings.fields.slice();
        const padding = component.settings.breakColumns[1] ?? 0;
        updateBlockSettings({
            ...component.settings,
            fields: arrayMoveImmutable(list, from + padding, to + padding),
        });
    };

    const [breakLine, setBreakLine] = useState<[number, number]>([0, 0]);
    const handleTopBreakLineChange = (index: number, offset: number) => {
        setBreakLine([index, offset]);
    };

    if (component.settings.hasNoData) {
        return null;
    }

    return (
        <div style={{ overflow: 'hidden' }}>
            <Box className={'table-holder'} sx={{ position: 'relative' }} data-test={'dataset-table-holder'}>
                {initialData && totalRows > -1 ? (
                    <>
                        {countOfLoadingPages > 0 && <ReportContentLoadingPlaceholder />}
                        <StaticBreakLine
                            component={component}
                            rows={rowsList}
                            lineIndex={breakLine[0]}
                            lineOffset={breakLine[1]}
                            cellStyler={cellStyler}
                        />
                        <DatasetHeaderLine
                            onChangeColumnSettings={handleChangeColumnSettings}
                            onChangeColumnSortSettings={handleChangeColumnSortSettings}
                            component={component}
                            contentSettings={contentSettings}
                            cellStyler={cellStyler}
                            scrollLeft={scrollLeft}
                            actions={actions}
                            editPanel={editPanel}
                            blockId={blockId}
                            onSort={handleSort}
                        />
                        <DatasetBody
                            onTopBreakLineChange={handleTopBreakLineChange}
                            totals={totals}
                            totalRows={totalRows}
                            component={component}
                            contentSettings={contentSettings}
                            rows={rowsList}
                            cellStyler={cellStyler}
                            onPageChange={(page: number) => handlePageChange(page)}
                            onHorizontalScroll={handleScrollLeft}
                            updateRealWidth={handleRealWidthChange}
                            actions={actions}
                        />
                    </>
                ) : (
                    <ReportContentLoadingPlaceholder />
                )}
            </Box>
        </div>
    );
}
