import React, { useRef, useState } from 'react';
import { Resizable } from 're-resizable';
import { CellStyler } from 'components/report-content/components/dataset/CellStyler';
import { HeaderResizableClassName } from 'components/report-content/components/dataset/index';
import { ReportContentNS } from 'components/report-content/index';
import BlockComponent = ReportContentNS.BlockComponent;
import ComponentSettingsDatasetField = ReportContentNS.ComponentSettingsDatasetField;
import ComponentSettingsDataset = ReportContentNS.ComponentSettingsDataset;
import ComponentSettingsDatasetSortField = ReportContentNS.ComponentSettingsDatasetSortField;
import DatasetHeaderDropDownIcon from 'components/report-content/components/dataset/parts/header/DatasetHeaderDropDownIcon';
import ContentMode = ReportContentNS.ContentMode;
import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';
import { alpha } from '@mui/material/styles';
import IconMi from 'components/common/icon/IconMi';

function ResizeHandle() {
    return (
        <Box
            component={'span'}
            className={'resize-handle'}
            sx={{
                width: 1,
                height: 1,
                position: 'absolute',
                inset: 0,
                '&:hover': {
                    backgroundColor: (theme: Theme) => alpha(theme.palette.primary.main, 0.85),
                },
            }}
        />
    );
}

function DatasetHeaderLineSortIcon({
    field,
    sortFields,
}: {
    field: ComponentSettingsDatasetField;
    sortFields: Array<ComponentSettingsDatasetSortField>;
}) {
    const index = sortFields.findIndex((sort) => sort.reference_name == field.reference_name);
    if (index == -1) {
        return null;
    }

    return (
        <Box
            sx={{
                display: 'inline-flex',
                alignItems: 'center',
                border: '1px solid',
                px: '2px',
                color: 'primary.main',
                backgroundColor: 'background.default',
                borderColor: (theme) => alpha(theme.palette.text.primary, 0.08),
                fontSize: '11px',
                fontWeight: 400,
                mr: 0.5,
            }}
        >
            <IconMi
                icon={'arrow-right'}
                sx={{
                    fontSize: '10px',
                    pointerEvents: 'none',
                    transform: `rotate(90deg)${sortFields[index].dir != 'ASC' ? ' scale(-1,1)' : ''}`,
                }}
            />
            {sortFields.length > 1 ? index + 1 : ''}
        </Box>
    );
}

export default function DatasetHeaderCell({
    component,
    field,
    cellStyler,
    setHeaderDropDownField,
    onChangeColumnSettings,
    headerDropDownField,
    context,
    scrollLeft,
    index,
    dragHandleProps,
}: {
    component: BlockComponent<ComponentSettingsDataset>;
    field: ComponentSettingsDatasetField;
    cellStyler: CellStyler;
    setHeaderDropDownField: any;
    onChangeColumnSettings: any;
    headerDropDownField: any;
    context: ContentMode;
    scrollLeft: number;
    index: number;
    dragHandleProps: any;
}) {
    const [mouseOnField, setMouseOnField] = useState<string>('');

    const fieldObj = {
        ...field,
        ...component.settings.headerFormatting,
    } as ComponentSettingsDatasetField;

    fieldObj.textAlign = field.textAlign;
    if (field.override_header_ind == 'Y') {
        fieldObj.bgColor = field.bgColor ?? fieldObj.bgColor;
        fieldObj.textColor = field.textColor ?? fieldObj.textColor;
    }

    const styleData = cellStyler.getCellStyle(index, fieldObj, {}, false);
    const style = {
        ...styleData.styleRules,
        display: 'block',
        overflow: 'hidden',
        verticalAlign: 'top',
        minHeight: '1.92em',
        lineHeight: '1.25em',
        padding: '0.33em 5px',
        whiteSpace: 'nowrap',
    };

    const ref = useRef<Resizable>();
    if (ref.current && component.settings.autoStretch) {
        setTimeout(() => ref.current?.updateSize({ width: field.columnSize, height: 22 }));
    }

    return (
        <Resizable
            // @ts-ignore
            ref={ref}
            className={HeaderResizableClassName}
            key={field.id}
            defaultSize={{
                width: field.columnSize + 'px',
                height: '100%',
            }}
            minWidth={30}
            // maxHeight="75vh"
            onResize={(e, dir, ref, delta) => {
                const newSettings = { ...field, columnSize: ref.clientWidth };
                onChangeColumnSettings(field.id, newSettings, true);
            }}
            handleComponent={{
                right: <ResizeHandle />,
            }}
            handleStyles={{
                right: { right: 0, width: '5px' },
            }}
            enable={{
                top: false,
                right: true,
                bottom: false,
                left: false,
                topRight: false,
                bottomRight: false,
                bottomLeft: false,
                topLeft: false,
            }}
        >
            <Box
                sx={{
                    height: '100%',
                    '&.block-wrapper-fixed-column': {
                        position: 'relative',
                        left: scrollLeft + 'px',
                        backgroundColor: '#fff',
                        zIndex: 2,
                    },
                }}
                style={style as React.CSSProperties}
                onMouseEnter={() => setMouseOnField(field.id)}
                onMouseLeave={() => setMouseOnField('')}
                className={cellStyler.buildClassStr(styleData.classRules)}
            >
                <DatasetHeaderLineSortIcon field={field} sortFields={component.settings.sortFields} />

                {context == 'edit' && field.group_column == 'N' ? (
                    <span {...dragHandleProps}>{field.column_name}</span>
                ) : (
                    field.column_name
                )}
                {context == 'edit' && (
                    <>
                        {(mouseOnField == field.id || headerDropDownField.field?.id == field.id) && (
                            <DatasetHeaderDropDownIcon
                                field={field}
                                onClick={(headerDropDown) => setHeaderDropDownField(headerDropDown)}
                            />
                        )}
                    </>
                )}
            </Box>
        </Resizable>
    );
}
