import {
    FormComponentType,
    FormComponentTypeBaseProps,
    FormElementProps,
    FormRendererAPIType,
    RawFormComponentType,
    RawFormElementProps,
} from 'components/common/form/index';
import {
    floatIntegerFilter,
    getComponentKey,
    inputIntegerFilter,
    prepareFormComponentValues,
} from 'components/common/form/layout/control';
import { logWarning } from 'tools/tools';
import { getComponentWrapperKey } from 'components/common/form/layout/wrapper';
import { ComponentKey, getFormComponentFieldType } from 'components/common/ComponentIndex';
import { Breakpoint } from '@mui/system';
import { Params as UrlParams } from '@remix-run/router/dist/utils';
import { FormsContextType } from 'components/common/form/hooks/useFormContext';
import { FormTabType } from 'components/common/form/tab';

export type ContainerMaxWidthType = false | 'maxFull' | Breakpoint;

export function getElementFieldValue(
    form: FormRendererAPIType,
    fullFieldName: string,
    formsContext: FormsContextType = {},
): string | null {
    let field = fullFieldName.split('.');
    const fieldName = field[0];
    const actualValue = form.hookFormGetValues(fieldName);
    if (field.length == 1) {
        // Get actual field value
        return actualValue;
    }
    // fieldName is `$fieldName.$valuePropertyName`
    // Get property value of actual field value
    let propName = field[1];

    // Check for other form deps
    const reg = new RegExp(/^\$form\[([a-zA-Z0-9_]+)\]\./, 'g');
    const formExpr = reg.exec(fullFieldName);
    if (formExpr) {
        const formUID = formExpr[1];
        if (typeof formsContext[formUID] != 'undefined') {
            return getElementFieldValue(formsContext[formUID], fullFieldName.replace(reg, ''), formsContext);
        } else {
            logWarning(`Form: Try to get unknown $form formUID: ${formUID} expr: ${fullFieldName}`);
            return null;
        }
    }

    if (fieldName == '$form') {
        if (propName == 'isDirty') {
            return form.hookFormState.isDirty ? 'Y' : 'N';
        }
    }

    const registerField = form.getElementValuesRegister(fieldName);
    if (registerField == null) {
        return null;
    }

    // Try to find value
    const arrayValue = (Array.isArray(actualValue) ? actualValue : [actualValue]).map((v) => String(v));
    const values = registerField.valuesRef.current.filter((v) => arrayValue.includes(String(v.value)));

    let valueResult: any = undefined;
    const addValue = (value: any) => {
        if (valueResult == undefined) {
            valueResult = value;
        } else {
            if (Array.isArray(valueResult)) {
                valueResult.push(value);
            } else {
                valueResult = [valueResult, value];
            }
        }
    };

    values.forEach((value) => {
        if (propName == '$option' && typeof field[2] != 'undefined') {
            if (!value) {
                return null;
            }
            const optionProp: string = field[2];
            if (Object(value).hasOwnProperty(optionProp)) {
                // @ts-ignore
                addValue(value[optionProp]);
            }
            propName = optionProp;
        }
        // Try to get value Property(attr)
        if (typeof value?.props[propName] != 'undefined') {
            addValue(value.props[propName] == null ? '' : value.props[propName]);
        }
    });
    if (valueResult != undefined) {
        return valueResult;
    }

    // Try to get Shared State
    const sharedState = registerField.componentStatePropsRef.current;
    if (typeof sharedState[propName] != 'undefined') {
        return sharedState[propName] == null ? '' : sharedState[propName];
    }

    // Try to get value of component Property(attr)
    const ref = registerField.componentPropsRef.current;
    if (typeof ref[propName] != 'undefined') {
        return ref[propName] == null ? '' : ref[propName];
    }

    logWarning(`Form: Try to get unknown Property ${propName} of field  ${fieldName}`);
    return null;
}

// Form Elements prepare Functions
export function prepareFormElementProps(
    raw: RawFormElementProps,
    nextComponentProps?: RawFormComponentType,
): FormElementProps {
    const filter =
        raw.inputFilter == 'int' ? inputIntegerFilter : raw.inputFilter == 'float' ? floatIntegerFilter : undefined;
    const componentProps = prepareFormComponentType(raw.component);

    return {
        component: componentProps,
        pk: raw.pk ?? '',
        urlParams: raw.urlParams ?? {},
        formSettingsUrl: raw.formSettingsUrl ?? '',
        translationNS: raw.translationNS ?? 'main',
        componentProps: { ...componentProps.props, ...(raw.componentProps ?? {}) },
        componentStateProps: {},
        componentValues: raw.componentValues ?? [],
        form: raw.form ?? ({} as FormRendererAPIType),
        data: raw?.data ?? {},
        inputFilter: filter,
        containerMaxWidth: getComponentContainerMaxWidth(
            raw.component.containerMaxWidth,
            componentProps.component,
            nextComponentProps?.component,
        ),
    };
}

export function prepareFormComponentType(raw: RawFormComponentType): FormComponentType {
    const name = raw.name ?? '';
    const baseProps: FormComponentTypeBaseProps = {
        xs: raw?.xs ?? 12,
        onNew: raw?.onNew ?? false,
        onDuplicate: raw?.onDuplicate ?? false,
        onEdit: raw?.onEdit ?? true,
        label: raw?.label ?? name,
        translate: raw?.translate ?? true,
        async: raw?.async ?? false,
        settingsUrl: raw?.settingsUrl ?? '',
    };

    const componentKey = getComponentKey(raw);
    return {
        name: name,
        uid: raw.uid ?? name,
        component: componentKey,
        wrapper: getComponentWrapperKey(raw),
        componentStartValues: prepareFormComponentValues(raw.data),
        fieldType: getFormComponentFieldType(componentKey),
        components: raw.components?.map((component) => prepareFormComponentType(component)) ?? [],
        deps: raw.deps ?? [],
        props: { ...baseProps, ...raw?.props },
        helper: raw?.helper,
    };
}

function getComponentContainerMaxWidth(
    settingValue: string | undefined,
    component: ComponentKey,
    nextComponent?: ComponentKey,
): ContainerMaxWidthType {
    if (settingValue == 'maxFull') return settingValue;
    if (settingValue == 'full') return false;

    if (settingValue) return settingValue as Breakpoint;

    return component == 'DropFileUpload' ||
        component == 'GridComponent' ||
        component == 'ReportContentEditor' ||
        component == 'ElementEditorFeedback' ||
        component == 'RatingEngagement' ||
        ((component == 'FormStaticLabel' || component == 'FormButton') &&
            (nextComponent == 'GridComponent' ||
                nextComponent == 'RatingEngagement' ||
                nextComponent == 'ElementEditorFeedback'))
        ? 'lg'
        : 'sm';
}

export function getPkId(pk: string, urlParams: UrlParams) {
    return undefined !== urlParams[pk] ? urlParams[pk]!.toString() : '';
}

export function processSettingsUrl(settingsUrl: string, pk: string | Array<string>, urlParams: UrlParams) {
    if (!Array.isArray(pk)) {
        pk = [pk];
    }
    // find ':' + $pk pattern in URL and replace it with value from urlParams ($urlParams[$pk])
    // url = "data/element/:elementId", urlParams['elementId'] = 42 => "data/element/42"
    pk.forEach(
        (pk) =>
            (settingsUrl = settingsUrl.replace(':' + pk, () => {
                if (urlParams[pk] == null) {
                    return '';
                }
                return undefined !== urlParams[pk] ? urlParams[pk]!.toString() : '';
            })),
    );
    return settingsUrl;
}

export function paramsUrl(url: string, params: UrlParams) {
    return processSettingsUrl(url, Object.keys(params), params);
}

export interface IFormTabComponentRecord {
    tabName: string;
    componentName: string;
    isNested: boolean;
    props: {
        component: RawFormComponentType;
        nextComponent?: RawFormComponentType;
    };
}

export function buildComponentsMap(formTabs: Array<FormTabType>): Array<IFormTabComponentRecord> {
    const result: Array<IFormTabComponentRecord> = [];
    const addRecord = (
        tabName: string,
        component: RawFormComponentType,
        isNested: boolean,
        nextComponent?: RawFormComponentType,
    ) => {
        result.push({
            tabName: tabName,
            componentName: component.name,
            isNested: isNested,
            props: {
                component: component,
                nextComponent: nextComponent,
            },
        });
        if (component.components?.length) {
            component.components.forEach((component, index) => {
                addRecord(tabName, component, true, component.components?.[index + 1]);
            });
        }
    };

    formTabs.forEach((tab) => {
        tab.components.forEach((component, index) => {
            addRecord(tab.name, component, false, tab.components[index + 1]);
        });
    });

    return result;
}

export function getFormId(uid: string, isNew: boolean) {
    return `form_${uid}` + (isNew ? '_new' : '');
}
