import { FormElementProps, RegisteredComponentType } from 'components/common/form/index';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { AssocArray } from 'tools/types';
import { JSONTree } from 'react-json-tree';
import { formElementCollapseProp, formElementExpandProp } from 'store/debugSlice';
import { formSliceState } from 'store/formSlice';

export default function ComponentDebugInfo({ elementProps }: { elementProps: FormElementProps }) {
    const dispatch = useDispatch();
    const name = elementProps.component.name;
    const debugExpandedProps = useSelector((state: any) => state.debug.formElementsExpandedProps[name] ?? []);

    const [propsToShow, setPropsToShow] = useState<AssocArray<any>>({});

    // Force to update
    const [currentValue, setCurrentValue] = useState(elementProps.form.hookFormGetValues(name));
    const [refresh, setRefresh] = useState<number>(0);
    useEffect(() => {
        const subscription = elementProps.form?.hookFormWatch((value, { name: fName, type }) => {
            if (fName == name) {
                setCurrentValue(value[name]);
                setRefresh((prevState) => prevState + 1);
            }
        });
        return () => subscription?.unsubscribe();
    }, [elementProps.form?.hookFormWatch]);

    const [registerField, setRegisterField] = useState<RegisteredComponentType | null>(null);
    useEffect(() => {
        if (!elementProps.form.formDidMount) {
            return;
        }
        const field = elementProps.form.getElementValuesRegister(name);
        setRegisterField(field);
    }, [elementProps.form.formDidMount]);

    const sharedStateTmp: any = useSelector((state: any) => {
        const form = (state?.form as formSliceState).formElementsSharedState[elementProps.form.formKey];
        if (form && typeof form[elementProps.component.name] != 'undefined') {
            return form[elementProps.component.name];
        }
        return {};
    });
    useEffect(() => {
        if (JSON.stringify(sharedStateTmp) == JSON.stringify(sharedState)) {
            return;
        }
        setSharedState(sharedStateTmp);
    }, [sharedStateTmp]);

    const [sharedState, setSharedState] = useState<AssocArray<any>>(sharedStateTmp);

    useEffect(() => {
        let list: AssocArray<any> = {};
        for (const [key, value] of Object.entries(elementProps.componentProps)) {
            if (['xs', 'onNew', 'onEdit', 'onDuplicate', 'async', 'translate', 'label'].includes(key)) {
                continue;
            }
            list[key] = value;
        }

        if (registerField) {
            const arrayValue = Array.isArray(currentValue) ? currentValue : [currentValue];
            const values = registerField.valuesRef.current.filter((v) => arrayValue.includes(v.value));
            let newPropsList: any = {};
            values.forEach((value) => {
                if (value?.props) {
                    for (const [propKey, prop] of Object.entries(value.props)) {
                        if (newPropsList[propKey] == undefined) {
                            newPropsList[propKey] = prop;
                        } else {
                            if (Array.isArray(newPropsList[propKey])) {
                                newPropsList[propKey].push(prop);
                            } else {
                                newPropsList[propKey] = [newPropsList[propKey], prop];
                            }
                        }
                    }
                }
            });

            for (const [propKey, prop] of Object.entries(newPropsList)) {
                list[propKey] = prop;
            }

            for (const [statePropKey, statePropValue] of Object.entries(sharedState)) {
                list[statePropKey] = statePropValue;
            }
        }
        setPropsToShow(list);
    }, [elementProps.componentProps, registerField?.valuesRef, currentValue, refresh, sharedState]);

    return (
        <span style={{ color: '#37809F', border: '1px solid #37809F', display: 'inherit' }}>
            <JSONTree
                hideRoot
                theme={{
                    base00: '#333',
                }}
                labelRenderer={([key], nodeType, expanded) => (
                    <strong
                        onClick={() => {
                            dispatch(
                                expanded
                                    ? formElementCollapseProp([name, String(key)])
                                    : formElementExpandProp([name, String(key)]),
                            );
                        }}
                    >
                        {key}
                    </strong>
                )}
                shouldExpandNodeInitially={([key]) => debugExpandedProps.includes(String(key))}
                data={{ name: elementProps.component.name, value: currentValue, props: propsToShow }}
            />
        </span>
    );
}
