import React, { useMemo, Suspense, useEffect, useState } from 'react';
import Components, { ComponentKey } from './ComponentIndex';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { formAPI } from 'api/form';
import { Params as UrlParams } from 'react-router';
import { Box, Container, Stack } from '@mui/material';
import LoadingPlaceholder from 'components/common/loading-placeholder/LoadingPlaceholder';
import { breadcrumbsSettings } from 'routes';
import { addVersionToUrl, settingsUrlToTranslationNS, uid } from 'tools/tools';
import useBundleTranslation from 'i18n';
import StaticInfo from 'components/common/StaticInfo';
import FormComponent from 'components/common/form/Form';
import NetworkError from 'components/common/error/NetworkError';
import { TFunction } from 'i18next';

export type PageProps = {
    title?: string;
    settings?: any;
    settingsUrl?: string;
    pk?: string;
    breadcrumbs?: breadcrumbsSettings;
};

export type AsyncPageProps = {
    settingsUrl: string;
    pk?: string;
    isPopup?: boolean;
    t?: TFunction;
};

function processSettingsUrl(settingsUrl: string, pk: string, urlParams: UrlParams) {
    return settingsUrl.replace(':' + pk, () => (undefined !== urlParams[pk] ? urlParams[pk]!.toString() : ''));
}

function AsyncPageRenderer({
    response,
    settingsUrl,
    pk,
    isPopup,
    t,
}: {
    response: any;
    settingsUrl: string;
    pk?: string;
    isPopup?: boolean;
    t?: TFunction;
}) {
    const settings = response.data.settings;
    const pageUid = pk ?? uid();
    const location = useLocation();
    const navigate = useNavigate();

    if (undefined === t) t = useBundleTranslation(settingsUrlToTranslationNS(settingsUrl))['t'];

    const [hashActionSettings, setHashActionSettings] = useState<any>();

    const fixedComponents = settings.components.filter((c: any) => c.fixed);
    const regularComponents = settings.components.filter((c: any) => !c.fixed);

    useEffect(() => {
        const hashDataKey = location.hash.slice(1);
        const actionSettings = settings?.hashActions?.[hashDataKey];
        if (actionSettings && actionSettings?.url) setHashActionSettings(actionSettings);
    }, []);
    return (
        <>
            <Suspense fallback={<LoadingPlaceholder />}>
                <Container
                    className="mi-container-2"
                    maxWidth={false}
                    component="main"
                    disableGutters
                    sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}
                >
                    {(settings.desc || fixedComponents.length > 0) && (
                        <Box
                            sx={{
                                mx: (theme) => theme.size.pxValue(theme.size.containerIndentX * -1),
                                position: 'sticky',
                                top: 0,
                                zIndex: 3,
                            }}
                            className={'index-sticky-element'}
                        >
                            {settings.desc && (
                                <Box className={'page-description'}>
                                    <StaticInfo label={t(settings.desc)} align="center" />
                                </Box>
                            )}

                            {fixedComponents.map((component: any, key: number) => {
                                if (component.component === 'StaticInfo') {
                                    return (
                                        <Box key={`page-${pageUid}-${component.component}`}>
                                            <StaticInfo label={t(component.label)} align="center" />
                                        </Box>
                                    );
                                }
                                return (
                                    <Box key={`page-${pageUid}-${component.component}`}>
                                        {React.createElement(
                                            Components[component.component as ComponentKey] as React.FC,
                                            {
                                                key,
                                                t,
                                                ...component.props,
                                            },
                                        )}
                                    </Box>
                                );
                            })}
                        </Box>
                    )}

                    <Stack
                        sx={{
                            ...{
                                flexGrow: 1,
                                overflow: 'auto',
                                width: '100%',
                            },
                            ...(isPopup
                                ? {}
                                : {
                                      py: 4,
                                      px: (theme) => theme.size.pxValue(theme.size.containerIndentX),
                                  }),
                        }}
                        key={`page-${pageUid}-container`}
                        alignItems={'center'}
                        className={'scroll-content-container form-components-indent'}
                        id={!isPopup ? 'page-scroll-grid-sticky' : ''}
                    >
                        {regularComponents.map((c: any, key: number) => {
                            if (c.component === 'GridComponent' || c.component === 'StaticInfo') {
                                const props = {
                                    ...c,
                                    props: undefined,
                                    ...(c.props ?? {}),
                                };
                                if (c.component === 'GridComponent') {
                                    props.height = isPopup ? undefined : 650;
                                    props.removeHeaderIndent = key == 0 ? true : undefined;
                                }
                                return (
                                    <Container
                                        className={'mi-container-4 form-component-holder'}
                                        component="main"
                                        disableGutters={true}
                                        maxWidth={false}
                                        key={`page-${settingsUrl}-${key}`}
                                    >
                                        {React.createElement(Components[c.component as ComponentKey] as React.FC, {
                                            key,
                                            t,
                                            ...props,
                                        })}
                                    </Container>
                                );
                            }
                            return (
                                <Container
                                    className={'mi-container-4 form-component-holder'}
                                    maxWidth={'lg'}
                                    component="main"
                                    disableGutters={true}
                                    key={`page-${settingsUrl}-${key}`}
                                >
                                    {React.createElement(Components[c.component as ComponentKey] as React.FC, {
                                        key,
                                        t,
                                        ...c,
                                        controlProps: c.props,
                                    })}
                                </Container>
                            );
                        })}
                    </Stack>
                </Container>
                {hashActionSettings && (
                    <FormComponent
                        isPopup
                        popupType={'new'}
                        pk={hashActionSettings.url}
                        uid={hashActionSettings.uid}
                        settingsUrl={hashActionSettings.url}
                        onHide={() => {
                            setHashActionSettings(null);
                        }}
                        popupSettings={{}}
                        afterSave={(response: any) => {
                            if (response.data && response.data.redirectUrl) {
                                setTimeout(() => navigate(response.data.redirectUrl), 100);
                            }
                        }}
                    />
                )}
            </Suspense>
        </>
    );
}

export function AsyncPage(props: AsyncPageProps) {
    let settingsUrl = props.settingsUrl;
    const urlParams: UrlParams = useParams();

    if (props.pk && settingsUrl) {
        settingsUrl = processSettingsUrl(settingsUrl, props.pk, urlParams);
    }
    if (settingsUrl && window.location.search > '') {
        settingsUrl += settingsUrl.includes('?') ? '&' : window.location.search;
    }

    const uid: string = settingsUrl.replaceAll(/[^A-Za-z0-9]/g, '_');
    const { status, data, error, isFetching } = useQuery<any, Error>({
        queryKey: [uid],
        queryFn: () => formAPI.load(addVersionToUrl(settingsUrl)),
    });

    return (
        <>
            {status === 'pending' ? (
                <LoadingPlaceholder />
            ) : status === 'error' ? (
                <>{error!.message === 'Network Error' ? <NetworkError /> : <span>Error: {error!.message}</span>}</>
            ) : (
                <AsyncPageRenderer {...props} response={data} />
            )}
        </>
    );
}

export default function Page(props: PageProps) {
    if (props.settings) {
        const { t } = useBundleTranslation([settingsUrlToTranslationNS(props.settingsUrl ?? 'main')]);

        return (
            <Box className={'page-container'} sx={{ height: '100%' }}>
                {props.settings.components.map((c: any, key: number) =>
                    useMemo(
                        () =>
                            React.createElement(Components[c.component as ComponentKey] as React.FC, {
                                key,
                                t,
                                ...c,
                            }),
                        [c],
                    ),
                )}
            </Box>
        );
    } else {
        return <AsyncPage pk={props.pk} settingsUrl={props.settingsUrl ?? ''} />;
    }
}
